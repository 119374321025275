<ion-header>
  <ion-toolbar>
    <ion-title>Quickview: {{ this.item.qrcode }}</ion-title>
    <ion-buttons slot="end">
      <ion-button class="ml-3 close-icon" (click)="navigate()">
        <ion-icon name="close" color="danger"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="pt-6 mt-0 overflow">
  <ion-progress-bar type="indeterminate" *ngIf="this.itemLoading === true"></ion-progress-bar>
  <ion-grid *ngIf="this.itemLoading === false">
    <ion-row>
      <ion-col size="12">
        <div class="carousel-container">
          <swiper [autoplay]="images?.length > 1" [loop]="true" [keyboard]="true" [pagination]="true" [enabled]="images?.length > 1" [navigation]="images?.length > 1 && this.isNative == false">
            <ng-template swiperSlide *ngIf="images == null || !images.length">
              <div class="carousel-slide-overlay no-image-text ion-justify-content-center">
                <div class="no-image">
                  <h1>No Images</h1>
                </div>
              </div>
              <mat-chip-list class="p-3 ml-auto mb-auto">
                <mat-chip
                  class="readOnly"
                  [ngClass]="{ 'clear-chip': this.item.status >= 4 }"
                  disableRipple
                  selected
                  [color]="this.item.isComplete === true || this.item.status >= 2 ? 'secondary' : 'warn'"
                >
                  <app-item-status-icon size="large" [status]="this.item.status"></app-item-status-icon>
                </mat-chip>
              </mat-chip-list>
            </ng-template>

            <ng-template swiperSlide *ngFor="let image of this.images">
              <ion-img [src]="this.imageService.baseUrl + image?.path"></ion-img>
              <mat-chip-list class="p-4 ion-float-right">
                <mat-chip
                  class="readOnly"
                  [ngClass]="{ 'clear-chip': this.item.status >= 4 }"
                  disableRipple
                  selected
                  [color]="this.item.isComplete === true || this.item.status >= 2 ? 'secondary' : 'warn'"
                >
                  <app-item-status-icon size="large" [status]="this.item.status"></app-item-status-icon>
                </mat-chip>
              </mat-chip-list>
            </ng-template>
          </swiper>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-button class="ion-float-right" color="primary" fill="clear" (click)="this.handleAccordionButton()">
          <span *ngIf="this.panelsOpen < 1">Open All</span>
          <span *ngIf="this.panelsOpen > 0">Close All</span>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <mat-accordion [multi]="true" togglePosition="before">
          <mat-expansion-panel [expanded]="true" (opened)="this.panelOpened()" (closed)="this.panelClosed()">
            <mat-expansion-panel-header>
              <mat-panel-title> Information </mat-panel-title>
            </mat-expansion-panel-header>
            <ion-row>
              <ion-col size="12" size-md="4">
                <div class="readonly">
                  <mat-label> QR Code </mat-label>
                  <div class="field">
                    <a color="secondary" (click)="this.navigate()" [routerLink]="['/', 'lumber-item', 'form', item.uuid]">{{ item.qrcode }}</a>
                  </div>
                </div>
                <div *ngIf="this.showCompany" class="readonly">
                  <mat-label>Company</mat-label>
                  <div class="field">
                    <p>{{ this.company?.name }}</p>
                  </div>
                </div>
              </ion-col>
              <ion-col size="12" size-md="4">
                <div class="readonly">
                  <mat-label>Species</mat-label>
                  <div class="field">
                    <p>{{ item.treeSpecies | notationName }}</p>
                  </div>
                </div>
              </ion-col>
              <ion-col size="12" size-md="4">
                <div class="readonly">
                  <mat-label>Grade</mat-label>
                  <p>{{ item.itemGrade?.reference }}</p>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12" size-md="4">
                <div class="readonly">
                  <mat-label>Name</mat-label>
                  <div class="field">
                    <p>{{ item.name }}</p>
                  </div>
                </div>
              </ion-col>
              <ion-col size="12" size-md="4">
                <div class="readonly">
                  <mat-label>Storage Location</mat-label>
                  <div class="field">
                    {{ item.currentStorageLocation?.locationName }}
                  </div>
                </div>
              </ion-col>
              <ion-col size="12" size-md="4">
                <div class="readonly">
                  <mat-label>
                    <span *ngIf="this.company?.measurementSystem?.isImperial">Pounds</span>
                    <span *ngIf="this.company?.measurementSystem?.isMetric">Kilograms</span>
                    of Carbon Stored
                  </mat-label>
                  <div class="field">
                    {{ this.item.carbonSequesteredValue | uomWeight: this.company?.measurementSystem }}
                  </div>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <div class="readonly">
                  <mat-label>Description</mat-label>
                  <!-- TODO component/class to truncate after a certain length/height
                                  preferably based on device size -->
                  <div class="field">
                    <p>
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </ion-col>
              <ion-col>
                <div class="readonly">
                  <mat-label>Sold</mat-label>
                  <!-- TODO component/class to truncate after a certain length/height
                                  preferably based on device size -->
                  <div class="field">
                    <p>
                      {{ item.isSold ? 'Sold' : item.forSale ? 'For Sale' : 'Not For Sale' }}
                    </p>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </mat-expansion-panel>
        </mat-accordion>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="this.navigate()" [routerLink]="['/', 'lumber-item', 'form', item.uuid]" fill="outline" color="primary"> Edit </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
