import { Exclude } from 'class-transformer';

import { Platform } from '../enums/platform';
import { BaseEntityDto } from './base-entity-dto';

export enum SubscriptionPackageType {
  ESSENTIALS = 0,
  PROFESSIONAL,
  TRAILBLAZER
}

export const subscriptionPackageTypeOptions = [
  { id: SubscriptionPackageType.ESSENTIALS, reference: 'Essentials', icon: 'essentials' },
  { id: SubscriptionPackageType.PROFESSIONAL, reference: 'Professional', icon: 'professional' },
  { id: SubscriptionPackageType.TRAILBLAZER, reference: 'Trailblazer', icon: 'trailblazer' }
];

interface SubscriptionPackageDto {
  type: SubscriptionPackageType;
  packageType?: SubscriptionPackageType;
  title: string;
  subTitle: string;
  features?: string; // comma delimited list
  isActive: boolean;
  pricePerMonth: number;
  externalId: string;
  externalTrialId: string;
  theme: string; // for styling
}

export class SubscriptionPackageEditDto extends BaseEntityDto implements SubscriptionPackageDto {

  type: SubscriptionPackageType;
  title: string;
  subTitle: string;
  features: string;
  isActive: boolean;
  pricePerMonth: number;
  theme: string;
  trialPeriod: number | null;
  comingSoon: boolean;
  userLimit: number | null;
  itemLimit: number | null;
  trialIsActive: boolean;

  @Exclude({ toPlainOnly: true })
  externalId: string; // readonly
  @Exclude({ toPlainOnly: true })
  externalTrialId: string; // readonly

  constructor() {
    super();
    this.isActive = true;
    this.comingSoon = true;
    this.trialIsActive = false;
  }
}

export class SubscriptionPackageListDto extends BaseEntityDto {
  type: SubscriptionPackageType;
  title: string;
  theme: string;
  pricePerMonth: number;
  externalId: string;
  externalTrialId: string; // readonly
  isActive: boolean;
  trialIsActive: boolean;
}

export class SubscriptionPackageOptionDto extends BaseEntityDto implements SubscriptionPackageDto {
  type: SubscriptionPackageType;
  title: string;
  subTitle: string;
  featureList: string[];
  isActive: boolean;
  trialIsActive: boolean;
  pricePerMonth: number;
  externalId: string;
  externalTrialId: string;
  theme: string;
  disabled: boolean;
  withTrial: boolean;
  webProductId: string;
  appleProductId: string;
  googleProductId: string;
  price: string;
}

export class SubscriptionProductOptionDto extends SubscriptionPackageOptionDto {
  type: SubscriptionPackageType;
  title: string;
  subTitle: string;
  disabled: boolean;
  isActive: boolean;
  price: string;
  pricePeriod: string;
  introPrice: string;
  introPricePeriod: string;
  externalId: string;
  featureList: string[];
  theme: string;
  selectText: string;
  product: CdvPurchase.Product;
}

export class RlsCompanyUserSubscriptionDto extends BaseEntityDto {
  isActive: boolean;
  startDate: Date;
  endDate: Date | null;
  cancelledDate: Date | null;
  paidThroughDate: Date | null;
  hasPaid: boolean | null; // Null represents Pending
  subscriptionPackageId: number;
  subscriptionPackage: SubscriptionPackageOptionDto;
  subscriberId: string;
  subscribedCompanyId: number;
  externalSubscriptionId: string;
  platform: Platform;
  externalInvoiceId: string;
}
