export const environment = {
  production: false,
  oidcConfig: {
    client_id: 'AncesTREEWebsite',
    client_secret: '7FA854B7556729CE4E752DB4C82199396A4479A9ACF58A7E2A72D573E3',
    server_host: 'https://authbeta.ancestree.org',
    redirect_url: window.location.origin + '/callback',
    end_session_redirect_url: window.location.origin + '/logout',
    scopes: 'openid profile ulnWebApi email roles offline_access',
    pkce: true
  },
  webUrl: 'https://dev.ancestree.org',
  hostUrl: 'https://dev.ancestree.org/',
  attachmentUrl: 'https://wsbeta.ancestree.org/',
  apiBaseUrl: 'https://wsbeta.ancestree.org/api/',
  apiVersion: '1.1',
  cookieDomain: 'ancestree.org',
  privacyPolicyUrl: 'https://wsbeta.ancestree.org/Uploads/Legal/privacy-policy.pdf',
  termsOfServiceUrl: 'https://wsbeta.ancestree.org/Uploads/Legal/terms-of-service.pdf',
  subscriptionTermsUrl: 'https://wsbeta.ancestree.org/Uploads/Legal/subscription_terms_and_benefits.pdf',
  paymentsConfig: {
    squareAPI: {
      url: 'https://sandbox.web.squarecdn.com/v1/square.js', // Square Prod js to inject
    },
    appStore: {
      validatorUrl: 'https:///wsbeta.ancestree.org/api/AppStore/Validator'
    },
    googlePlay: {
      url: ''
    }
  },
  googleMaps: {
    apiKey: 'AIzaSyCofqPye6qpVj835ax98bxC5_P6l9Jp_Og'
  },
  captchaSiteKey: '6LeHJZkjAAAAAIjxiOZtROA6uxqQLqR9TTCQ5x1c',
  useHash: false,
  scheme: 'beta.ancestree.market:/',
  email: 'info@ancestree.org',
  defaultRoute: '/getting-started'
};
