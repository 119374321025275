import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule
} from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
  MatSelectModule
} from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
  MatTooltipModule
} from '@angular/material/tooltip';
import { IonicModule } from '@ionic/angular';
import { ReactiveValidationModule } from 'angular-reactive-validation';
import { NgArrayPipesModule, NgDatePipesModule } from 'ngx-pipes';
import { QuicklinkModule } from 'ngx-quicklink';

import { AttachmentModule } from '../components/attachments/attachments.module';
import { ComboBoxComponent } from '../components/combo-box/combo-box.component';
import { CountryInputComponent } from '../components/country-input/country-input.component';
import { CreateFormButtonComponent } from '../components/create-form-button/create-form-button.component';
import { CurrencyInputComponent } from '../components/currency-input/currency-input.component';
import { DistanceInputComponent } from '../components/distance-input/distance-input.component';
import { FormFooterComponent } from '../components/form-footer/form-footer.component';
import { AddressInputComponent } from '../components/forms/address-input/address-input.component';
import { EmailInputComponent } from '../components/forms/email-input/email-input.component';
import { PhoneInputComponent } from '../components/forms/phone-input/phone-input.component';
import { GeolocationInputComponent } from '../components/geolocation-input/geolocation-input.component';
import { OtherableFormDialogComponent } from '../components/otherable-form-dialog/otherable-form-dialog.component';
import { PasswordInputComponent } from '../components/password-input/password-input.component';
import { StateInputComponent } from '../components/state-input/state-input.component';
import { TemperatureInputComponent } from '../components/temperature-input/temperature-input.component';
import { CoreModule } from '../core/core.module';
import { IonItemRequiredDirective } from '../directives/ion-item-required.directive';
import { PageDirective } from '../directives/page.directive';
import {
  MatInputRequiredDirective,
  MatSelectRequiredDirective
} from '../mat-form-field-required.directive';
import { RequestMessageModalPage } from '../pages/modals/request-message-modal/request-message-modal.page';
import { SharedLoadingModule } from './loading.module';
import { SharedAutocompleteModule } from './shared-autocomplete.module';
import { SharedTableModule } from './shared-table.module';

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 200,
  hideDelay: 1000,
  touchendHideDelay: 2000
};

export const myCustomFormFieldDefaults: MatFormFieldDefaultOptions = {
  appearance: 'standard',
  floatLabel: 'auto',
  hideRequiredMarker: false
};

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,

    AttachmentModule,
    SharedLoadingModule,
    SharedAutocompleteModule,
    SharedTableModule,
    SharedAutocompleteModule,

    MatFormFieldModule,
    MatTableModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatCheckboxModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    MatTooltipModule,
    MatMomentDateModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgArrayPipesModule,
    NgDatePipesModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveValidationModule,
    QuicklinkModule
  ],
  declarations: [
    AddressInputComponent,
    EmailInputComponent,
    PhoneInputComponent,
    FormFooterComponent,
    GeolocationInputComponent,
    CurrencyInputComponent,
    DistanceInputComponent,
    StateInputComponent,
    CountryInputComponent,
    TemperatureInputComponent,
    PasswordInputComponent,
    CreateFormButtonComponent,
    PageDirective,
    OtherableFormDialogComponent,
    ComboBoxComponent,
    RequestMessageModalPage,

    // Otherable Forms, Move to Other Module

    MatSelectRequiredDirective,
    MatInputRequiredDirective,
    IonItemRequiredDirective
  ],
  exports: [

    FormsModule,
    ReactiveFormsModule,

    // App Modules
    AttachmentModule,
    SharedLoadingModule,

    // 3rd Party Modules
    MatFormFieldModule,
    MatTableModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatCheckboxModule,
    MatSelectModule,
    MatStepperModule,
    MatCheckboxModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    MatTooltipModule,
    MatMomentDateModule,
    MatIconModule,
    NgArrayPipesModule,
    NgDatePipesModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveValidationModule,

    // Declared Components
    AddressInputComponent,
    CurrencyInputComponent,
    DistanceInputComponent,
    EmailInputComponent,
    FormFooterComponent,
    GeolocationInputComponent,
    PhoneInputComponent,
    StateInputComponent,
    CountryInputComponent,
    TemperatureInputComponent,
    PasswordInputComponent,
    CreateFormButtonComponent,
    ComboBoxComponent,
    RequestMessageModalPage,

    // Declared Forms

    // Declared Directives
    MatSelectRequiredDirective,
    MatInputRequiredDirective,
    IonItemRequiredDirective,

    QuicklinkModule
  ],
  providers: [
    // For Modal's with FormComponents to work, not working
    // FormGroupDirective,
    // MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
    // { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] }
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: myCustomFormFieldDefaults },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {} }
  ]
})
export class SharedFormModule { }
