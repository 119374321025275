import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { SharedFormModule } from 'src/app/shared/shared-form.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { GettingStartedPageRoutingModule } from './getting-started-routing.module';
import { GettingStartedPage } from './getting-started.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    SharedFormModule,
    GettingStartedPageRoutingModule
  ],
  declarations: [GettingStartedPage]
})
export class GettingStartedPageModule { }
