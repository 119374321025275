import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { find, isEmpty, isNil, omitBy, reject } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { classToPlain, plainToClass } from 'class-transformer';
import { environment } from 'src/environments/environment';

import { Address } from '../models/address';
import { CompanyAccount } from '../models/company-account';
import {
  RlsCompanyUserSubscriptionDto,
  SubscriptionPackageEditDto,
  SubscriptionPackageListDto,
  SubscriptionPackageOptionDto
} from '../models/dto/subscription-package-dto';
import { CountryCodeToTypeMap } from '../models/enums/country-type';
import {
  PaymentCustomerUser,
  PaymentCustomerUserWithCardData,
  PaymentMethodOption
} from '../models/payment-customer-user';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { BaseEntityService } from './base-entity.service';
import { SystemSettingService } from './system-setting.service';

declare let SquarePkg: any; // Square Web Payments API package
declare global {
  interface Window {
    Square: any;
  }
}

interface SquareContact {
  addressLines?: string[];
  city?: string | undefined;
  countryCode?: string | undefined;
  email?: string | undefined;
  familyName?: string | undefined;
  givenName?: string | undefined;
  phone?: string | undefined;
  postalCode?: string | undefined;
  state?: string | undefined;
}

export interface SubscribePaymentOptions {
  cardId?: string | null | undefined;
  paymentToken?: string | null | undefined;
  verificationToken?: string | null | undefined;
  transactionId?: string | null | undefined;
}

export interface ActiveCompanySubDto {
  activeSubscription: RlsCompanyUserSubscriptionDto | null;
  upcomingSubscription: RlsCompanyUserSubscriptionDto | null;
  errorMessage?: string | null;
};

export interface UpdatePackagePricingDto {
  newPricePerMonth: number;
  newTrialPeriod: number;
  trialIsActive: boolean;
  subscriptionPackageId: number;
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPackageService extends BaseEntityService<SubscriptionPackageListDto | SubscriptionPackageEditDto> {

  endpoint = 'SubscriptionPackages';

  paymentsAPI = null;

  squarePaymentsLoaded = new BehaviorSubject(false);
  public squarePaymentsLoaded$: Observable<boolean> = this.squarePaymentsLoaded;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    protected api: ApiService,
    private systemSettingService: SystemSettingService
  ) { super(); }

  /**
   * TODO inject from env the Square Web Payments API
   */
  async inject(renderer: Renderer2) {
    const isLoaded = await this.squarePaymentsLoaded$.pipe(take(1)).toPromise();
    if (isLoaded === true) { return Promise.resolve(true); }
    let script = renderer.createElement('script');
    script.type = "text/javascript";
    script.id = 'squareAPI';
    renderer.appendChild(this.document.body, script);

    try {
      const { applicationId, locationId } = await this.getConfig();
      script.onload = () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.paymentsAPI = window.Square && window.Square.payments(applicationId, locationId);
        console.log(this.paymentsAPI);
        this.squarePaymentsLoaded.next(true);
      };
      const paymentSDKUrl = await this.systemSettingService.getByKey('ANCESTREE.SQUARE_PAYMENT_WEB_SDK').then(res => res.value)
        .catch(err => console.error(err)) || environment.paymentsConfig.squareAPI.url;
      script.src = paymentSDKUrl; // must be after to ensure on load fires
      await this.squarePaymentsLoaded$.pipe(filter(spl => spl === true), take(1)).toPromise();
      return true;
    } catch (err) {
      console.error(err);
      this.squarePaymentsLoaded.next(false);
      // const statusContainer = document.getElementById(
      //   'payment-status-container'
      // );
      // statusContainer.className = 'missing-credentials';
      // statusContainer.style.visibility = 'visible';
      return false;
    }
  }

  async removeScript(renderer: Renderer2) {
    renderer.removeChild(this.document.body, this.document.getElementById('squareAPI'));
    window.Square = undefined;
  }

  async getConfig() {
    return this.api.get(`${this.endpoint}/ClientConfig`).toPromise();
  }

  async getPaymentForm() {
    const isLoaded = await this.squarePaymentsLoaded$.pipe(take(1)).toPromise();
    // if (isLoaded !== true) { await this.inject(); }
  }

  createExternalCustomer(userId: string): Promise<PaymentCustomerUser> {
    return this.api.post(`${this.endpoint}/CreateExternalCustomer`, { userId })
      .toPromise();
  }

  createCustomerCard(userId: string, customerId: string, paymentToken: string, verificationToken: string): Promise<PaymentCustomerUser> {
    return this.api.post(`${this.endpoint}/CreateExternalCard`, { userId, customerId })
      .toPromise();
  }

  getExternalCustomer(): Promise<PaymentCustomerUser> {
    return this.api.get(`${this.endpoint}/ExternalCustomer`).toPromise();
  }

  async createPaymentToken(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
      throw new Error(errorMessage);
    }
  }

  //https://developer.squareup.com/reference/sdks/web/payments/objects/Payments
  async verifyPayment(paymentToken, amount: number | string, currencyCode = 'USD', user: User = null, address: Address = null) {
    const billingAddress: SquareContact = {
      email: user.email,
      phone: user.phoneNumber,
      familyName: user.lastName,
      givenName: user.firstName,
    };
    if (address != null) {
      billingAddress.addressLines = reject([address.streetAddress, address.streetAddress2], isEmpty);
      billingAddress.state = address.state;
      billingAddress.postalCode = address.postalCode;
      billingAddress.city = address.city;
      billingAddress.countryCode = CountryCodeToTypeMap.get(address.country) || undefined;
    }
    const verificationDetails = {
      amount: `${amount}.00`,
      currencyCode,
      billingContact: billingAddress,
      intent: 'STORE',
    };
    return this.paymentsAPI.verifyBuyer(paymentToken, verificationDetails).then(res => {
      console.log(res);
      return res.token;
    });
  }

  async subscribeForCompany(subPackage: SubscriptionPackageOptionDto, companyId: number, options: SubscribePaymentOptions): Promise<RlsCompanyUserSubscriptionDto> {
    const params = {
      ...options,
      subscriptionId: subPackage.id,
      companyId
    };

    return this.api.post(`${this.endpoint}/Subscribe`, omitBy(params, isNil)).toPromise();
  }

  async subscribeForCompanyIAP(subPackage: SubscriptionPackageOptionDto, companyId: number, options: SubscribePaymentOptions): Promise<RlsCompanyUserSubscriptionDto> {
    const params = {
      ...options,
      subscriptionId: subPackage.id,
      companyId,
      platform: Capacitor.getPlatform()
    };

    return this.api.post(`${this.endpoint}/SubscribeIAP`, omitBy(params, isNil)).toPromise();
  }

  async swapSubscriptionPlans(oldSub: RlsCompanyUserSubscriptionDto, newPackage: SubscriptionPackageOptionDto, companyId: number, options: SubscribePaymentOptions) {
    const params = {
      ...options,
      oldSubscriptionId: oldSub.id,
      NewSubscriptionPackageId: newPackage.id,
      companyId
    };

    return this.api.post(`${this.endpoint}/SwapSubscription`, omitBy(params, isNil)).toPromise();
  }

  async generatePaymentOptions(user: User, company: CompanyAccount, selectedSub: SubscriptionPackageOptionDto, existingPM: PaymentMethodOption, newPaymentMethod): Promise<SubscribePaymentOptions> {
    if (existingPM == null && newPaymentMethod == null) {
      throw new Error('Must choose payment method!');
    }
    if (existingPM != null) {
      return { cardId: existingPM.externalCardId };
    }
    const paymentToken = await this.createPaymentToken(newPaymentMethod);

    let rlsAddress;
    if (user.rlsUserAddress?.length > 0) {
      rlsAddress = user.rlsUserAddress[0]?.address;
    }
    if (rlsAddress == null) {
      rlsAddress = find(company.rlsCompanyAddresses, a => a.address.uuid === company.companyAddress?.uuid)?.address;
    }
    const verificationToken = await this.verifyPayment(paymentToken, selectedSub.pricePerMonth, 'USD', user, rlsAddress);
    return { verificationToken, paymentToken };
  }


  async cancelSub(sub: RlsCompanyUserSubscriptionDto): Promise<RlsCompanyUserSubscriptionDto> {
    return this.api.delete(`${this.endpoint}/CancelSub`, { SubId: sub.id })
      .toPromise();
  }

  async updatePaymentMethodForSub(sub: RlsCompanyUserSubscriptionDto, companyId: number, options: SubscribePaymentOptions) {
    const body = {
      subscriptionId: sub.id,
      companyId,
      ...options
    };
    return this.api.put(`${this.endpoint}/UpdatePaymentMethodForSub`, omitBy(body, isNil)).toPromise();
  }



  getCurrentSubByCompany(companyId: number): Promise<ActiveCompanySubDto> {
    return this.api.get(`${this.endpoint}/CurrentSubByCompany`, { companyId })
      .toPromise();
  }

  getCurrentPaymentMethod(): Promise<PaymentCustomerUserWithCardData> {
    return this.api.get(`${this.endpoint}/CurrentPaymentMethod`).toPromise();
  }

  getSubscriptionHistoryByCompany(companyId: number) {
    return this.api.get(`${this.endpoint}/CompanySubHistory`, { companyId })
      .toPromise();
  }

  get(): Promise<SubscriptionPackageListDto[]> {
    return this.api.get(`${this.endpoint}`)
      .pipe(map((res: any[]) => res?.map(o => plainToClass(SubscriptionPackageListDto, o))))
      .toPromise();
  }

  getOptions(withTrial = false, originalTransactionId = null): Promise<SubscriptionPackageOptionDto[]> {
    return this.api.get(`${this.endpoint}/Options`, { platform: Capacitor.getPlatform(), withTrial, originalTransactionId })
      .pipe(map((res: any[]) => res?.map(o => plainToClass(SubscriptionPackageOptionDto, o))))
      .toPromise();
  }

  getExternalId(option: SubscriptionPackageOptionDto, platform: string = Capacitor.getPlatform()): string {
    if (platform === 'ios') {
      return option.appleProductId;
    } else if (platform === 'android') {
      return option.googleProductId;
    }
    return option.webProductId;
  }

  getById(id: number): Promise<SubscriptionPackageEditDto> {
    return this.api.get(`${this.endpoint}/${id}`)
      .pipe(map(res => plainToClass(SubscriptionPackageEditDto, res)))
      .toPromise();
  }

  getByUUID(uuid: string): Promise<SubscriptionPackageEditDto> {
    return this.api.get(`${this.endpoint}/ByUUID/${uuid}`)
      .pipe(map(res => plainToClass(SubscriptionPackageEditDto, res)))
      .toPromise();
  }

  post(model: SubscriptionPackageEditDto): Promise<SubscriptionPackageEditDto> {
    return this.api.post(`${this.endpoint}`, classToPlain(model))
      .pipe(map(res => plainToClass(SubscriptionPackageEditDto, res)))
      .toPromise();

  }

  put(model: SubscriptionPackageEditDto): Promise<SubscriptionPackageEditDto> {
    return this.api.put(`${this.endpoint}/${model.id}`, classToPlain(model))
      .pipe(map(res => plainToClass(SubscriptionPackageEditDto, res)))
      .toPromise();
  }

  updatePricingForLivePackage(model: SubscriptionPackageEditDto, newPrice: number,
    trialPeriod: number, trialIsActive: boolean): Promise<SubscriptionPackageEditDto> {
    const body: UpdatePackagePricingDto = {
      subscriptionPackageId: model.id,
      newPricePerMonth: newPrice,
      newTrialPeriod: trialPeriod,
      trialIsActive
    };

    return this.api.put(`${this.endpoint}/UpdatePricing/${model.id}`, body)
      .pipe(map(res => plainToClass(SubscriptionPackageEditDto, res)))
      .toPromise();
  }

  delete(model: SubscriptionPackageListDto): Promise<any> {
    return this.api.delete(`${this.endpoint}/${model.id}`).toPromise();
  }

  // Send the Company Account creation email with subscription information and links to the mobile applications
  getMobileAppEmail(): Promise<any> {
    return this.api.post(`${this.endpoint}/CompanyCreated`, {}).toPromise();
  }

  getCurrentSubByTransaction(transactionId: string): Promise<RlsCompanyUserSubscriptionDto> {
    const params = { transactionId, platform: Capacitor.getPlatform() };
    return this.api.get(`${this.endpoint}/CurrentSubByIAP`, omitBy(params, isNil))
      .pipe(map(res => plainToClass(RlsCompanyUserSubscriptionDto, res)))
      .toPromise();
  }

  updateSubscriptionPostRegister(transactionId: string, userId: string, platform = Capacitor.getPlatform()) {
    return this.api.put(`${this.endpoint}/UpdateSubscriptionFromRegister`, { originalTransactionId: transactionId, userId, platformId: platform })
      .toPromise();
  }
}
