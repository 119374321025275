import { BaseEntity } from './baseEntity';
import { Geolocation } from './geolocation';

import * as moment from 'moment';
import { Moment } from 'moment';
import { Transform, Type } from 'class-transformer';

export class Attachment extends BaseEntity {
    constructor() {
        super();
        this.isActive = true;
        this.isUploading = false;
        this.isAttached = false;
        this.isDeleting = false;
    }
    path: string;
    name: string;
    geolocation?: Geolocation;

    // Needs a { enableImplicitConversion: true } for plainToClass
    @Transform((m: string): any => m == null ? null : moment(m), { toClassOnly: true })
    @Transform((m: Moment): any => m != null ? m.format() : null, { toPlainOnly: true })
    created: Moment;

    isActive: boolean;
    isAttached: boolean;
    isUploading: boolean;
    isDeleting: boolean;
}
