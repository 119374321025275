import { isNaN as _isNaN } from 'lodash-es';

import { UnitOfMeasurementService } from '../services/unit-of-measurement.service';
import { BaseEntity } from './baseEntity';
import { IOtherable } from './interfaces/IOtherable';
import { MeasurementSystem } from './measurement-system';

let roundOff = (num, places) => {
    const x = Math.pow(10, places);
    return Math.round(num * x) / x;
}
export class ScalingMethod extends BaseEntity implements IOtherable {

    constructor() {
        super();
        this.isActive = true;
        this.isOther = false;
    }

    method: string;
    isActive: boolean;
    isOther: boolean;

    get display(): string {
        return this.method;
    }

    /**
     *
     * @param diameter
     * @param length
     * @param ms
     * @returns
     */
    scalingCalculation(diameter: number, length: number, ms?: MeasurementSystem): number {
        let result: number = null;
        const isMetric = ms.isMetric || false;
        const isImperial = ms.isImperial || !isMetric;

        if (diameter == null || length == null) { return null; }

        if (isMetric) {
            diameter = UnitOfMeasurementService.convertMetersToInches(diameter);
            length = UnitOfMeasurementService.convertMetersToInches(length);
        }

        let fiLength = Math.floor(length / 12);
        // fiLength = fiLength > 0 ? fiLength : length / 12; if i need to handle case in which feet < 1, not always = 0
        // TODO only use feetValue not inches in this
        switch (this.method) {
            case 'Scribner':
                result = ScalingMethod.Scribner(diameter, fiLength);
                break;
            case 'International':
                result = ScalingMethod.International(diameter, fiLength);
                break;
            case 'Doyle':
                result = ScalingMethod.Doyle(diameter, fiLength);
                break;
        }
        if (_isNaN(result)) {
            result = null;
        }
        // Translate back from feet to inches
        // result = result != null ? Math.round(result) : result; // rounding in presentation not data layer
        result = UnitOfMeasurementService.convertFeetToInches(result);
        // result = roundOff(result, 2);
        // Translate back to metric if applicable
        if (isMetric) {
            return UnitOfMeasurementService.convertInchesToMeters(result);
        }
        return result;
    }

    /**
     *
     * @param diameter in inches
     * @param length in feet
     * @returns board feet
     * =(0.0494*(C4+D4)*(C4+D4)*E4)-(0.124*(C4+D4)*E4)-(0.269*E4)
     * i.e. =(0.0494*(6+0)*(6+0)*15)-(0.124*(6+0)*15)-(0.269*15)
     */
    public static Scribner(diameter: number, length: number): number {
        return (0.0494 * diameter * diameter * length) - (0.124 * diameter * length) - (0.269 * length)
    }
    /**
     *
     * @param diameter in inches
     * @param length in feet
     * @returns board feet
     */
    public static International(diameter: number, length: number) {
        return (0.905 * ((0.22 * diameter * diameter) - (0.71 * diameter))) * (length / 4);
    }
    /**
     *
     * @param diameter in inches
     * @param length in feet
     * @returns board feet
     * (length*((diameter inch + diameter fraction inch) - 4) * ((C4+D4)-4))/16
     * (15 * ((6 + 0) - 4) * ((6 + 0) - 4)) / 16
     *
     */
    public static Doyle(diameter: number, length: number) {
        return ((diameter - 4) * (diameter - 4)) * (length / 16);
    }
}