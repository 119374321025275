import { Type } from 'class-transformer';

import { Address } from '../address';
import { BaseEntity } from '../baseEntity';
import { CompanyAccount } from '../company-account';

export class RlsCompanyAddress extends BaseEntity {

    constructor() {
        super();
        this.address = new Address(this.companyAccount);
    }

    @Type(() => Address)
    address: Address;

    companyAccount: CompanyAccount;
}
