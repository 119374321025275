import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

import { AuthService as OIDCAuthService } from 'ionic-appauth';
import { environment } from 'src/environments/environment';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private oidc: OIDCAuthService, private authService: AuthService, private navCtrl: NavController, private router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.oidc.initComplete$.pipe(
      filter(complete => complete),
      switchMap(() => this.authService.loggedIn$),
      tap(async isAuthenticated => {
        console.log('Can Activate Auth Guard', isAuthenticated);
        if (!isAuthenticated) {
          const isRefreshing = await this.authService.isRefreshing$.pipe(filter(r => r === false), take(1)).toPromise();
          const isAuthAfter = await this.authService.loggedIn$.pipe(take(1)).toPromise();
          console.log('Can Activate Auth Guard After Refresh', isAuthAfter, isRefreshing);
          if (!isAuthAfter) {
            return this.navCtrl.navigateRoot(environment.defaultRoute); // Move them to non-auth page
          }
        }
        return true;
      }),
    );
  }
}
