import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ValidatorDeclaration, Validators as ReactiveValidators } from 'angular-reactive-validation';
import { indexOf } from 'lodash-es';
import { ValidationService } from '../services/validation.service';

@Injectable({
  providedIn: 'root'
})

export class UserValidationService {
  static nameValidator = ReactiveValidators.compose([
    ValidationService.requiredValidator(),
    ValidationService.maxLengthValidator(100)
  ]);

  static emailValidator(message = 'Please enter a valid email'): ValidatorFn {
    return ValidatorDeclaration.wrapNoArgumentValidator((control) =>
      PrivateValidators.emailCheck(control), 'invalidEmailAddress')(message);
  }

  static passwordValidator(message = 'Password must contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character, and be minimumn of 8 characters long'): ValidatorFn {
    return ValidatorDeclaration.wrapNoArgumentValidator((control) =>
      PrivateValidators.passwordCheck(control), 'invalidPassword')(message);
  }

  static usernameValidator(message = 'Invalid username, must contain letters/numbers, no spaces.'): ValidatorFn {
    return ValidatorDeclaration.wrapNoArgumentValidator((control) =>
      PrivateValidators.usernameCheck(control), 'invalidPassword')(message);
  }

  constructor() {}
}

class PrivateValidators {
  constructor() {}

  static emailCheck(control: AbstractControl): null | { invalidEmailAddress: boolean } {
    // RFC 2822 compliant regex
    // tslint:disable-next-line:max-line-length
    if (control.value && control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  static passwordCheck(control: AbstractControl): null | { invalidPassword: boolean } {
    // Password must contain at least 1 uppercase, 1 lower case, 1 number, 1 special character, and be minimumn of 8 characters long
    if (control.value == null) {
      return null;
    }
    if (control.value.match(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/)) {
      return null;
    } else {
      return { invalidPassword: true };
    }
  }

  static usernameCheck(control: AbstractControl) {
    if (control.value != null) {
      if (indexOf(control.value, ' ') === -1) {
        return null;
      }
    }
    return { invalidUsername: true };
  }
}
