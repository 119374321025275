import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Subscription } from 'rxjs';

import { RlsUserNotification } from 'src/app/models/notifications/rls-user-notification';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-profile-popover',
  templateUrl: './profile-popover.component.html',
  styleUrls: ['./profile-popover.component.scss'],
})
export class ProfilePopoverComponent implements OnInit, OnDestroy {

  routerMatchOpts: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'ignored',
    paths: 'subset',
    fragment: 'ignored',
  };

  isCompanyAdmin: boolean = false;
  isCompanyUser: boolean = false;
  isEndUser: boolean = false;
  settingsLinks = [];

  loggedInSub: Subscription;

  @Input() notifications: RlsUserNotification[];
  @Input() dismissPopover = () => { };
  unapprovedCompanyAdmin: boolean;

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private rolesService: RolesService) { }

  ngOnInit() {
    this.loggedInSub = this.authService.loggedIn.subscribe(async data => {
      this.unapprovedCompanyAdmin = data ? await this.rolesService.isCompanyAdmin$(true) : false; // for subscription management, company owner
      this.isCompanyAdmin = data ? await this.rolesService.isCompanyAdmin$() : false;
      this.isCompanyUser = data ? await this.rolesService.isCompanyUser$() : false;
      this.isEndUser = data ? await this.rolesService.isEndUser$() : false;
      // const value = await this.notificationService.userNotifications$.toPromise();
      this.settingsLinks = await this.generateMenu();
    });
  }

  async generateMenu() {
    return [
      {
        name: 'Profile',
        link: '/settings/edit-user',
        icon: 'person-circle-outline'
      },
      {
        name: 'Notifications',
        link: '/notifications',
        icon: 'notifications-outline',
        badge: this.notifications?.length > 9 ? '9+' : this.notifications?.length
      },
      {
        name: 'Request Tickets',
        link: '/request-tickets',
        icon: 'clipboard-outline',
        hidden: !this.isCompanyUser && !this.isCompanyAdmin,
        navigationExtras: {
          queryParams: { type: 'user' }
        },
        activeOptions: {
          queryParams: 'exact',
          matrixParams: 'ignored',
          paths: 'exact',
          fragment: 'ignored'
        }
      },
      {
        name: 'Edit Company',
        link: '/settings/edit-company',
        icon: 'create-outline',
        hidden: !this.isCompanyAdmin
      },
      {
        name: 'Manage Subscription',
        link: '/settings/manage-subscription',
        icon: 'settings-outline',
        hidden: Capacitor.isNativePlatform() || !this.unapprovedCompanyAdmin
      },
      {
        name: 'Manage Subscription',
        link: '/settings/manage-subscription-iap',
        icon: 'settings-outline',
        hidden: !Capacitor.isNativePlatform() || Capacitor.getPlatform() === 'android'
      },
      {
        name: 'Settings',
        link: '/settings',
        icon: 'settings-outline',
        hidden: !this.isCompanyAdmin,
        activeOptions: {
          queryParams: 'ignored',
          matrixParams: 'ignored',
          paths: 'exact',
          fragment: 'ignored',
        }
      },
      {
        name: 'Company Requests',
        link: '/request-tickets',
        icon: 'clipboard-outline',
        hidden: !this.isCompanyAdmin,
        navigationExtras: {
          queryParams: { type: 'company' }
        },
        activeOptions: {
          queryParams: 'exact',
          matrixParams: 'ignored',
          paths: 'exact',
          fragment: 'ignored'
        }
      },
      {
        name: 'Delete Account',
        link: '/delete-account',
        icon: 'warning-outline',
        activeOptions: {
          queryParams: 'ignored',
          matrixParams: 'ignored',
          paths: 'exact',
          fragment: 'ignored',
        }
      },
    ];
  }

  ngOnDestroy(): void {
    this.loggedInSub?.unsubscribe();
  }

  logout(): Promise<void> {
    this.dismissPopover();
    return this.authService.signOut();
  }

}
