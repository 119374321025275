import { DOCUMENT } from '@angular/common';
import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { MenuController, Platform } from '@ionic/angular';
import { SIZE_TO_MEDIA } from '@ionic/core/dist/collection/utils/media';
import { xor } from 'lodash-es';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, filter, map, mergeMap, take } from 'rxjs/operators';

import 'cordova-plugin-purchase/www/store.d';
import {
  AuthActions,
  AuthService as OIDCService,
  IAuthAction
} from 'ionic-appauth';
import { environment } from 'src/environments/environment';
import SwiperCore, { Autoplay, Keyboard, Navigation, Pagination } from 'swiper';

import { CustomAuthService } from './auth/factories';
import { NavService } from './nav.service';
import { AuthService } from './services/auth.service';
import { CompanyService } from './services/company.service';
import { GoogleApiService } from './services/google-api.service';
import { IapSubscriptionPackageService } from './services/iap-subscription-package.service';
import { SubscriptionPackageService } from './services/subscription-package.service';
import { SystemSettingService } from './services/system-setting.service';
import { TitleService } from './services/title.service';
import { ToasterService } from './services/toaster.service';
import { UserService } from './services/user.service';
import { Utilities } from './shared/util';

const TOKEN_KEY_RESPONSE = 'token_response';
SwiperCore.use([Autoplay, Navigation, Keyboard, Pagination]); // Install swiper

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {

  @HostBinding('class.loading-header')
  get isHeaderLoading() {
    return this.isLoading;
  }

  @HostBinding('class.loading-sidemenu')
  get isMenuLoading() {
    return this.isLoading;
  }

  opened: boolean = false;
  navSub: Subscription;
  authActionSub: Subscription;
  authSub: Subscription;
  isNative: boolean = false;
  isLoading: boolean = false;

  private mapsLoaded = false;
  private networkHandler = null;
  silentLoadAccessToken: boolean = false;
  googleMapsApiKey = new BehaviorSubject<string>(environment.googleMaps.apiKey); // default value is the environment setting
  googleMapsApiKey$ = this.googleMapsApiKey;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private platform: Platform,
    public titleService: TitleService,
    private auth: AuthService,
    private oidc: OIDCService,
    private userService: UserService,
    private toaster: ToasterService,
    private renderer: Renderer2,
    private menu: MenuController,
    private gapi: GoogleApiService,
    private ccService: NgcCookieConsentService,
    private companyService: CompanyService,
    private systemSettingService: SystemSettingService,
    private iapService: IapSubscriptionPackageService,
    private subService: SubscriptionPackageService,
    private navService: NavService,
    @Inject(DOCUMENT) private _document
  ) {
    this.initializeApp();
  }

  ngOnDestroy(): void {
    this.navSub?.unsubscribe();
    this.authActionSub?.unsubscribe();
    this.authSub?.unsubscribe();
  }

  async storeReady() {
    const { store, ProductType } = CdvPurchase;
    //if (Capacitor.getPlatform() === 'ios') {
    await this.auth.loggedIn.pipe(filter(v => v != null)).pipe(take(1)).toPromise();
    this.auth.loggedIn$.subscribe(async isLoggedIn => {
      if (isLoggedIn === true) {
        const u = await this.userService.getUser$();
        store.applicationUsername = () => u?.id || null;
      }
    });

    store.verbosity = CdvPurchase.LogLevel.ERROR;
    store.validator_privacy_policy = [
      'fraud', 'support', 'analytics', 'tracking'
    ];
    store.validator = Capacitor.getPlatform() === 'ios' ? environment.paymentsConfig.appStore.validatorUrl : environment.paymentsConfig.googlePlay.url;

    // Show errors for 10 seconds.
    store.error((error) => {
      console.error(error);
      void this.toaster.error(error.message, 5000);
    });

    const options = await this.subService.getOptions();
    const storePlatform = Capacitor.getPlatform() === 'ios' ? CdvPurchase.Platform.APPLE_APPSTORE : CdvPurchase.Platform.GOOGLE_PLAY;
    const productsToRegister = options.filter(opt => opt.externalId != null).map(opt => {
      return { id: opt.externalId, type: ProductType.PAID_SUBSCRIPTION, platform: storePlatform };
    });

    this.iapService.store = store;
    store.register(productsToRegister);

    // https://github.com/j3k0/cordova-plugin-purchase/wiki/HOWTO:-Migrate-to-v13#product_ownership
    store.when().productUpdated(product => {
      console.log('Update', product);
      this.iapService.updateProducts(product);
    }).approved((t) => {
      console.log('Approved Transaction', t);
      return t.verify();
    }).verified(r => {
      this.iapService.setProductsWithoutTrials(r.raw.ineligible_for_intro_price);
      const owned = r.collection.find(purchase => {
        if (store.owned(purchase) && purchase.isExpired != true) {
          return true
        }
      });

      if (owned != null) {
        const t: any = (owned as any);
        this.iapService.transactionId.next(t.originalTransactionId);
        this.fetchAndSetIAP(store, t.originalTransactionId);
      } else if (r.collection?.length > 0) {
        const tId = (r.nativeTransactions[0] as any)?.original_transaction_id;
        this.iapService.transactionId.next(tId);
        this.fetchAndSetIAP(store, tId);
      } else {
        this.iapService.transactionId.next(null);
        this.iapService.currentSubscriptionIAP.next(null);
      }
      return r.finish();
    });

    await store.initialize([{
      platform: CdvPurchase.Platform.APPLE_APPSTORE
    }]);

    const loadedProducts = await this.iapService.products$.pipe(take(1)).toPromise();
    if (loadedProducts?.length != store.products?.length) {
      this.iapService.setProducts(store.products);
    }
  }

  checkStoreReady() {
    if (!window.CdvPurchase || !window['cordova']) {
      setTimeout(this.checkStoreReady, 100); // not loaded yet, check again in 100ms
    } else {
      void this.storeReady(); // plugin is available, call a "deviceReady()" function that you define.
    }
  }

  async fetchAndSetIAP(store: CdvPurchase.Store, originalTransactionId: string): Promise<void> {
    try {
      const currentSub = await this.subService.getCurrentSubByTransaction(originalTransactionId);
      this.iapService.currentSub.next(currentSub);
      if (currentSub != null) {
        this.iapService.currentSubscriptionIAP.next(store.get(currentSub.externalSubscriptionId));
      }
    } catch (err) {
      console.error(err);
      this.iapService.currentSubscriptionIAP.next(null);
    }
  }

  initializeApp() {
    // this.menuController.isOpen().then(res => this.opened = res);
    this.isLoading = true;
    void this.platform.ready().then(async () => {
      this.isNative = Capacitor.isNativePlatform();
      this.loadSDK();
      await this.oidc.init();

      // For now only ios supports IAP
      if (this.isNative === true && Capacitor.getPlatform() === 'ios') {
        this.checkStoreReady();
      }

      if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
        await SplashScreen.hide();
      }
      if (Capacitor.isNativePlatform() === false && this.ccService.hasConsented() === false) {
        this.ccService.open();
      }
      Utilities.storageGetItem('sideMenuOpen').then(res => {
        if (!Capacitor.isNativePlatform() && res === 'true') {
          this.handleMenuToggle();
        }
      });


      // App.addListener('appStateChange', (state: any) => {
      //   console.log('App state changed', state);
      // });

      // App.addListener('appUrlOpen', (data: any) => {
      //   console.log('APP URL OPEN: ' + data.url);
      // });

      // App.addListener('appRestoredResult', (data: any) => {
      //   console.log('Restored result:', data);
      // });

      if (Capacitor.isNativePlatform() != true) {
        window.addEventListener('storage', async (ev) => {
          const prefix = Utilities.storageGetGroup();
          if (ev.key === `${prefix}.${TOKEN_KEY_RESPONSE}`) {
            if (ev.newValue != null) {
              this.silentLoadAccessToken = await this.auth.loggedIn$.pipe(take(1)).toPromise() ? true : false;
              (this.oidc as CustomAuthService)?.silentLoadToken().then(() => {
                if (window.location.href?.includes(environment.defaultRoute)) {
                  this.router.navigateByUrl('/');
                }
              }).finally(() => {
                this.silentLoadAccessToken = false;

              });
            } else {
              this.auth.signoutWithoutRedirect();
            }
          }
        })
      }
    });

    // Handle Authentication Events
    // NOTE: There is an issue with Refresh Token Invalidating
    // The application gets into a cycle of retrying getting the oidc-config/refreshing token
    // and it keeps failing and logging here, even though we are handling the logout here on RefreshFailed
    this.authActionSub = this.oidc.events$.subscribe((action: IAuthAction) => {
      console.log(action);
      this.postCallback(action);
    });

    this.navSub = this.router.events.pipe(filter(e => e instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((e) => {
      this.titleService.title.next('AncesTREE');
      // Native or mobile web browser
      if (Capacitor.isNativePlatform() || this.platform.is('mobileweb')) {
        this.menu.close('main-menu');
      }
    });
  }

  // Potential issue with failing to fetch and callback page
  async postCallback(action: IAuthAction) {
    if (action.action === AuthActions.LoadTokenFromStorageFailed) {
      this.auth.isRefreshing.next(false);
      this.auth.loggedIn.next(false);
    }
    if (action.action === AuthActions.SignInSuccess) {
      await this.oidc.loadTokenFromStorage();
      await this.oidc.loadUserInfo();
      this.auth.isRefreshing.next(false);
      this.auth.loggedIn.next(true);
      this.oidc.user$.pipe(filter(u => u != null), take(1)).subscribe(res => {
        this.auth.completeAuthentication(res).then((user) => {
          // TODO from here direct user to complete registration
          // the authguard service should probably also now check for this
          // so that any user that is not finished registering
          // can complete it.
          // That or we handle finish registering on the IdentityServer
          if (this.iapService.isSupported === true) {
            // Sync subcription of logged in user from device
            this.iapService.currentSub$.pipe(filter(sub => sub != null), take(1)).subscribe(async (currentSub) => {
              if (currentSub.subscriberId == null && currentSub.externalInvoiceId != null) {
                await this.subService.updateSubscriptionPostRegister(currentSub.externalInvoiceId, user.id)
                  .catch(err => { console.error(err); })
              }
            });
          }
          this.router.navigateByUrl('/');
        }).catch((err) => {
          console.error(err);
          this.auth.signoutWithoutRedirect();
          this.router.navigate(['/getting-started']);
          this.toaster.error('Failed to complete login, try again later', 5000);
        });
      });
    }

    // Upon Loading Token successfully, try to load the user from all means possible
    // Now always fetching user and company through getLoggedInUser on initial app load
    if (action.action === AuthActions.LoadTokenFromStorageSuccess) {
      this.auth.loggedIn.next(true);
      let user = await this.userService.getUser$(); // load from memory
      if (this.silentLoadAccessToken == true) { return; }
      const callbackLoginURI = environment.oidcConfig.redirect_url;
      const callbackLogoutURI = environment.oidcConfig.end_session_redirect_url;

      const loginPathing = callbackLoginURI.split('/');
      const loginPath = loginPathing[loginPathing.length - 1];
      const logoutPathing = callbackLogoutURI.split('/');
      const logoutPath = logoutPathing[logoutPathing.length - 1];
      const currentPath = window.location.href;
      // TODO may want to use something more angualr
      // const currentPath = await this.activatedRoute.url.pipe(take(1)).toPromise();
      if (currentPath?.includes(loginPath) == true || currentPath?.includes(logoutPath) == true) {
        // Ignore as it's handled From Complete Auth or Signout Success
        return;
      } else {
        // Make null even if in memory to fetch latest
        user = null;
      }
      //  else if (user == null) {
      //   user = await this.userService.initUser(); // load from storage
      // }
      if (user == null) {
        // fetch from server
        try {
          user = await this.userService.getLoggedInUser();
          const apiRoles = user.apiRoles.map(r => r.reference);
          const roleClaims = this.userService.getRolesOnJWT(action.tokenResponse.accessToken, apiRoles);
          const companyIdOnToken = this.userService.getCompanyIdOnJWT(action.tokenResponse.accessToken);
          const company = await this.companyService.getCompany$();
          const isCompanyValid = company?.approved === true ? user.companyAccountId == company?.id : companyIdOnToken == user.companyAccountId;
          if (xor(roleClaims, apiRoles)?.length > 0 || isCompanyValid == false) {
            await this.oidc.refreshToken();
          }
          // TODO If user.isRegistered == false, redirect them to complete profile
        } catch (err) {
          console.error(err);
          user = null;
          // This handles the case in which a token is not being refreshed, as its technically valid
          // but the rest of the this should be handled by a refresh failing.
          if (err?.status === 401 || err?.status === 404) {
            this.auth.signoutWithoutRedirect();
          } else if (err?.status == 0) {
            this.toaster.error(err.display);
          }
        }
      }
    }

    if (action.action === AuthActions.SignInFailed) {
      this.auth.isRefreshing.next(false);
      this.auth.loggedIn.next(false);
      // if (isNullOrEmpty(actin.error) == false) {
      //   this.toaster.error(action.error);
      // }
      await this.router.navigate([environment.defaultRoute]);
    }

    // TODO Maybe comment this back and just take the newValue from the listener instead
    // if null then redirect to welcome
    // as its only purpose is to redirect to welcome on signout, maybe even due to it from something else entirely.
    // The below doesn't work as register and other screens while unauthenticated would cause a redirect if refreshed
    // if (action.action === AuthActions.LoadTokenFromStorageFailed) {
    //   const callbackLoginURI = environment.oidcConfig.redirect_url;
    //   const callbackLogoutURI = environment.oidcConfig.end_session_redirect_url;
    //   const currentPath = window.location.href;
    //   if (currentPath.includes(callbackLoginURI) === false && currentPath.includes(callbackLogoutURI) === false) {
    //     this.auth.loggedIn.next(false);
    //     this.router.navigate([environment.defaultRoute]);
    //   }

    // }

    if (action.action === AuthActions.SignOutSuccess) {
      this.auth.loggedIn.next(false);
      this.auth.isRefreshing.next(false);
      this.router.navigate([environment.defaultRoute]);
    }
    if (action.action === AuthActions.RefreshFailed) {

      // TODO prevent refresh failed error on status == 0
      // if (action.error == 'Unable To Obtain Server Configuration') {
      //   console.error('Failed to connect'); // TODO display this somehow/maybe a custom IAuthAction if possible
      //   // this.toast.error('Failed to connect, try again later');
      //   return;
      // }
      this.auth.signoutWithoutRedirect();
      this.toaster.error('Session Expired, login again to continue.');
    }
    if (action.action === AuthActions.RefreshSuccess) {
      try {
        // TODO await till isRefreshing is false should work
        console.log('Awaiting Refresh is false');
        this.auth.loggedIn.next(true);
        let isRefreshing = await this.auth.isRefreshing$.pipe(take(1), filter(v => v == false)).toPromise();
        console.log('Refresh is false', isRefreshing);
        // await new Promise(resolve => setTimeout(resolve, 450));
        // isRefreshing = await this.auth.isRefreshing$.pipe(take(1)).toPromise();
        // console.log('Is Refreshing After Timeout', isRefreshing);
        await this.userService.getLoggedInUser(); // this has an old access token, when a previous token was valid. need to work around or fix somehow...
      } catch (err) {
        console.error(err);
        if (err?.status === 401 || err?.status === 404) {
          this.auth.signoutWithoutRedirect();
        }
      }
    }
  }


  private loadSDK(): Promise<any> {
    console.log("Loading Google Maps SDK");
    return new Promise((resolve, reject) => {
      if (!this.mapsLoaded) {
        Network.getStatus().then((status) => {
          if (status.connected) {
            this.injectSDK().then((res) => {
              resolve(true);
            }, (err) => {
              reject(err);
            });
          } else {
            if (this.networkHandler == null) {
              this.networkHandler = Network.addListener('networkStatusChange', (status) => {
                if (status.connected) {
                  this.networkHandler.remove();
                  this.loadSDK().then((res) => {
                    console.log("Google Maps ready.")
                  }, (err) => {
                    console.log(err);
                  });
                }
              });
            }
            reject('Not online');
          }
        }, (err) => {
          // NOTE: navigator.onLine temporarily required until Network plugin has web implementation
          if (navigator.onLine) {
            this.injectSDK().then((res) => {
              resolve(true);
            }, (err) => {
              reject(err);
            });
          } else {
            reject('Not online');
          }
        });
      } else {
        reject('SDK already loaded');
      }
    });
  }

  private injectSDK(): Promise<any> {
    return new Promise((resolve, reject) => {
      window['initMap'] = async () => {
        this.mapsLoaded = true;
        await Utilities.storageSetItem('invalidGoogleAPIKey', false);
        this.gapi.invalidGoogleApiKey.next(false);
        resolve(true);
      }
      window['gm_authFailure'] = async () => {
        this.mapsLoaded = true;
        await Utilities.storageSetItem('invalidGoogleAPIKey', true);
        this.gapi.invalidGoogleApiKey.next(true);
        reject(false);
      }
      let script = this.renderer.createElement('script');
      this.systemSettingService.getGoogleMapsApiKey(environment.googleMaps.apiKey).then(key => {
        script.id = 'googleMaps';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${key?.length > 0 ? key : 'INVALID_KEY'}&libraries=places&language=en&callback=initMap`;
        this.renderer.appendChild(this._document.body, script);
      });
    });
  }

  handleMenuToggle() {
    if (!Capacitor.isNativePlatform() || window.matchMedia(SIZE_TO_MEDIA.md)?.matches === true) {
      this.opened = this.opened == true ? false : true;
      this.navService.setSidebarExpanded(this.opened);
      console.log('Store Menu');
      Utilities.storageSetItem('sideMenuOpen', this.opened ? 'true' : 'false');
    }
  }

  menuWillOpen(event) {
    this.opened = true;
    console.log('Menu Open', this.opened);
  }

  menuWillClose(event) {
    this.opened = false;
    console.log('Menu Close', this.opened);
  }
}

