import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';

import { RolesService } from 'src/app/services/roles.service';

@Injectable({
  providedIn: 'root'
})
export class ManagerGuard implements CanActivate, CanActivateChild {
  constructor(private rolesService: RolesService, private router: Router) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.rolesService.isManagerOrHigher$().then(res => {
      if (res == false) {
        return Promise.reject(false);
      }
      return res;
    }).catch(err => this.router.parseUrl('/'));
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.rolesService.isManagerOrHigher$().then(res => {
      if (res == false) {
        return Promise.reject(false);
      }
      return res;
    }).catch(err => this.router.parseUrl('/'));
  }

}
