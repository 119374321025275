<img *ngIf="this.image != null && this.image?.path != null" class="image-render"
  [ngClass]="{'allowPreview': this.allowPreview}" [style.width]="this.width" [style.height]="this.height"
  [style.max-width]="this.maxWidth" [style.max-height]="this.maxHeight" [src]="this.baseUrl + image?.path"
  [alt]="image?.name" (click)="this.launchPreviewModal()" />
<!-- TODO Consider adding a button for mobile ux -->
<!-- <ion-button class="expand-button" fill="clear" (click)="this.launchPreviewModal()">
  <ion-icon color="light" slot="icon-only" *ngIf="this.allowPreview" name="expand"></ion-icon>
</ion-button> -->
<ion-modal class="ulm-modal form-modal" #imagePreviewModal>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title class="ellipsis">Preview - {{image?.name}}</ion-title>
        <ion-buttons slot="end">
          <ion-button type="button" tabindex="-1" (click)="this.imagePreviewModal?.dismiss()">
            <ion-icon name="close" color="primary"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col>
            <ion-img class="img-preview" width="100%" height="100%" [src]="this.baseUrl + image?.path"
              [alt]="image?.name">
            </ion-img>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ng-template>
</ion-modal>
