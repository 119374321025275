import { Observable } from 'rxjs';

import { AppInjector } from '../core/app.injector';
import { BaseEntity } from '../models/baseEntity';
import { BaseEntityDto } from '../models/dto/base-entity-dto';
import { ApiService } from './api.service';

export abstract class BaseEntityService<T extends BaseEntity | BaseEntityDto> {

  protected api: ApiService;

  constructor() {
    const injector = AppInjector.getInjector();
    this.api = injector?.get(ApiService);
  }

  abstract get(): Observable<T[]> | Promise<T[]>;
  abstract getById(id: number): Observable<T> | Promise<T>;
  abstract getByUUID(uuid: string): Observable<T> | Promise<T>;

  abstract post(model: T): Observable<T> | Promise<T>;
  abstract put(model: T): Observable<T> | Promise<T>;

  abstract delete(model: T): Observable<boolean | any | void> | Promise<boolean | any | void>;

}
