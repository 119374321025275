<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start" class="ml-1 ml-md-2">
      <ion-button class="title-link" routerLink="/">
        <ion-img src="assets/images/app-logo-long-light.png" class="logo"></ion-img>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" class="mr-1 mr-md-2">
      <ion-button (click)="this.login();" color="primary" fill="outline">
        <span class="mat-button-label ion-hide-sm-down pr-2">Login</span>
        <ion-icon name="log-in-outline"></ion-icon>
      </ion-button>
      <ion-button [href]="this.email" color="primary" fill="outline">
        <span class="mat-button-label ion-hide-sm-down pr-2">Email Us Here</span>
        <ion-icon name="mail-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-card>
    <img class="bg-image" src="assets/images/home-main-image.jpg" />
    <ion-card-content>
      <img class="main-logo" src="assets/images/app-logo-small-home.png" />
      <h3 class="card-text brand-heading mt-8">
        AncesTREE<sup>®</sup> is a digital toolbox for the industry that brings members into the largest urban, salvaged and reclaimed wood marketplace in North America.
      </h3>
      <div class="action-buttons mt-8">
        <ion-button color="primary" [routerLink]="['/', { outlets: { 'modal': 'signup'}}]" [queryParams]="this.queryParams" skipLocationChange>Register </ion-button>
        <ion-button class="" routerLink="/registration-packages" color="primary">Packages and Pricing</ion-button>
      </div>
    </ion-card-content>
    <div class="overlay"></div>
  </ion-card>
  <!-- Mission Statement -->
  <div class="mission mt-12">
    <h5 color="primary">
      We believe in the power and strength of collaboration. Our mission is to facilitate the growth of urban lumber businesses by providing digital tools for efficient management and planning of log
      and lumber inventories. We are committed to providing top tier customer service to help our subscribers get the most out of our products. By aggregating inventories and working together, we are
      creating a robust, dynamic, easy to use multi-vendor marketplace that showcases the unique and high-quality wood products our industry offers. Together, we can support our communities and build
      a sustainable future for urban, salvaged, and reclaimed woods, as well as the businesses who work with them, keeping these woods out of the waste stream and giving them a second life.
    </h5>
  </div>
  <!-- Membership Information -->
  <div class="membership-container mt-12">
    <h5 color="primary">Members Receive</h5>
    <div class="items-container">
      <div class="item">
        <div class="icon-container">
          <ion-icon name="stats-chart-outline"></ion-icon>
        </div>
        <div class="description-container">
          <h5>An inventory management system app that streamlines operations and simplifies your daily procedures.</h5>
        </div>
      </div>

      <div class="item">
        <div class="icon-container">
          <ion-icon name="cart-outline"></ion-icon>
        </div>
        <div class="description-container">
          <h5>
            Easily post products from your AncesTREE<sup>®</sup> inventory directly to the multi-vendor marketplace with just a few clicks.
            <small class="ion-text-center">*Single-vendor marketplace option is available, inquire for info. </small>
          </h5>
        </div>
      </div>

      <div class="item">
        <div class="icon-container">
          <ion-icon name="ribbon-outline"></ion-icon>
        </div>
        <div class="description-container">
          <h5>Follow the chain of custody of your wood products, and adhere to USRW Certification.</h5>
        </div>
      </div>
      <div class="item">
        <div class="icon-container">
          <ion-icon name="rocket-outline"></ion-icon>
        </div>
        <div class="description-container">
          <h5>Get ready for your business to take off by utilizing our aggregated online marketplace where customers can more easily find your urban, salvaged and reclaimed wood products.</h5>
        </div>
      </div>
    </div>
  </div>
  <!-- Quote -->
  <div class="quote-container">
    <div class="content">
      <h2>AncesTREE<sup>®</sup> takes your lumber business from chaos to control</h2>
      <h4 class="quote">
        <em
          >Ready to get organized and take control of your log and lumber inventory? Register for AncesTREE<sup>®</sup> today to stop rework, reduce chaos, increase profits and start selling on the
          aggregated marketplace with just one click. AncesTREE<sup>®</sup> will free up your time, allowing you to focus on what you love the most.</em
        >
      </h4>
      <!-- <h2 class="ion-text-end"><em>Jennifer Alger, CEO, Far West Forest Products</em></h2> -->
    </div>
  </div>
  <!-- Footer -->
  <div class="footer-container bg-primary">
    <div class="footer-content">
      <div class="benefits">
        <h4>AncesTREE<sup>®</sup> Inventory Management App Features & Benefits:</h4>
        <h5>Improved Business Organization:</h5>
        <ul>
          <li>Streamlines operations, allowing you to know what you have and where it is.</li>
        </ul>
        <h5>User-Friendly Interface:</h5>
        <ul>
          <li>Streamlines the process of tracking, updating, and managing inventory with in-app QR code scanner.</li>
          <li>
            Add critical item details with specialized fields such as wood source, species, cut type, and dimensions.
            <ul>
              <li>Auto-Calculate board footage of logs and lumber.</li>
              <li>Auto-Calculate and display the CO2e stored in the individual log and lumber items as well as the amount stored in your total inventory.</li>
              <li>Upload real-time photos of your items from your mobile device to visually track each item.</li>
            </ul>
          </li>
          <li>
            Track the history of logs and lumber in your inventory.
            <ul>
              <li>Input and view the processing history of items, including lumber milled from logs in your inventory.</li>
              <li>USRW Certification and Chain of Custody tracking.</li>
            </ul>
          </li>
          <li>Add your own customizable storage locations.</li>
          <li>Document pricing and shipping information.</li>
        </ul>
        <h5>Real-Time Updates:</h5>
        <ul>
          <li>
            Inventory tracking and product listing that your whole team has access to.
            <ul>
              <li>Easily search for inventory using customizable filters.</li>
            </ul>
          </li>
          <li>Provides real-time inventory status helping to ensure you’re not selling products online that have already been sold.</li>
          <li>Easily export custom CSV reports.</li>
        </ul>
        <h5>Quick Posting:</h5>
        <ul>
          <li>Allows products to be posted to the marketplace with just a few clicks, reducing rework.</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer-container bg-secondary">
    <div class="footer-content">
      <div class="benefits-2">
        <h4>AncesTREE<sup>®</sup> Inventory Management App Features & Benefits:</h4>
        <h5>Increased Market Reach:</h5>
        <ul>
          <li>
            Aggregates inventories, allowing you to reach larger markets by providing a platform for urban lumber businesses to showcase their products - Let AncesTREE<sup>®</sup> help you grow your
            sales… it’s like having a built in marketing company helping you to sell your products
          </li>
          <li>Facilitates increased sales through a broader customer base, advanced customer search options,and improved marketplace presence.</li>
        </ul>
        <h5>Customer Access:</h5>
        <ul>
          <li>Connects businesses directly with customers looking for urban wood products.</li>
        </ul>
        <h5>Competitive Edge:</h5>
        <ul>
          <li>Levels the playing field by enabling smaller businesses to compete with larger industry players.</li>
          <li>Reduced shipping costs by sharing 3rd party specially negotiated rates.</li>
          <li>Increased profits.</li>
        </ul>
        <h5>Sustainability:</h5>
        <ul>
          <li>Promotes the use of urban wood, contributing to environmental sustainability.</li>
        </ul>
        <h5>Streamlined Transactions:</h5>
        <ul>
          <li>Simplifies the buying and selling process, making transactions more efficient.</li>
          <li>Sales tax collection and reporting to save you time and frustration.</li>
        </ul>
        <h5>Quality Products:</h5>
        <ul>
          <li>Highlights the unique and high-quality wood products available from urban lumber sources.</li>
        </ul>
        <h5>Community Support:</h5>
        <ul>
          <li>Supports local businesses and contributes to the economic growth of the community.</li>
        </ul>
      </div>
    </div>
    <!-- <div class="coming-soon-container">
        <h4 class="mb-0">Coming Soon!</h4>
        <div class="coming-soon-content">
          <h5>Make sure you're on the list for exclusive updates and early-bird subscription.</h5>
          <ion-button color="light" fill="outline" class="ml-6 ion-align-self-center"
            [routerLink]="['/', { outlets: { 'modal': 'signup'}}]" [queryParams]="this.queryParams">Register Today
          </ion-button>
        </div>
      </div> -->
    <div class="ion-text-center">
      <ion-button class="shop-button" color="primary" size="large" href="https://www.ancestree.io/shop" target="_blank">Shop Now</ion-button>
    </div>
  </div>

  <div class="uwn-footer ion-justify-content-between">
    <div class="uwn-content ml-2">
      <ion-button class="legal-link" size="small" fill="clear" color="light" target="_blank" [href]="this.systemSettings.tosLink$ | async">Terms of Service</ion-button>
      <ion-button class="legal-link" size="small" fill="clear" color="light" target="_blank" [href]="this.systemSettings.ppLink$ | async">Privacy Policy</ion-button>
    </div>
    <div class="uwn-content mr-2">
      <div class="uwn-text-container">
        <h5 class="uwn-text">Member of</h5>
      </div>
      <a href="https://urbanwoodnetwork.org/" target="_blank" class="uwn-logo-container">
        <img class="uwn-logo" src="assets/TM-UWN-logo.png" height="36px" />
      </a>
    </div>
  </div>
</ion-content>
