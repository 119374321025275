import * as uuid from 'uuid';

import { Exclude } from 'class-transformer';

export class BaseEntityDto {
  id: number;
  uuid: string;

  constructor() {
    this.id = 0;
    this.uuid = uuid.v4();
  }

  @Exclude({ toPlainOnly: true })
  public get isNew(): boolean {
    return this.id === 0;
  }
}