import { map, reduce, toUpper } from 'lodash-es';

import { Type } from 'class-transformer';

import { Attachment } from './attachment';
import { BaseEntity } from './baseEntity';
import { CutTypeGroup } from './cut-type-group';
import { IAttachable } from './interfaces/IAttachable';
import { IOtherable } from './interfaces/IOtherable';

export class CutType extends BaseEntity implements IAttachable, IOtherable {

    constructor() {
        super();
        this.isActive = true;
        this.isOther = false;
    }
    get attachableName(): string {
        return 'CUTTYPE';
    }

    reference: string;
    description: string;
    tooltip: string;
    isActive: boolean;
    isOther: boolean;

    cutTypeGroupId: number;
    @Type(() => CutTypeGroup)
    cutTypeGroup: CutTypeGroup;

    cutTypeSubGroupId: number;
    @Type(() => CutTypeGroup)
    cutTypeSubGroup: CutTypeGroup;

    dimensionGraphicId: number;
    @Type(() => Attachment)
    dimensionGraphic: Attachment;


    // Getters
    get isBoard(): boolean {
        return toUpper(this.cutTypeGroup?.reference)?.includes('BOARD');
    }

    get isSlab(): boolean {
        return toUpper(this.cutTypeGroup?.reference)?.includes('SLAB');
    }

    get isSquare(): boolean {
        return toUpper(this.cutTypeSubGroup?.reference)?.includes('SQUARE');
    }

    get isRound(): boolean {
        return toUpper(this.cutTypeSubGroup?.reference)?.includes('ROUND');
    }

    get isLive(): boolean {
        return toUpper(this.cutTypeSubGroup?.reference)?.includes('LIVE');
    }

    get isVeneer(): boolean {
        return toUpper(this.cutTypeGroup?.reference)?.includes('VENEER');
    }

    get isBookMatched(): boolean {
        return toUpper(this.cutTypeGroup?.reference)?.includes('BOOK MATCH');
    }

    get isTurningBlock(): boolean {
        return toUpper(this.cutTypeGroup?.reference)?.includes('TURNING BLOCK');
    }

    get isTurning(): boolean {
        return toUpper(this.cutTypeGroup?.reference)?.includes('TURNING');
    }

    get isPostsMore(): boolean {
        return toUpper(this.cutTypeGroup?.reference)?.includes('POSTS, BEAMS, OR TIMBERS');
    }

    get isBases(): boolean {
        return toUpper(this.cutTypeGroup?.reference)?.includes('BASES');
    }

    get isCookie(): boolean {
        return toUpper(this.cutTypeGroup?.reference)?.includes('COOKIE');
    }

    get isGroupOther(): boolean {
        return this.cutTypeGroup?.isOther;
    }

    get isSubOther(): boolean {
        return this.cutTypeSubGroup?.isOther;
    }

    get isUniform(): boolean {
        const text = toUpper(this.cutTypeSubGroup.reference);
        return text?.includes('UNIFORM') && this.isNonUniform != true;
    }

    get isNonUniform(): boolean {
        const possibilities = ['NON UNIFORM', 'NONUNIFORM', 'NON-UNIFORM'];
        const result = map(possibilities, s => this.cutTypeSubGroup?.reference != null ?
            toUpper(this.cutTypeSubGroup?.reference).includes(s) : false);
        return reduce(result, (r, b) => r || b);
    }

    get isLiveUniform(): boolean {
        return this.isLive && this.isUniform && !this.isNonUniform;
    }

    get isLiveNonUniform(): boolean {
        return this.isLive && this.isNonUniform;
    }
}
