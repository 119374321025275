import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Requestor, StorageBackend } from '@openid/appauth';

import { AuthService, Browser } from 'ionic-appauth';
import {
  CapacitorBrowser,
  CapacitorStorage
} from 'ionic-appauth/lib/capacitor';

import { AuthInterceptor } from '../interceptors/auth-interceptor';
import { authFactory } from './factories';
import { NgHttpService } from './ng-http.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: StorageBackend,
      useClass: CapacitorStorage,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: Requestor,
      useClass: NgHttpService,
    },
    {
      provide: Browser,
      useClass: CapacitorBrowser,
    },
    {
      provide: AuthService,
      useFactory: authFactory,
      deps: [Platform, NgZone, Requestor, Browser, StorageBackend]
    }
  ]
})
export class AuthModule { }
