import { User } from './user';
import { CompanyAccount } from './company-account';
import { BaseEntity } from './baseEntity';

export class Email extends BaseEntity {
    
    constructor() {
        super();
        this.isActive = true;
    }

    // Properties
    reference?: string;
    address: string;
    isActive: boolean;

    // Relationships

    defaultForUserAccount: User;
    defaultForCompanyAccount: CompanyAccount;

    // rlsUserEmail: RlsUserEmail[];
    // rlsCompanyEmail: RlsCompanyEmail[];
}