import { Type } from 'class-transformer';
import { CompanyAccount } from '../company-account';
import { Phone } from '../phone';
import { BaseEntity } from '../baseEntity';

export class RlsCompanyPhone extends BaseEntity {

    constructor() {
        super();
        this.phone = new Phone();
        this.isPush = false;
        this.isTxt = false;
    }

    isPush: boolean;
    isTxt: boolean;

    @Type(() => Phone)
    phone: Phone;
    phoneId: number;

    companyAccount: CompanyAccount;
    companyAccountId: number;
}
