import { Exclude } from 'class-transformer';
import * as uuid from 'uuid';

export class BaseEntity {

    constructor() {
        this.id = 0;
        this.uuid = uuid.v4();
    }

    public id: number;
    public uuid: string;

    @Exclude({ toPlainOnly: true })
    public get isNew(): boolean {
        return this.id === 0;
    }
}
