import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';

import { NonMobileNavService } from '../services/non-mobile-nav.service';

@Injectable({
  providedIn: 'root'
})
export class RestrictPlatformGuard implements CanActivate {

  constructor(private router: Router, private nonMobileNavService: NonMobileNavService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const restrictedPlatforms: string[] = route?.data?.restrict as string[] || [];
    const platform = Capacitor.getPlatform();
    if (!restrictedPlatforms.includes(platform)) {
      return true;
    }
    if (Capacitor.isNativePlatform() === true) {
      this.nonMobileNavService.hasReturned.next(true);
    }
    return this.router.parseUrl('/');
  }

}
