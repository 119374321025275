import { Type } from 'class-transformer';
import { CompanyAccount } from 'src/app/models/company-account';
import { BaseEntity } from '../baseEntity';
import { IOtherable } from '../interfaces/IOtherable';
import { LumberItem } from './lumber-item';

export class ItemGrade extends BaseEntity implements IOtherable {

  constructor() {
    super();
    this.isActive = true;
    this.isOther = false;
    this.isRemoval = false;
    this.isReclaim = false;
    this.isLumber = false;
  }

  reference: string;
  description: string;
  isActive: boolean;
  isOther: boolean;

  isRemoval: boolean;
  isReclaim: boolean;
  isLumber: boolean;

  cocPoints?: number;

  companyAccountId: number;
  @Type(() => CompanyAccount)
  companyAccount: CompanyAccount;

  lumberItems: LumberItem[];

}
