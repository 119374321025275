import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {

  constructor() { }

  invalidGoogleApiKey: BehaviorSubject<boolean> = new BehaviorSubject(false);
  invalidGoogleApiKey$: Observable<boolean> = this.invalidGoogleApiKey;


}
