import { Injectable } from '@angular/core';
import { isNaN as _isNaN } from 'lodash-es';

import { MeasurementSystem } from '../models/measurement-system';

@Injectable({
  providedIn: 'root'
})
export class UnitOfMeasurementService {

  constructor() { }

  /**
   *
   * @param feet
   * @returns
   */
  public static convertFeetToInches(feet: number): number {
    if (feet == null || _isNaN(feet)) return null;
    return ((Math.trunc(feet)) * 12) + ((feet % 1) * 12);
  }

  /**
   *
   * @param value
   * @returns
   */
  public static convertInchesToFeet(value: number): ImperialDistance {
    if (value == null || _isNaN(value)) { return { feet: null, inches: null }; }
    return { feet: Math.trunc(value / 12), inches: value % 12 };
  }

  public static convertInchesToMeters(inches: number) {
    if (inches == null || _isNaN(inches)) return null;
    return inches / 39.37;
  }

  public static convertMetersToInches(meters: number) {
    if (meters == null || _isNaN(meters)) return null;
    return meters * 39.37;
  }

  public static convertFahrenheitToCelsius(temp: number) {
    if (temp == null || _isNaN(temp)) return null;
    return temp / 33.8;
  }

  public static convertCelsiusToFahrenheit(temp: number) {
    if (temp == null || _isNaN(temp)) return null;
    return temp * 33.8;
  }

  public static convertPoundsToKG(pounds: number): number {
    return (pounds * 453.59265) / 1000.0;
  }

  public static convertKGToPounds(kg: number): number {
    return (kg * 1000) / 453.59265;
  }

}

export interface ImperialDistance {
  feet: number;
  inches: number;
}