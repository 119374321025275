import { NgZone } from '@angular/core';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';
import {
  GRANT_TYPE_REFRESH_TOKEN,
  Requestor,
  StorageBackend,
  TokenRequest,
  TokenRequestJson,
  TokenResponse
} from '@openid/appauth';
import { take } from 'rxjs/operators';

import {
  AuthActionBuilder,
  AuthService,
  Browser,
  IAuthService
} from 'ionic-appauth';

import { environment } from '../../../environments/environment';

const TOKEN_RESPONSE_KEY = "token_response";

export class CustomAuthService extends AuthService implements IAuthService {
  constructor(browser?: Browser, storage?: StorageBackend, requestor?: Requestor) {
    super(browser, storage, requestor);
  }

  public async removeTokens() {
    await this.storage.removeItem(TOKEN_RESPONSE_KEY);
  }

  protected async requestTokenRefreshWithExtras() {
    let _token = await this.token$
      .pipe(take(1))
      .toPromise();

    if (!_token) {
      throw new Error("No Token Defined!");
    }

    // this is a fallback in case the syncing between localstorage fails
    // Basically on refreshToken calls we will inspect to see if the refresh token
    // has changed in a different tab or window, if it has then we need to use it
    // as the refresh token is configured as a one time use, per documentation from ids
    let tokenResponseString: string | null = await this.storage.getItem(TOKEN_RESPONSE_KEY);

    if (tokenResponseString != null) {
      const loadTokenFromStorage = new TokenResponse(JSON.parse(tokenResponseString));
      if (loadTokenFromStorage.issuedAt > _token.issuedAt) {
        _token = loadTokenFromStorage;
      }
    }

    let requestJSON: TokenRequestJson = {
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      refresh_token: _token.refreshToken,
      redirect_uri: this.authConfig.redirect_url,
      client_id: this.authConfig.client_id,
      extras: {
        client_secret: this.authConfig.client_secret
      }
    };

    let token: TokenResponse = await this.tokenHandler.performTokenRequest(await this.configuration, new TokenRequest(requestJSON));
    await this.storage.setItem(TOKEN_RESPONSE_KEY, JSON.stringify(token.toJson()));
    this.notifyActionListers(AuthActionBuilder.RefreshSuccess(token));
  }

  public async refreshToken() {
    await this.requestTokenRefreshWithExtras().catch((response) => {
      this.storage.removeItem(TOKEN_RESPONSE_KEY);
      this.notifyActionListers(AuthActionBuilder.RefreshFailed(response));
    });
  }

  public async silentLoadToken() {
    return this.loadTokenFromStorage();
  }
}

export const authFactory = (platform: Platform, ngZone: NgZone,
  requestor: Requestor, browser: Browser, storage: StorageBackend) => {

  const authService = new CustomAuthService(browser, storage, requestor);
  authService.authConfig = environment.oidcConfig;

  if (platform.is('capacitor')) {
    // HACK For some reason on an initial cold launch this listener fails without
    // a setTimeout, will see if can remove after Capacitor 4.0 upgrade
    // this also only happend with latest Android API version
    setTimeout(() => {
      App.addListener('appUrlOpen', (data: any) => {
        if (data.url !== undefined) {
          ngZone.run(() => {
            if ((data.url).indexOf(authService.authConfig.redirect_url) === 0) {
              authService.authorizationCallback(data.url);
            } else if ((data.url).indexOf(authService.authConfig.end_session_redirect_url) === 0) {
              authService.endSessionCallback();
            }
          });
        }
      });
    });

  }

  return authService;
};
