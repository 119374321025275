import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  cloneDeep,
  findIndex,
  isNil,
  isUndefined,
  map as _map,
  omitBy
} from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as uuid from 'uuid';

import { classToPlain, plainToClass } from 'class-transformer';
import * as moment from 'moment';

import { Address } from '../models/address';
import { Attachment } from '../models/attachment';
import { CompanyAccount } from '../models/company-account';
import { ItemListDto } from '../models/dto/ItemListDto';
import { Geolocation } from '../models/geolocation';
import { LumberItemListFilter } from '../models/interfaces/ILumberItemListFilter';
import { IPaginationResult } from '../models/interfaces/IPaginationResult';
import { LumberItem } from '../models/lumber-items/lumber-item';
import { WoodType } from '../models/lumber-items/wood-type';
import { RlsLumberItemImage } from '../models/relations/rls-lumber-item-image';
import { AttachmentService } from './attachment.service';
import { BaseEntityService } from './base-entity.service';
import { ToasterService } from './toaster.service';
import { UserService } from './user.service';

export class CompanyInventoryListFilter {
  constructor() {
    this.includeInactive = true;
    this.includeSubItem = true;
  }
  companyId: number;
  qrcode: string;
  name: string;
  status: number;
  woodTypeId: number[];
  treeSpeciesId: number[];
  locationId: number;
  includeSubItem: boolean;
  includeInactive: boolean;
  parentId: number;
  fetchRelationships: string[];
}

@Injectable({
  providedIn: 'root'
})
export class LumberItemsService extends BaseEntityService<LumberItem> {

  endpoint: string = 'LumberItems';

  lumberItems: BehaviorSubject<LumberItem[]> = new BehaviorSubject([]);
  companyLumber: BehaviorSubject<LumberItem[]> = new BehaviorSubject([]);

  itemsList: LumberItem[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private toaster: ToasterService,
    private userService: UserService,
    private attachmentService: AttachmentService,
    private router: Router
  ) { super(); }

  viewSpreadsheet(items: LumberItem[]) {
    this.itemsList = items;
    this.router.navigate(['items-spreadsheet']);
  }

  get(): Observable<LumberItem[]> {
    return this.api.get(`${this.endpoint}/GetPublic`);
  }

  getByUUID(uuid: string): Promise<LumberItem> {
    return this.api.get(`${this.endpoint}/ByUUID/${uuid}`)
      .pipe(map(i => plainToClass(LumberItem, i)))
      .toPromise();
  }

  getById(itemId: number): Promise<LumberItem> {
    return this.api.get(`${this.endpoint}/${itemId}`)
      .pipe(map(i => plainToClass(LumberItem, i)))
      .toPromise();
  }

  getAddress(addressId: number): Promise<Address> {
    return this.api.get(`Address/${addressId}`)
      .pipe(map(res => plainToClass(Address, res)))
      .toPromise();
  }

  getChildren(uuid: string): Promise<LumberItem[]> {
    return this.api.get(`${this.endpoint}/${uuid}/Children`)
      .pipe(map(res => res?.map(o => plainToClass(LumberItem, o))))
      .toPromise();
  }

  getCompanyInventory(companyId): Observable<LumberItem[]> {
    return this.api.get(`${this.endpoint}/CompanyLumber/${companyId}`);
  }

  filterQuery(filter: LumberItemListFilter, pageSize?: number, pageNumber?: number): Promise<IPaginationResult<ItemListDto>> {
    const params = { pageSize, pageNumber };
    return this.api.post(`${this.endpoint}/Query`, filter, omitBy(params, isUndefined))
      .pipe(map(res => {
        return {
          size: res.size,
          data: _map(res.data, i => i as ItemListDto)
        };
      })).toPromise();
  }

  getTotal(companyId: number | null): Promise<number> {
    return this.api.get(`${this.endpoint}/Total`, omitBy({ companyId }, isNil))
      .toPromise();
  }

  // ItemExportDto
  getForExport(filter: LumberItemListFilter): Promise<any[]> {
    return this.api.post(`LumberItems/ForExport`, filter).toPromise();
  }

  generateQRCode(): Promise<string> {
    return this.api.post(`${this.endpoint}/GenerateQRCode`, {}).pipe(map(res => res.qrcode)).toPromise();
  }

  autocomplete(filter: string, companyId?: number): Promise<LumberItem[]> {
    return this.api.get(`${this.endpoint}/Autocomplete`, { filter, companyId })
      .pipe(map(res => res.map(r => plainToClass(LumberItem, r))))
      .toPromise();
  }

  getIncomplete(companyId): Promise<LumberItem[]> {
    return this.api.get(`${this.endpoint}/CompanyLumberUnfinished/${companyId}`).toPromise();
  }

  getWoodTypes(): Promise<WoodType[]> {
    return this.api.get('LumberItems/WoodTypes')
      .pipe(map(res => _map(res, o => plainToClass(WoodType, o))))
      .toPromise();
  }

  getLumberInputs(woodTypeId: number): Promise<any> {
    return this.api.get(`${this.endpoint}/LumberInputs/${woodTypeId}`).toPromise();
  }

  getLumberInputSection(woodTypeId: number, section): Promise<any> {
    return this.api.get(`${this.endpoint}/LumberInputs/${woodTypeId}/${section}`).toPromise();
  }

  fetchCompany(companyId: number): Promise<CompanyAccount> {
    return this.api.get('LumberItems/CompanyFetch', { companyId })
      .pipe(map(ca => plainToClass(CompanyAccount, ca)))
      .toPromise();
  }

  put(item: LumberItem): Promise<LumberItem> {
    return this.api.put(`${this.endpoint}/${item.id}`, classToPlain(item))
      .pipe(map(i => plainToClass(LumberItem, i)))
      .toPromise();
  }

  putList(items: LumberItem[]): Promise<LumberItem[]> {
    const userId = this.userService.user.value.id;
    return this.api.put(`${this.endpoint}/LumberItemList`, items).toPromise();
  }

  post(item: LumberItem): Promise<LumberItem> {
    return this.api.post(`${this.endpoint}`, classToPlain(item))
      .pipe(map(i => plainToClass(LumberItem, i)))
      .toPromise();
  }

  delete(item: LumberItem): Promise<boolean> {
    return this.api.delete(`${this.endpoint}/${item.id}`).toPromise();
  }

  addRlsLumberItemImage(itemId: number, rls: RlsLumberItemImage) {
    return this.api.post(`${this.endpoint}/Images/${itemId}`, rls).toPromise();
  }

  deleteRlsLumberItemImage(rls: RlsLumberItemImage) {
    return this.api.delete(`${this.endpoint}/Images/${rls.id}`);
  }

  createRlsLumberItemImageGroup(rImage: RlsLumberItemImage): UntypedFormGroup {
    const group = this.fb.group({
      id: [rImage.id, Validators.required],
      uuid: [rImage.uuid, Validators.required],
      isPreRemoval: [rImage.isPreRemoval],
      isPostRemoval: [rImage.isPostRemoval],
      imageId: [rImage.imageId],
      lumberItemId: [rImage.lumberItemId],
      image: this.fb.group({
        id: [rImage.image?.id, Validators.required],
        uuid: [rImage.image?.uuid, Validators.required],
        isActive: [rImage.image?.isActive, Validators.required],
        isAttached: [rImage.image?.isAttached, Validators.required],
        name: [rImage.image?.name, [Validators.required, Validators.maxLength(100)]],
        path: [rImage.image?.path, Validators.required],
        created: [rImage.image?.created || null]
      })
    });
    if (rImage.image?.geolocation != null) {
      const fg = group.get('image') as UntypedFormGroup;
      fg.addControl('geolocation', this.createGeolocation(rImage.image?.geolocation));
    }
    return group;
  }

  createGeolocation(geoLocation: Geolocation) {
    return this.fb.group({
      id: [geoLocation?.id, Validators.required],
      uuid: [geoLocation?.uuid, Validators.required],
      latitude: [geoLocation?.latitude, Validators.required],
      longitude: [geoLocation?.longitude, Validators.required]
    });
  }

  removeImage(formArray: UntypedFormArray, cachedArray: RlsLumberItemImage[], image: Attachment, uuid: string) {
    const index = findIndex(formArray.value, (i: RlsLumberItemImage) => i.uuid === uuid);
    if (index > -1) {
      return this.handleImageRemove(formArray, cachedArray, index);
    }
  }

  handleImageRemoving(itemImages: UntypedFormArray, image: Attachment) {
    const index = findIndex(itemImages.value, (i: RlsLumberItemImage) => i?.image?.uuid === image.uuid);
    if (index > -1) {
      itemImages.value[index].image.isDeleting = image.isDeleting;
    }
  }

  handleImageRemove(formArray: UntypedFormArray, cachedFormArray: RlsLumberItemImage[], index: number) {
    const rlsGroup: UntypedFormGroup = formArray.at(index) as UntypedFormGroup;
    const rls: RlsLumberItemImage = plainToClass(RlsLumberItemImage, rlsGroup.getRawValue(), { enableImplicitConversion: true });
    let promise = Promise.resolve(true);

    if (!rls.isNew) {
      promise = this.deleteRlsLumberItemImage(rls).toPromise();
    }

    // Must delete the relationship first due to foreign key contstraints
    return promise.then(res => {
      return this.attachmentService.delete(rls.image).then(r => {
        formArray.removeAt(index);
        cachedFormArray = cloneDeep(formArray.getRawValue());
        formArray.markAsDirty();
      });
    }).catch(err => {
      console.error(err);
      let original: Attachment = formArray.at(index).value.image as Attachment;
      original.isDeleting = false;
      this.handleImageRemoving(formArray, original);
    });
  }

  setImageLocation(value: any, group: UntypedFormGroup) {
    const geoGroup = group.get('image.geolocation') as UntypedFormGroup;
    geoGroup.patchValue({
      latitude: value.coords.latitude,
      longitude: value.coords.longitude
    });
  }

  validateQrcode(qrcode: string, itemUuid: string): Promise<string> {
    return this.api.get(`${this.endpoint}/ValidateQRCode`, { qrcode, itemUuid })
      .pipe(map(res => res?.qrcode as string))
      .toPromise();
  }

  createCopyFromOriginal(fromItem: LumberItem): LumberItem {
    // Copied from lumber-item-form.page's copy()
    const copyModel = cloneDeep(fromItem);
    copyModel.id = 0;
    copyModel.created = moment();
    copyModel.createdById = null;
    copyModel.createdBy = null;

    copyModel.uuid = uuid.v4();
    copyModel.qrcode = null;
    copyModel.name = copyModel.name == null ? null : `${copyModel.name} - Copy`;
    copyModel.isTagged = false;

    copyModel.children = [];

    if (copyModel.treeRemovalLocationAddress != null) {
      copyModel.treeRemovalLocationAddress.uuid = uuid.v4();
      copyModel.treeRemovalLocationAddress.id = 0;
      if (copyModel.treeRemovalLocationAddress.geolocation != null) {
        copyModel.treeRemovalLocationAddress.geolocation.uuid = uuid.v4();
        copyModel.treeRemovalLocationAddress.geolocation.id = 0;
      }
    }

    if (copyModel.reclaimLocation != null) {
      copyModel.reclaimLocation.id = 0;
      copyModel.reclaimLocation.uuid = uuid.v4();
    }

    if (copyModel.lumberItemLumberCharacteristics != null) {
      copyModel.lumberItemLumberCharacteristics = null;
    }

    if (copyModel.lumberItemWoodProcessingServices != null) {
      copyModel.lumberItemWoodProcessingServices = null;
    }

    if (copyModel.chainOfCustody != null) {
      copyModel.chainOfCustody = null;
    }

    copyModel.rlsLumberItemImages = [];

    return copyModel;
  }

  // handleImageUpload(attachment: Attachment, isPreRemoval: boolean = null, isPostRemoval: boolean = null) {
  //   const item = this.newItemForm.value;
  //   const existing = this.itemImages.value as RlsLumberItemImage[];

  //   if (attachment == null) {
  //     return;
  //   }
  //   const matchedError = find(existing, o => {
  //     return o?.image?.path === attachment?.path;
  //   });
  //   if (matchedError != null) {
  //     this.toaster.error('Attachment already exists');
  //     return this.attachmentService.delete(attachment);
  //   }

  //   const rImage = new RlsLumberItemImage();
  //   rImage.isPreRemoval = isPreRemoval;
  //   rImage.isPostRemoval = isPostRemoval;
  //   rImage.image = attachment;
  //   rImage.imageId = attachment.id;
  //   rImage.lumberItem = item;
  //   rImage.lumberItemId = item.id;

  //   this.itemImages.push(this.createImageGroup(rImage));
  //   this.itemImages.markAsDirty();
  //   this.newItemForm.setControl('rlsLumberItemImages', this.itemImages);
  //   this.newItemForm.markAsDirty();
  // }

  // handleImageRemoving(image: Attachment) {
  //   const index = findIndex(this.itemImages.value, (i: RlsLumberItemImage) => i?.image?.uuid == image.uuid);
  //   if (index > -1) {
  //     this.itemImages.value[index].image.isDeleting = image.isDeleting;
  //   }
  //   this.newItemForm.patchValue({ rlsLumberItemImages: this.itemImages.value });
  // }

  // handleImageRemove(formArray: FormArray, index: number, cachedFormArray?: RlsLumberItemImage[]) {
  //   const rlsGroup: FormGroup = this.itemImages.at(index) as FormGroup;
  //   const rls: RlsLumberItemImage = plainToClass(RlsLumberItemImage, rlsGroup.getRawValue(), { enableImplicitConversion: true });
  //   let promise = Promise.resolve(true);

  //   if (!rls.isNew) {
  //     promise = this.itemsService.deleteRlsLumberItemImage(rls).toPromise();
  //   }

  //   // Must delete the relationship first due to foreign key contstraints
  //   return promise.then(res => {
  //     return this.attachmentService.delete(rls.image).then(r => {
  //       this.itemImages.removeAt(index);
  //       this.newItemForm.patchValue({ rlsLumberItemImages: this.itemImages.value });
  //       //cachedFormArray = cloneDeep(itemImages.getRawValue());
  //     });
  //   }).catch(err => {
  //     console.error(err);
  //     let original: Attachment = this.itemImages.at(index).value.image as Attachment;
  //     original.isDeleting = false;
  //     this.handleImageRemoving(original);
  //   });
  // }

  // removeImage(image: Attachment, uuid: string) {
  //   const index = findIndex(this.itemImages.value, (i: RlsLumberItemImage) => i.uuid === uuid);
  //   if (index > -1) {
  //     return this.handleImageRemove(this.itemImages, index);
  //   }
  // }

}
