export class CurrencyCode {
    static USA = "USD";
    static CANADA = "CAD";
}

export const CurrencyCodeMap = new Map<string, string>([
    ['United States', CurrencyCode.USA],
    ['Canada', CurrencyCode.CANADA]
])

export class Currency {
    id: number;
    currencyName: string;
    currencyCountry: string;
    currencyCode: string;
}
