import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';

import { environment } from 'src/environments/environment';

import { Attachment } from '../../../models/attachment';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {

  @ViewChild('imagePreviewModal') imagePreviewModal: IonModal;

  @Input() image: Attachment;
  baseUrl: string;

  @Input() width: string;
  @Input() maxWidth: string = '100%';
  @Input() height: string;
  @Input() maxHeight: string = '100%';


  @Input() allowPreview: boolean = true;

  constructor() {
    this.baseUrl = environment.attachmentUrl;
  }

  ngOnInit() { }

  launchPreviewModal() {
    if (this.allowPreview !== true) { return; }
    return this.imagePreviewModal?.present();
  }

}
