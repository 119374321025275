import { Type } from 'class-transformer';

import { Address } from './address';
import { BaseEntity } from './baseEntity';
import { CompanyAccount } from './company-account';
import { IOtherable } from './interfaces/IOtherable';
import { ProcessingEquipment } from './processing-equipment';

export class StorageLocation extends BaseEntity implements IOtherable {

  constructor() {
    super();
    this.isSubLocation = false;
    this.isActive = true;
    this.isOther = false;
  }

  isSubLocation: boolean;

  parentLocation: StorageLocation;
  parentId: number;
  childrenLocations: StorageLocation[];

  locationName: string;
  reference: string; // Same as locationName mapped property, todo convert to dto

  isOther: boolean;
  isActive: boolean;

  companyAccountId: number;
  companyAccount: CompanyAccount;

  addressId: number;
  @Type(() => Address)
  address: Address;

  processingEquipment: ProcessingEquipment;
}
