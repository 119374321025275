import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule
} from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatLabel
} from '@angular/material/form-field';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipModule
} from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ReactiveValidationModule } from 'angular-reactive-validation';
import { NgArrayPipesModule, NgDatePipesModule } from 'ngx-pipes';
import { QuicklinkModule } from 'ngx-quicklink';

import { SwiperModule } from 'swiper/angular';

import { AttachmentModule } from '../components/attachments/attachments.module';
import { BrowserQrScannerComponent } from '../components/browser-qr-scanner/browser-qr-scanner.component';
import { CheckboxGroupComponent } from '../components/checkbox-group/checkbox-group.component';
import { GeolocationButtonComponent } from '../components/geolocation-button/geolocation-button.component';
import { InventoryItemCardComponent } from '../components/inventory-item-card/inventory-item-card.component';
import { ItemProblemsModalComponent } from '../components/item-problems-modal/item-problems-modal.component';
import { ItemQuickviewModalComponent } from '../components/item-quickview-modal/item-quickview-modal.component';
import { ItemStatusIconComponent } from '../components/item-status-icon/item-status-icon.component';
import { CompaniesComponent } from '../components/manager/companies/companies.component';
import { ManageUsersComponent } from '../components/manager/manage-users/manage-users.component';
import { MultipleSelectComponent } from '../components/multiple-select/multiple-select.component';
import { QrcodeScannerComponent } from '../components/qrcode-scanner/qrcode-scanner.component';
import { RegistrationModalComponent } from '../components/registration-modal/registration-modal.component';
import { ScannerGenerateDialogComponent } from '../components/scanner-generate-dialog/scanner-generate-dialog.component';
import { SelectCreateComponent } from '../components/select-create/select-create.component';
import { SendButtonComponent } from '../components/send-button/send-button.component';
import { StorageLocationSelectComponent } from '../components/storage-location-select/storage-location-select.component';
import { CoreModule } from '../core/core.module';
import { SharedLoadingModule } from './loading.module';
import { SharedAutocompleteModule } from './shared-autocomplete.module';
import {
  myCustomFormFieldDefaults,
  myCustomTooltipDefaults,
  SharedFormModule
} from './shared-form.module';
import { SharedTableModule } from './shared-table.module';

// TODO clean up the imports and exports here...
@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    IonicModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    MatStepperModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
    MatMomentDateModule,
    MatIconModule,
    NgArrayPipesModule,
    NgDatePipesModule,
    MatInputModule,
    FileUploadModule,
    AttachmentModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    ReactiveValidationModule,
    MatTableModule,
    MatChipsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatButtonToggleModule,
    SwiperModule,
    MatSortModule,
    MatExpansionModule,
    SharedLoadingModule,
    SharedTableModule,
    SharedFormModule,
    SharedAutocompleteModule,
    ZXingScannerModule,
    QuicklinkModule
  ],
  declarations: [
    BrowserQrScannerComponent,
    QrcodeScannerComponent,
    StorageLocationSelectComponent,

    GeolocationButtonComponent,
    SelectCreateComponent,
    InventoryItemCardComponent,
    MultipleSelectComponent,
    CheckboxGroupComponent,
    SendButtonComponent,
    ItemProblemsModalComponent,
    ItemQuickviewModalComponent,
    RegistrationModalComponent,

    CompaniesComponent,
    ManageUsersComponent,
    ScannerGenerateDialogComponent,
    ItemStatusIconComponent
  ],
  exports: [
    MatButtonModule,
    MatRadioModule,
    MatSelect,
    MatOption,
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatLabel,
    MatStepperModule,
    MatCheckboxModule,
    MatIcon,
    MatTooltipModule,
    MatButtonToggleModule,

    BrowserQrScannerComponent,
    QrcodeScannerComponent,
    StorageLocationSelectComponent,
    NgArrayPipesModule,
    NgDatePipesModule,
    FileUploadModule,
    AttachmentModule,
    SharedLoadingModule,
    ItemStatusIconComponent,

    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    GeolocationButtonComponent,
    ReactiveValidationModule,
    MatTableModule,
    MatChipsModule,
    MatPaginatorModule,
    MatCardModule,
    SelectCreateComponent,
    MatProgressSpinnerModule,
    InventoryItemCardComponent,
    MultipleSelectComponent,
    CheckboxGroupComponent,
    SendButtonComponent,
    ItemProblemsModalComponent,
    RegistrationModalComponent,
    SwiperModule,
    MatSortModule,
    MatExpansionModule,

    CompaniesComponent,
    ManageUsersComponent,
    ScannerGenerateDialogComponent,

    QuicklinkModule
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: myCustomFormFieldDefaults },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {} },
  ],
})
export class SharedModule { }
