<app-list-table
  #parentList
  [isLoading]="this.isLoading"
  [columns]="this.displayColumns"
  [dataSource]="this.dataSource"
  [allowSelect]="true"
  [selectAllLocal]="this.selfSelect"
  (selectedRows)="this.selected($event)"
  (allItemsSelected)="this.emitAllSelected()"
  (allItemsRemoved)="this.allItemsRemoved.emit()"
  matSort
  matSortDisableClear
  mat-table
>
  <ng-container matColumnDef="company">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.companyName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="qrcode">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>QR Code</th>
    <td mat-cell *matCellDef="let element">
      <a [routerLink]="['/', 'lumber-item', 'form', element?.uuid]">{{ element?.qrcode || 'NEEDS CODE' }}</a>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="treeSpecies">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tree Species</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.treeSpecies | notationName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="woodType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Wood Type</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.woodType?.reference }}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let element">
      <div class="icon-container">
        <app-item-status-icon [status]="element.status"></app-item-status-icon>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="isComplete">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Complete</th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element?.isComplete === true">Complete</span>
      <span *ngIf="element?.isComplete === false">Incomplete</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="manage">
    <th mat-header-cell *matHeaderCellDef>View</th>
    <td mat-cell *matCellDef="let element">
      <div class="view-container">
        <ion-button color="dark" fill="clear" (click)="this.quickView(element, $event)">
          <ion-icon name="eye" slot="icon-only"></ion-icon>
        </ion-button>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="createdBy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
    <td mat-cell *matCellDef="let element">
      {{ element.createdBy }}
    </td>
  </ng-container>

  <ng-container matColumnDef="created">
    <th class="header-right" mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
    <td mat-cell *matCellDef="let element">
      {{ element.created | date }}
    </td>
  </ng-container>

  <ng-container matColumnDef="children">
    <td mat-cell *matCellDef="let element" [attr.colspan]="'100%'">
      <div class="flex child-row" *ngIf="this.includeChildren && element?.children != null && element?.children.length > 0">
        <app-item-list
          #childList
          class="w-100 children-list"
          [selfSelect]="this.selfSelect"
          [isChildList]="true"
          [displayColumns]="parentList.columns"
          (selectedItems)="this.selectedChildren($event, element.id)"
          (viewItem)="this.quickView($event, null)"
          [data]="element.children"
          [includeChildren]="false"
        >
        </app-item-list>
      </div>
    </td>
  </ng-container>

  <tr mat-row *matRowDef="let row; columns: ['children']" class="list-table-detail-row" [class.hidden]="!row?.children?.length || !this.includeChildren"></tr>
</app-list-table>
