import { Type } from 'class-transformer';

import { AddressType } from './address-type';
import { BaseEntity } from './baseEntity';
import { CompanyAccount } from './company-account';
import { CountryType, CountryTypeMap } from './enums/country-type';
import { Geolocation } from './geolocation';
import { ProcessingEquipment } from './processing-equipment';
import { RlsCompanyAddress } from './relations/rls-company-address';
import { RlsUserAddress } from './relations/rls-user-address';
import { StorageLocation } from './storage-location';
import { User } from './user';

export class Address extends BaseEntity {

    constructor(company?: CompanyAccount) {
        super();
        this.isActive = true;
        this.country = company?.defaultAddress?.country || CountryTypeMap.get(CountryType.USA);
    }

    reference?: string;

    addressTypeId?: number;
    @Type(() => AddressType)
    addressType?: AddressType;

    streetAddress: string;
    streetAddress2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    isActive: boolean;

    // Relationships
    geolocation?: Geolocation;
    defaultForUserAccount: User;
    defaultForCompanyAccount: CompanyAccount;

    rlsCompanyAddress: RlsCompanyAddress[];
    rlsUserAddress: RlsUserAddress[];

    storageLocations: StorageLocation[];
    processingEquipments: ProcessingEquipment[];

    get fullAddress(): string {
        let address = `${this.streetAddress}`;
        if (this.streetAddress2 != null && this.streetAddress2?.trim().length > 0) {
            address += ` ${this.streetAddress2?.trim()}`;
        }
        address += ` ${this.city}, ${this.state}, ${this.postalCode} ${this.country}`;
        return address;
    }
}
