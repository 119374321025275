import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenResponse } from '@openid/appauth';
import { isUndefined, omitBy } from 'lodash-es';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import {
  AuthActions,
  AuthService as OIDCAuthService,
  IAuthAction
} from 'ionic-appauth';
import { User as UserAccount } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';

import { environment } from '../../environments/environment';
import { LoadingDialogComponent } from '../components/loading-dialog/loading-dialog.component';
import { safeJSONParse, safeJSONStringify, Utilities } from '../shared/util';
import { AlertService } from './alert.service';
import { CompanyService } from './company.service';
import { ToasterService } from './toaster.service';

const TOKEN_RESPONSE_KEY = "token_response";

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private user: any = null;
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject(null);
  loggedIn$: Observable<boolean> = this.loggedIn;
  public isRefreshing: BehaviorSubject<boolean> = new BehaviorSubject(null);
  isRefreshing$: Observable<boolean> = this.isRefreshing;

  modalId: string = 'auth-loader';

  sub: Subscription;
  authActionSub: Subscription;

  constructor(
    private toast: ToasterService,
    private router: Router,
    private userService: UserService,
    private oidc: OIDCAuthService,
    private companyService: CompanyService,
    private alert: AlertService
  ) { }

  getUser(): Promise<any> {
    return this.oidc.initComplete$.pipe(
      filter(complete => complete === true),
      switchMap(() => this.oidc.user$),
      tap(user => user),
      take(1)
    ).toPromise();
  }

  setUser(user) {
    this.user = user;
  }

  isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  isAuthenticated(): Promise<boolean> {
    return this.oidc.initComplete$.pipe(
      filter(complete => complete === true),
      switchMap(() => this.oidc.isAuthenticated$),
      tap(isAuthenticated => isAuthenticated),
      take(1)
    ).toPromise();
  }

  /**
   *
   * @returns
   */
  isUserLoaded(): Promise<boolean> {
    return this.getUser().then(res => res != null).catch(err => false);
  }

  getClaims(): any {
    return this.user;
  }

  /**
   * getValidToken implicity refreshes expired/invalid tokens
   * @returns
   */
  async getAuthorizationHeaderValue(): Promise<string> {
    return this.oidc.getValidToken().then((res: TokenResponse) => {
      return Promise.resolve(`${res.tokenType} ${res.accessToken}`);
    }).finally(() => this.isRefreshing.next(false));
  }

  async completeAuthentication(user): Promise<UserAccount> {
    const ref = await this.alert.createModal({
      cssClass: ['ulm-modal', 'small'],
      component: LoadingDialogComponent,
      componentProps: { title: 'Logging you in' },
      backdropDismiss: false,
      id: this.modalId
    });
    await ref.present();

    this.user = user;
    return this.userService.getLoggedInUser().then(client => {
      this.loggedIn.next(true);
      return client;
    }).catch(async err => {
      this.loggedIn.next(false);
      await this.userService.setUser(null);
      await this.companyService.setCompany(null);
      console.error(err);
      return Promise.reject('Failed to login, try again later');
    }).finally(async () => await this.alert.dismissModalById(this.modalId));

  }

  silentRefresh() {
    return this.oidc.refreshToken();
  }

  signIn(authExtras?: any) {
    authExtras = omitBy(authExtras, isUndefined)
    return this.oidc.signIn(authExtras);
  }

  async login() {
    const ref = await this.alert.createModal({
      cssClass: ['ulm-modal', 'small'],
      component: LoadingDialogComponent,
      componentProps: { title: 'Logging you in' },
      backdropDismiss: false,
    });
    await ref.present();

    return this.signIn().finally(async () => {
      await ref.dismiss();
    });
  }

  // Issue with this method is the Cookie, is still valid, so on web
  // non-native devices, this will still act as though auth
  async signoutWithoutRedirect() {
    await Utilities.storageRemoveItem('user');
    await Utilities.storageRemoveItem('company');
    // this.oidc.revokeTokens();
    this.setUser(null);
    await this.userService.setUser(null);
    await this.companyService.setCompany(null);
    this.loggedIn.next(false);
    await Utilities.storageRemoveItem(TOKEN_RESPONSE_KEY)
    this.oidc.endSessionCallback();

    await this.router.navigate([environment.defaultRoute]);
    return Promise.resolve(true);
  }

  async signOut() {
    await Utilities.storageRemoveItem('user');
    await Utilities.storageRemoveItem('company');
    this.setUser(null);
    await this.userService.setUser(null);
    await this.companyService.setCompany(null);
    this.loggedIn.next(false);
    this.router.navigate([environment.defaultRoute]);
    return this.oidc.signOut();
  }

  sendVerificationEmail(email: string) {
    return this.userService.resendEmailVerification(email);
  }
}
