import { BaseEntity } from './baseEntity';
export class Role extends BaseEntity {
    constructor() {
        super();
        this.isActive = true;
    }

    isActive: boolean;
    reference: string;
    description: string;
}
