import { BaseEntity } from './../baseEntity';
import { LumberItem } from '../lumber-items/lumber-item';
import { Attachment } from '../attachment';
import { Type } from 'class-transformer';


export class RlsLumberItemImage extends BaseEntity {
    constructor() {
        super();
    }

    isPreRemoval: boolean;
    isPostRemoval: boolean;

    imageId: number;

    @Type(() => Attachment)
    image: Attachment;

    lumberItemId: number;
    lumberItem: LumberItem;
}
