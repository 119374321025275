import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { assign, find as _find, findIndex, difference } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import 'cordova-plugin-purchase/www/store.d';
import { environment } from 'src/environments/environment';

import {
  RlsCompanyUserSubscriptionDto,
  SubscriptionPackageOptionDto,
  SubscriptionPackageType,
  SubscriptionProductOptionDto
} from '../models/dto/subscription-package-dto';

@Injectable({
  providedIn: 'root'
})
export class IapSubscriptionPackageService {
  iapPlatformMap: Map<string, CdvPurchase.Platform>;


  products = new BehaviorSubject<CdvPurchase.Product[]>([]);
  products$: Observable<CdvPurchase.Product[]> = this.products;

  currentSub = new BehaviorSubject<RlsCompanyUserSubscriptionDto>(null);
  currentSub$: Observable<RlsCompanyUserSubscriptionDto> = this.currentSub;

  currentSubscriptionIAP = new BehaviorSubject<CdvPurchase.Product>(null);
  currentSubscriptionIAP$ = this.currentSubscriptionIAP;

  transactionId = new BehaviorSubject<string>(null);
  transactionId$ = this.transactionId;

  ineligbleIntroProductIds = new BehaviorSubject<string[]>([]);
  ineligbleIntroProductIds$ = this.ineligbleIntroProductIds;

  store: CdvPurchase.Store;

  constructor() {
    if (Capacitor.isNativePlatform()) {
      this.iapPlatformMap = new Map([
        ['ios', CdvPurchase.Platform.APPLE_APPSTORE],
        ['google', CdvPurchase.Platform.GOOGLE_PLAY]
      ]);
    }
  }

  get iapPlatform(): CdvPurchase.Platform {
    return this.iapPlatformMap.get(Capacitor.getPlatform());
  }

  get isSupported() { return Capacitor.getPlatform() === 'ios'; }

  setProducts(arr: CdvPurchase.Product[]): void {
    this.products.next(arr);
  }

  mapOptionsByPlatform(opt: SubscriptionPackageOptionDto, platform: string, iap: CdvPurchase.Product = null, inelgibleProductIds: string[]): SubscriptionProductOptionDto {
    const result = new SubscriptionProductOptionDto();
    result.product = iap;
    assign(result, opt);
    result.featureList = opt.featureList;
    if (opt.type !== SubscriptionPackageType.ESSENTIALS) {
      result.title = iap.title;
      result.subTitle = iap.description;
      result.price = `${iap.pricing?.price} per month`;
      result.disabled = !iap.canPurchase || opt.disabled;
      const raw = (iap as any)?.raw;
      const receipt = this.store.verifiedReceipts.find(o => o.id === iap.id);
      const isInelgibleForIntro = inelgibleProductIds.findIndex(v => v === opt.externalId) > -1;
      // Not seeing iap.ineligibleForIntroPrice receipt.ineligibleForIntroPrice;
      // TODO I need to check on app store side that introPriceIsAvailable in order to show this
      if (isInelgibleForIntro === false) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (opt.withTrial === true && raw.introPricePaymentMode != null) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
          const introFeature = `${raw.introPrice} for ${raw.introPricePeriod} ${raw.introPricePeriodUnit}`;
          result.featureList = [...result.featureList, introFeature];
        }
      }
    }

    return result;
  }

  async updateProducts(product: CdvPurchase.Product): Promise<void> {
    let arr = await this.products.pipe(take(1)).toPromise();
    const i = findIndex(arr, o => o.id === product.id);
    if (i > -1) {
      arr = arr.splice(i, 1);
    }
    arr.push(product);
    this.products.next(arr);
  }

  async orderProduct(product: CdvPurchase.Product): Promise<CdvPurchase.IError> {
    try {
      const res = await this.store.get(product.id).getOffer().order();
      console.log('Order IAProduct', res);
      return res;
    } catch (err) {
      console.error(err);
    }
  }

  async manageSubscription(): Promise<CdvPurchase.IError> {
    try {
      const res = await this.store.manageSubscriptions(this.iapPlatform);
      console.log('Manage IAProduct', res);
      return res;
    } catch (err) {
      console.error(err);
    }
  }

  async restorePurchase(): Promise<void> {
    try {
      const res = await this.store.restorePurchases();
      return res;
    } catch (err) {
      console.error(err);
    }
  }

  async setProductsWithoutTrials(productIds: string[]): Promise<void> {
    const current = await this.ineligbleIntroProductIds$.pipe(take(1)).toPromise();
    if (productIds?.length! + current?.length || difference(productIds, current)?.length > 0) {
      this.ineligbleIntroProductIds.next(productIds);
    }
  }

  mapOptionsToProducts(options: SubscriptionPackageOptionDto[], iapOptions: CdvPurchase.Product[], inelgibleProductIds: string[]): SubscriptionProductOptionDto[] {
    const platform = Capacitor.getPlatform();
    return options.map(opt => {
      const iap = _find(iapOptions, io => io.id === opt.externalId);
      return this.mapOptionsByPlatform(opt, platform, iap, inelgibleProductIds)
    });
  }

  getPackageOptions(options$: Observable<SubscriptionPackageOptionDto[]>): Observable<SubscriptionProductOptionDto[]> {
    return combineLatest([options$, this.products$, this.ineligbleIntroProductIds$])
      .pipe(
        filter(([v1, v2]) => v1?.length > 0 && v2?.length > 0 && v1?.filter(o => o.externalId != null)?.length === v2?.length),
        map(([v1, v2, v3]) => this.mapOptionsToProducts(v1, v2, v3)));
  }

}
