import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { CompanyAccount } from '../models/company-account';
import { AlertService } from '../services/alert.service';
import { CompanyService } from '../services/company.service';
import { UserService } from '../services/user.service';
import { BaseResolver } from './base.resolver';

@Injectable({ providedIn: 'root' })
export class CompanyAccountResolver extends BaseResolver<CompanyAccount> {

  modalId = 'company-loader';

  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    protected alert: AlertService
  ) { super(alert); }

  async onResolve(route: ActivatedRouteSnapshot): Promise<CompanyAccount> {
    const company = await this.companyService.getCompany$();
    if (company != null) {
      return Promise.resolve(company);
    }
    try {
      const user = await this.userService.getUser$();
      if (user?.companyAccountId == null) {
        await this.companyService.setCompany(null);
        return Promise.resolve(null);
      }
      const fetchCompany = await this.companyService.getForForm(user?.companyAccountId);
      await this.companyService.setCompany(fetchCompany);
      return Promise.resolve(fetchCompany);
    } catch (err) {
      console.error(err);
      this.companyService.setCompany(null);
      return Promise.resolve(null);
    }
  }
}