import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  spin = false;

  constructor() { }

  start() {
    this.spin = true;
  }

  stop() {
    this.spin = false;
  }
}
