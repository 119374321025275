import * as uuid from 'uuid';

import { Exclude, Type } from 'class-transformer';

import { Address } from './address';
import { Attachment } from './attachment';
import { Claim } from './claim';
import { CompanyAccount } from './company-account';
import { DryingCompany } from './drying-company';
import { Email } from './email';
import { IAttachable } from './interfaces/IAttachable';
import { Phone } from './phone';
import { RlsUserAddress } from './relations/rls-user-address';
import { RlsUserCompany } from './relations/rls-user-company';
import { RlsUserEmail } from './relations/rls-user-email';
import { RlsUserPhone } from './relations/rls-user-phone';
import { Role } from './role';
import { UserType } from './user-type';

export class User implements IAttachable {

  constructor() {
    this.isActive = true;
    this.uuid = uuid.v4();
    this.id = null;
  }

  @Exclude({ toPlainOnly: true })
  get attachableName(): string {
    return 'USER';
  }

  @Exclude({ toPlainOnly: true })
  get isNew(): boolean {
    return this.id == null || this.id?.length === 0;
  }

  get isSubscribed(): boolean {
    return true;
  }

  id: string;
  uuid: string;
  concurrencyStamp: string;
  isRegistered: boolean;
  userName: string;
  normalizedUserName: string;

  firstName: string;
  lastName: string;

  email: string;
  normalizedEmail: string;
  emailConfirmed: boolean;

  phoneNumber: string;

  companyAccountId: number;
  companyAccount: CompanyAccount;

  roles: string[];
  claims: Claim[];
  @Type(() => Role)
  apiRoles: Role[];

  userAddresses: Address[];
  rlsUserAddress: RlsUserAddress[];

  userEmails: Email[];
  rlsUserEmail: RlsUserEmail[];
  @Type(() => Email)
  defaultEmail: Email;
  defaultEmailId: number;

  userPhones: Phone[];
  rlsUserPhone: RlsUserPhone[];
  @Type(() => Phone)
  defaultPhone: Phone;
  defaultPhoneId: number;

  isActive: boolean;
  created: Date;

  @Type(() => Attachment)
  profilePicture: Attachment;
  profilePictureId: number;

  @Type(() => UserType)
  userTypes: UserType[];

  dryingCompany: DryingCompany;

  rlsUserCompany: RlsUserCompany;

}
