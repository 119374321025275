export class RegisterUserDto {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  company: string | null;
  companyId: string | null; // This is via an invite link
  policyConfirmed: boolean;
  permission: boolean;
}
