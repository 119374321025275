import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { concat, intersectionBy, uniqBy } from 'lodash-es';

import { ItemListDto } from 'src/app/models/dto/ItemListDto';
import { LumberItemStatusMap } from 'src/app/models/enums/lumber-item-status';

import { BaseListComponent } from '../../core/base-list.component';
import { ListTableComponent } from '../list-table/list-table.component';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
})
export class ItemListComponent extends BaseListComponent<ItemListDto> {


  @ViewChild('parentList') parentList: ListTableComponent<ItemListDto>;
  @ViewChildren('childList') childList: QueryList<ItemListComponent>;

  @Output() viewItem: EventEmitter<ItemListDto> = new EventEmitter();
  @Output() selectedItems: EventEmitter<ItemListDto[]> = new EventEmitter();
  @Output() allItemsSelected: EventEmitter<ItemListDto[]> = new EventEmitter();
  @Output() allItemsRemoved: EventEmitter<any> = new EventEmitter();

  @Input() isChildList: boolean = false;
  @Input() includeChildren: boolean = false;
  @Input() displayColumns: string[] = ['select', 'qrcode', 'woodType', 'status', 'treeSpecies', 'created', 'isActive', 'manage'];
  @Input() itemGroup: ItemListDto[] = [];
  @Input() selfSelect: boolean = true;

  statusMap = LumberItemStatusMap;

  selection: ItemListDto[] = [];
  childSelection: ItemListDto[] = [];

  constructor(private cd: ChangeDetectorRef) { super(); }

  quickView(item: ItemListDto, event: Event) {
    this.viewItem.emit(item);
    event?.stopPropagation();
  }

  selected(arr: ItemListDto[]) {
    //Items with a changed selected status
    const difference = this.selection.concat(arr)
      .filter(x => !(this.selection.includes(x) && arr.includes(x)));
    this.selection = arr;

    //Apply selected status to relevant child items
    difference.filter(item => item.children?.length > 0).forEach(parent => {
      const childTable = this.childList.find(child => child.data === parent.children);
      if (childTable && this.selection.includes(parent)) {
        this.childSelection = this.childSelection.filter(item => item.parentId !== parent.id).concat(parent.children);
        childTable.selectAll();
      } else if (childTable) {
        this.childSelection = this.childSelection.filter(item => item.parentId !== parent.id);
        childTable.clearSelections();
      }
      childTable.setSelection(this.childSelection);
    });

    this.cd.detectChanges();

    const result = concat(this.selection, this.childSelection);
    this.selectedItems.emit(result);
  }

  setSelection(arr: ItemListDto[]) {
    const data = this.data || this.dataSource?.data;
    const intersection = intersectionBy(data, arr, 'uuid');
    this.selection = intersection;
    this.childList.forEach(c => c.setSelection(arr));
    this.parentList.setSelection(this.selection);
  }

  selectedChildren(selection: ItemListDto[], parentId: number) {
    this.childSelection = this.childSelection.filter(child => child.parentId !== parentId).concat(selection);
    this.selectedItems.emit(uniqBy(this.selection.concat(this.childSelection), 'uuid'));
  }

  clearSelections() {
    this.selection = [];
    this.childSelection = [];
    this.parentList?.clearSelection();
    this.childList?.forEach(i => i?.clearSelections());
  }

  selectAll() {
    this.parentList?.selectAll();
    this.childList?.forEach(i => i?.selectAll());
  }

  emitAllSelected() {
    this.allItemsSelected.emit(uniqBy(this.selection.concat(this.childSelection), 'uuid'));
  }

}
