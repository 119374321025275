import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

import { environment } from 'src/environments/environment';

import { AuthGuardService } from './auth/auth-guard.service';
import { RegistrationModalComponent } from './components/registration-modal/registration-modal.component';
import { CompanyUserGuard } from './guards/company-user.guard';
import { IsNativeMobileGuard } from './guards/is-native-mobile.guard';
import { ManagerOrAdminGuard } from './guards/manager-or-admin.guard';
import { ManagerGuard } from './guards/manager.guard';
import { MobileRedirectGuard } from './guards/mobile-redirect.guard';
import { ModalRedirectGuard } from './guards/modal-redirect.guard';
import { RestrictPlatformGuard } from './guards/restrict-platform.guard';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { DeleteAccountComponent } from './pages/delete-account/delete-account.component';
import { GettingStartedPage } from './pages/getting-started/getting-started.page';
import { WelcomePage } from './pages/welcome/welcome.page';
import { CompanyAccountResolver } from './resolvers/company-account.resolver';
import { UserResolver } from './resolvers/user.resolver';

const routes: Routes = [
  { path: 'callback', loadChildren: () => import('./auth/auth-callback/auth-callback.module').then(m => m.AuthCallbackPageModule) },
  { path: 'logout', loadChildren: () => import('./auth/end-session/end-session.module').then(m => m.EndSessionPageModule) },
  {
    path: 'login',
    canActivate: [ModalRedirectGuard],
    component: GettingStartedPage,
    data: { redirect: environment.defaultRoute, outlet: 'modal', value: 'signup' }
  },
  {
    path: 'register',
    canActivate: [ModalRedirectGuard],
    component: GettingStartedPage,
    data: { redirect: environment.defaultRoute, outlet: 'modal', value: 'signup' }
  },
  {
    path: 'signup',
    outlet: 'modal',
    canDeactivate: [ModalRedirectGuard],
    component: RegistrationModalComponent
  },
  { path: 'welcome', component: WelcomePage },
  {
    path: '',
    canActivate: [AuthGuardService, MobileRedirectGuard],
    resolve: { user: UserResolver, companyAccount: CompanyAccountResolver },
    component: DashboardPage,
    data: { showLoading: true }
  },
  {
    path: 'mobile',
    loadChildren: () => import('./pages/mobile/mobile.module').then(m => m.MobilePageModule),
    canActivate: [AuthGuardService, IsNativeMobileGuard],
    resolve: { user: UserResolver, companyAccount: CompanyAccountResolver },
    data: { showLoading: true }
  },
  {
    path: 'registration-success',
    loadChildren: () => import('./pages/register-pages/registration-success/registration-success.module').then(m => m.RegistrationSuccessPageModule)
  },
  {
    path: 'register-company',
    canActivate: [AuthGuardService, RestrictPlatformGuard],
    loadChildren: () => import('./pages/register-pages/register-company/register-company.module').then(m => m.RegisterCompanyPageModule),
    data: { restrict: ['android'] }
  },
  {
    path: 'company-register-success',
    loadChildren: () => import('./pages/register-pages/company-register-success/company-register-success.module').then(m => m.CompanyRegisterSuccessPageModule)
  },
  {
    path: 'inventory',
    canActivate: [AuthGuardService, CompanyUserGuard, MobileRedirectGuard],
    loadChildren: () => import('./pages/company-pages/company-inventory/company-inventory.module').then(m => m.CompanyInventoryPageModule)
  },
  {
    path: 'lumber-item', redirectTo: 'inventory', pathMatch: 'prefix'
  },
  {
    path: 'storage-location',
    loadChildren: () => import('./pages/storage-location/storage-location.module').then(m => m.StorageLocationPageModule)
  },
  {
    path: 'company',
    canActivate: [AuthGuardService, ManagerOrAdminGuard],
    loadChildren: () => import('./pages/company/company.page.module').then(m => m.CompanyPageModule)
  },
  {
    path: 'cut-type',
    canActivate: [AuthGuardService, ManagerOrAdminGuard],
    loadChildren: () => import('./pages/cut-type/cut-type.module').then(m => m.CutTypePageModule)
  },
  {
    path: 'tree-species',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/tree-species/tree-species.module').then(m => m.TreeSpeciesPageModule)
  },
  {
    path: 'lumber-characteristic',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/lumber-characteristic/lumber-characteristic.module').then(m => m.LumberCharacteristicPageModule)
  },
  {
    path: 'removed-by',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/removed-by/removed-by.module').then(m => m.RemovedByPageModule)
  },
  {
    path: 'processing-equipment',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/processing-equipment/processing-equipment.module').then(m => m.ProcessingEquipmentPageModule)
  },
  {
    path: 'reason-for-removal',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/reason-for-removal/reason-for-removal.module').then(m => m.ReasonForRemovalPageModule)
  },
  {
    path: 'item-grade',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/item-grade/item-grade.module').then(m => m.ItemGradePageModule)
  },
  {
    path: 'equipment-group',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/equipment-group/equipment-group.module').then(m => m.EquipmentGroupPageModule)
  },
  {
    path: 'cut-type-group',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/cut-type-group/cut-type-group.module').then(m => m.CutTypeGroupPageModule)
  },
  {
    path: 'wood-processing-information',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/wood-processing-information/wood-processing-information.module').then(m => m.WoodProcessingInformationPageModule)
  },
  {
    path: 'wood-processing-service',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/wood-processing-service/wood-processing-service.module').then(m => m.WoodProcessingServicePageModule)
  },
  {
    path: 'drying-type',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/drying-type/drying-type.module').then(m => m.DryingTypePageModule)
  },
  {
    path: 'drying-information',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/drying-information/drying-information.module').then(m => m.DryingInformationPageModule)
  },
  {
    path: 'removal-authority',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/removal-authority/removal-authority.module').then(m => m.RemovalAuthorityPageModule)
  },
  {
    path: 'removal-location',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/removal-location/removal-location.module').then(m => m.RemovalLocationPageModule)
  },
  {
    path: 'chain-of-custody',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/chain-of-custody/chain-of-custody.module').then(m => m.ChainOfCustodyPageModule)
  },
  {
    path: 'coc-historical-data',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/coc-historical-data/coc-historical-data.module').then(m => m.CocHistoricalDataPageModule)
  },
  {
    path: 'user-type',
    canActivate: [AuthGuardService, CompanyUserGuard],
    loadChildren: () => import('./pages/user-type/user-type.module').then(m => m.UserTypePageModule)
  },
  {
    path: 'drying-company',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/drying-company/drying-company.module').then(m => m.DryingCompanyPageModule)
  },
  {
    path: 'admin-dashboard',
    canActivate: [AuthGuardService, ManagerGuard],
    loadChildren: () => import('./pages/admin-pages/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardPageModule)
  },
  {
    path: 'request-tickets',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/company-pages/manage-requests/manage-requests-routing.module').then(m => m.ManageRequestsPageRoutingModule)
  },
  {
    path: 'edit-registered-user/:uuid',
    canActivate: [AuthGuardService, ManagerGuard],
    loadChildren: () => import('./pages/manager-pages/edit-registered-user/edit-registered-user.module')
      .then(m => m.EditRegisteredUserPageModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuardService, MobileRedirectGuard],
    loadChildren: () => import('./pages/settings/settings.module')
      .then(m => m.SettingsPageModule)
  },
  {
    path: 'manager-dashboard',
    canActivate: [AuthGuardService, ManagerGuard],
    loadChildren: () => import('./pages/manager-pages/manager-dashboard/manager-dashboard.module')
      .then(m => m.ManagerDashboardPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/settings/notifications/notifications.module').then(m => m.NotificationsPageModule)
  },
  {
    path: 'system-setting',
    canActivate: [AuthGuardService, ManagerGuard],
    loadChildren: () => import('./pages/admin-pages/system-setting/system-setting.module').then(m => m.SystemSettingPageModule)
  },
  {
    path: 'sales-orders',
    canActivate: [AuthGuardService, CompanyUserGuard],
    loadChildren: () => import('./pages/sales-order/sales-order.module').then(m => m.SalesOrderPageModule)
  },
  {
    path: 'email-confirmed',
    loadChildren: () => import('./pages/register-pages/email-confirmed/email-confirmed.module').then(m => m.EmailConfirmedPageModule)
  },
  {
    path: 'confirm-email',
    loadChildren: () => import('./pages/confirm-email/confirm-email.module').then(m => m.ConfirmEmailPageModule)
  },
  {
    path: 'getting-started',
    component: GettingStartedPage,
    canActivate: [RestrictPlatformGuard],
    data: { restrict: ['android'] }
  },
  {
    path: 'registration-packages',
    loadChildren: () => import('./pages/registration-packages/registration-packages.module').then(m => m.RegistrationPackagesPageModule),
    canActivate: [RestrictPlatformGuard],
    data: { restrict: ['android'] }
  },
  {
    path: 'shipping-option',
    loadChildren: () => import('./pages/shipping-option/shipping-option.module').then(m => m.ShippingOptionPageModule),
    canActivate: [AuthGuardService, ManagerGuard]
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('./pages/sub-package/sub-package.module').then(m => m.SubPackagePageModule)
  },
  {
    path: 'delete-account',
    component: DeleteAccountComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'public-lumber-items',
    canActivate: [AuthGuardService, MobileRedirectGuard],
    loadChildren: () => import('./pages/inventory-pages/public-lumber-items/public-lumber-items.module')
      .then(m => m.PublicLumberItemsPageModule)
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: environment.useHash, onSameUrlNavigation: 'reload', preloadingStrategy: QuicklinkStrategy })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
