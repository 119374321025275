import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { join } from 'lodash-es';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileRedirectGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isNative = Capacitor.isNativePlatform();
    const urlFragments = state.url.split('/').slice(1);
    if (isNative && urlFragments[0] === '') {
      console.log('redirectToMobile');
      return this.router.navigateByUrl(this.router.parseUrl('/mobile'), { state: { bypassDeactivateGuard: true } });
    } else if (isNative && urlFragments[0] === 'inventory') {
      const path = join(['/mobile', ...urlFragments], '/');
      return this.router.navigateByUrl(this.router.parseUrl(path), { state: { bypassDeactivateGuard: true } });
    }
    console.log('no mobile redirect');
    return true;
  }

}
