import { BaseEntity } from './baseEntity';
import { Type } from 'class-transformer';
import { CutTypeGroup } from './cut-type-group';

export class CutTypeGroupSubGroup extends BaseEntity {

  constructor() {
    super();
  }

  // @Type(() => CutTypeGroup)
  cutTypeGroup: CutTypeGroup;

  // @Type(() => CutTypeGroup)
  cutTypeSubGroup: CutTypeGroup;

}
