<ion-header *ngIf="company">
  <ion-toolbar>
    <ion-title>
      <div class="flex ion-align-items-center">
        <app-image *ngIf="company?.logo != null" class="company-logo mr-4" [allowPreview]="false" [image]="company.logo"> </app-image>
        <ion-label class="ion-text-wrap ion-align-self-center"> {{company.name}} </ion-label>
      </div>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button
        type="button"
        fill="solid"
        *ngIf="company != null && company.approved == null"
        (click)="this.checkCompanyApprovalStatus();"
        [disabled]="this.approvalStatusLoading === true"
        color="primary"
      >
        Approval Status
        <span class="ml-3">
          <ion-icon *ngIf="this.approvalStatusLoading === false" name="refresh"></ion-icon>
          <ion-spinner *ngIf="this.approvalStatusLoading === true"></ion-spinner>
        </span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-progress-bar *ngIf="this.dashboardItemsLoading == true" type="indeterminate"></ion-progress-bar>
  <ion-refresher *ngIf="company && company.approved" slot="fixed" (ionRefresh)="refresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <ion-grid class="ion-no-padding" *ngIf="this.user != null">
    <ion-row class="ion-padding" *ngIf="company == null">
      <ion-col>
        <div class="container">
          <ion-img src="assets/images/app-logo-light.png" class="logo"></ion-img>
        </div>
        <ion-button class="mt-2 mt-md-6 reg-company" expand="block" *ngIf="!company && !isManagerOrHigher && !isMobile" routerLink="/register-company" color="primary"> Register Company </ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-padding" *ngIf="company && !company.approved">
      <ion-col size="12" size-md="8">
        <ion-text *ngIf="company.approved == null">
          <h2>{{company.name}} is awaiting admin review has not been approved yet.</h2>
        </ion-text>
        <ion-text *ngIf="company.approved === false">
          <h2>{{company.name}} has been denied access.</h2>
          <p>Contact <a href="mailto:{{this.supportEmail}}">{{this.supportEmail}}</a> for details.</p>
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="company && company.approved">
      <ion-col size="12">
        <ion-toolbar>
          <ion-title>
            <span *ngIf="this.isCompanyAdmin"> Company's Incomplete Items </span>
            <span *ngIf="this.isCompanyUser && !this.isCompanyAdmin"> {{user.firstName}}'s Incomplete Items </span>
          </ion-title>
          <ion-buttons slot="end">
            <ion-button routerLink="/lumber-item/create" color="primary">
              <span class="mat-button-label">Item</span>
              <mat-icon inline="true" class="mat-button-icon pl-2">add</mat-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
        <app-item-list
          [isLoading]="this.dashboardItemsLoading"
          [includeChildren]="false"
          [displayColumns]="this.dashboardItemColumns"
          [data]="this.dashboardItems?.data"
          (viewItem)="this.openQuickView($event)"
        >
        </app-item-list>
        <mat-paginator
          #paginator
          [length]="this.dashboardItems.size"
          [pageSize]="dashboardItemPageSize"
          [pageIndex]="dashboardItemPageNumber"
          [pageSizeOptions]="dashboardItemPageOptions"
          (page)="this.dashboardItemsPageChange($event)"
          showFirstLastButtons
        ></mat-paginator>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
