import { BaseEntity } from '../baseEntity';

export class WoodType extends BaseEntity {

    constructor() {
        super();
        this.isActive = true;
    }

    reference: string;
    isActive: boolean;

    isLumber: boolean;
    isRemoval: boolean;
    isReclaim: boolean;
    isFinishedProduct: boolean;
    isMunicipal: boolean;

    get isLog(): boolean {
        return this.isRemoval;
    }
}
