import { BaseEntity } from './baseEntity';

export class SystemSetting extends BaseEntity {

  constructor() { super(); }

  reference: string; //key
  value: string; // value represented/stored as string parsed elsewhere
  canEdit: boolean;
  description: string;
  associatedId: string; // UUID
}