<ion-header>
  <ion-toolbar>
    <ion-title>Delete Account</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-button type="button" color="danger" [disabled]="this.isDeleting" (click)="this.deleteAccount()">
          <ion-spinner [hidden]="!this.isDeleting"></ion-spinner>
          <span [hidden]="this.isDeleting">Delete Account</span>
        </ion-button>
        <p>
          Contact us at
          <span [hidden]="this.supportEmail !== null">
            <ion-item>
              <ion-spinner></ion-spinner>
            </ion-item>
          </span>
          <a [hidden]="this.supportEmail == null" href="mailto:{{ this.supportEmail }}">{{ this.supportEmail }}</a>
          for more information or any issues.
        </p>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
