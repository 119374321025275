import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';

import { RolesService } from 'src/app/services/roles.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyUserGuard implements CanActivate {
  constructor(private rolesService: RolesService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.rolesService.isCompanyUserOrHigher$().then(res => {
      if (res == false) {
        return Promise.reject(false);
      }
      return res;
    }).catch(err => {
      return this.router.parseUrl('/');
    });
  }

}
