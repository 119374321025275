import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  title: BehaviorSubject<string> = new BehaviorSubject<string>('AncesTREE');
  title$: Observable<string> = this.title.asObservable();
  displayCompany: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  setTitle(value: string) {
    this.title.next(value);
  }
}
