<ion-content>
  <ion-list>
    <ion-item lines="full" [class.hidden]="link.hidden" *ngFor="let link of this.settingsLinks"
      (click)="dismissPopover()" [routerLink]="link.link" [queryParams]="link.navigationExtras?.queryParams"
      routerLinkActive="selected-item" [routerLinkActiveOptions]="link.activeOptions || this.routerMatchOpts">
      <div slot="start">
        <ion-icon slot="icon-only" [name]="link.icon">
        </ion-icon>
        <ion-badge *ngIf="link.badge != null" class="badge-primary">
          {{link.badge}}
        </ion-badge>
      </div>
      <ion-label>
        {{link.name}}
      </ion-label>
    </ion-item>
    <ion-item lines="none" button (click)="logout();">
      <div slot="start">
        <ion-icon name="log-out-outline"></ion-icon>
      </div>
      <ion-label>Log out</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
