<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>{{this.title}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true">
  <div class="flex isLoading">
    <ion-spinner color="primary"></ion-spinner>
  </div>
</ion-content>
