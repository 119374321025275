import { Type } from 'class-transformer';
import { CompanyAccount } from 'src/app/models/company-account';
import { BaseEntity } from './baseEntity';
export class WoodProcessingService extends BaseEntity {

  constructor() {
    super();
    this.isActive = true;
    this.isOther = false;
    this.displayOrder = 0;
  }

  service: string;
  description: string;
  displayOrder: number;
  isActive: boolean;
  isOther: boolean;

  companyAccountId: number;
  @Type(() => CompanyAccount)
  companyAccount: CompanyAccount;

  get reference() {
    return this.service;
  }
}
