import { Directive, DoCheck, ElementRef, Renderer2 } from '@angular/core';
import { AbstractControl, FormControl, NgControl } from '@angular/forms';
import { IonInput } from '@ionic/angular';

@Directive({
  selector: 'ion-input[formControlName], ion-input[formControl], ion-textarea[formControlName], ion-textarea[formControl], ion-select[formControlName], ion-select[formControl]'
})
export class IonItemRequiredDirective implements DoCheck {

  constructor(private readonly ngControl: NgControl, private el: ElementRef, private renderer: Renderer2) { }

  ngDoCheck(): void {
    if (this.ngControl?.control != null) {
      const validator = this.ngControl.control.validator && this.ngControl.control.validator({} as AbstractControl);
      const isRequired = validator != null ? validator.hasOwnProperty('required') : false;
      const item = this.renderer.parentNode(this.el.nativeElement);
      if (item != null) {
        if (isRequired) {
          this.renderer.setAttribute(item, 'required', '');
        } else {
          this.renderer.removeAttribute(item, 'required');

        }
      }
    }
  }
}
