import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import {
  MultilevelMenuService,
  NgMaterialMultilevelMenuModule
} from 'ng-material-multilevel-menu';
import { NgPipesModule } from 'ngx-pipes';
import { QuicklinkModule } from 'ngx-quicklink';

import { CoreModule } from 'src/app/core/core.module';
import { DeleteAccountComponent } from 'src/app/pages/delete-account/delete-account.component';

import { AttachmentModule } from '../attachments/attachments.module';
import { CsvPopoverComponent } from '../csv-popover/csv-popover.component';
import { HeaderComponent } from '../header/header.component';
import { ProfilePopoverComponent } from '../profile-popover/profile-popover.component';
import { NavItemComponent } from './components/nav-item/nav-item.component';
import { NavComponent } from './nav.component';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    NgPipesModule,
    RouterModule,
    IonicModule,
    AttachmentModule,
    MatIconModule,
    MatTooltipModule,
    NgMaterialMultilevelMenuModule,
    QuicklinkModule
  ],
  exports: [
    HeaderComponent,
    NavComponent,
    NavItemComponent,
    QuicklinkModule
  ],
  declarations: [
    HeaderComponent,
    ProfilePopoverComponent,
    DeleteAccountComponent,
    CsvPopoverComponent,
    NavComponent,
    NavItemComponent
  ],
  providers: [
    MultilevelMenuService
  ],
})
export class NavModule { }
