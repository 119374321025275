import { ValidatorFn } from '@angular/forms';
import { isFunction } from 'lodash-es';

export interface BooleanFn {
  (): boolean;
}

type CustomValidatorFun = () => ValidatorFn;


/**
 * A conditional validator generator. Assigns a validator to the form control if the predicate
 * function returns true on the moment of validation
 * @example
 * Here if the myCheckbox is set to true, the myEmailField will be required and also the text will have to have the word 'mason' in the end.
 * If it doesn't satisfy these requirements, the errors will placed to the dedicated `illuminatiError` namespace.
 * Also the myEmailField will always have `maxLength`, `minLength` and `pattern` validators.
 * ngOnInit() {
 *   this.myForm = this.fb.group({
 *    myCheckbox: [''],
 *    myEmailField: ['', [
 *       Validators.maxLength(250),
 *       Validators.minLength(5),
 *       Validators.pattern(/.+@.+\..+/),
 *       conditionalValidator(() => this.myForm.get('myCheckbox').value,
 *                            Validators.compose([
 *                            Validators.required,
 *                            Validators.pattern(/.*mason/)
 *         ]),
 *        'illuminatiError')
 *        ]]
 *     })
 * }
 * @param predicate
 * @param validator
 * @param errorNamespace optional argument that creates own namespace for the validation error
 */
export function conditionalValidator(predicate: Function, validator: ValidatorFn | CustomValidatorFun, errorNamespace?: string): ValidatorFn {
  return (formControl => {
    if (!predicate(formControl)) {
      return null;
    }
    let error = fieldControlCheck(formControl, validator, errorNamespace);
    if (error != null) {
      return error;
    }
    return processValidation(formControl, predicate, validator, errorNamespace);
  });
}

export function conditionalValidatorParental(predicate: Function, validator: ValidatorFn | CustomValidatorFun, errorNamespace?: string): ValidatorFn {
  return (formControl => {
    if (formControl?.parent === null || !predicate(formControl)) {
      return null;
    }
    let error = fieldControlCheck(formControl, validator, errorNamespace);
    if (error != null) {
      return error;
    }
    if (formControl == null || formControl?.parent == null) {
      return null;
    }

    return processValidation(formControl, predicate, validator, errorNamespace);
  });
}

/**
 * MatFormFields in order to show the required validator * dynamically
 * we test with a {}, if this occurs we simply give it to the validator to return the error
 *
 * @param formControl
 * @param validator
 * @param errorNamespace
 * @returns
 */
function fieldControlCheck(formControl, validator, errorNamespace?: string) {
  if (Object.keys(formControl)?.length === 0) {
    let error = validator(formControl);
    if (isFunction(error)) {
      error = error(formControl);
    }
    if (errorNamespace && error) {
      const customError = {};
      customError[errorNamespace] = error;
      error = customError;
    }
    return error;
  }
}

function processValidation(formControl, predicate, validator, errorNamespace?: string) {
  let error = null;
  if (predicate(formControl)) {
    error = validator(formControl);
  }
  if (isFunction(error)) {
    error = error(formControl);
  }
  if (errorNamespace && error) {
    const customError = {};
    customError[errorNamespace] = error;
    error = customError;
  }
  return error;
}