import { IOtherable } from '../interfaces/IOtherable';
import { BaseEntity } from './../baseEntity';
export class LumberCharacteristic extends BaseEntity implements IOtherable {

    constructor() {
        super();
        this.isActive = true;
        this.isOther = false;
        this.displayOrder = 0;
    }

    characteristic: string;
    description: string;
    isActive: boolean;
    isOther: boolean;
    displayOrder: number;
}
