import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AlertController } from '@ionic/angular';
import {
  ExpandedLTR,
  ExpandedRTL,
  MultilevelNodes,
  NgMaterialMultilevelMenuComponent
} from 'ng-material-multilevel-menu';
import { combineLatest, Subscription } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { AuthService as OIDCService } from 'ionic-appauth';
import { CompanyAccount } from 'src/app/models/company-account';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { RolesService } from 'src/app/services/roles.service';
import { SystemSettingService } from 'src/app/services/system-setting.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';

export interface NavItem extends MultilevelNodes {
  activeOptions?: IsActiveMatchOptions;
  items?: NavItem[];
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  animations: [ExpandedRTL, ExpandedLTR]
})
export class NavComponent implements OnInit, OnDestroy {

  routerMatchOpts: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'ignored',
    paths: 'subset',
    fragment: 'ignored',
  };

  config: any;
  navigationItems: MultilevelNodes[];
  company: CompanyAccount;
  companyLabel: string = 'Company';

  isNative: boolean = false;
  isLoggedIn: boolean = false;
  isAdmin: boolean = false;
  isManager: boolean = false;
  isCompanyAdmin: boolean = false;
  isCompanyUser: boolean = false;
  @Input() expanded: boolean = false;
  @Input() notificationCount = 0;

  modalId: string = 'nav-loader';

  loggedInSub: Subscription;
  companySub: Subscription;
  userSub: Subscription;

  @ViewChild("menu") menu: NgMaterialMultilevelMenuComponent;

  ppLink: string;
  tosLink: string;

  constructor(
    private authService: AuthService,
    private oidc: OIDCService,
    private companyService: CompanyService,
    private userService: UserService,
    private alertController: AlertController,
    private toast: ToasterService,
    private rolesService: RolesService,
    public systemSettings: SystemSettingService,
    private alert: AlertService) {
  }

  ngOnInit(): void {
    this.config = {
      interfaceWithRoute: true,
      highlightOnSelect: true,
      collapseOnSelect: false,
      customTemplate: true,
    };
    this.isNative = Capacitor.isNativePlatform();
    this.loggedInSub = combineLatest([this.authService.loggedIn$, this.userService.user$, this.companyService.company$])
      .subscribe(([isLoggedIn, user, company]) => {
        this.isLoggedIn = isLoggedIn;
        if (isLoggedIn === true && user != null) {
          const { isAdmin, isManager, isCompanyAdmin, isCompanyUser } = this.rolesService.getUserRoles(user, company);
          this.isAdmin = isAdmin;
          this.isManager = isManager;
          this.isCompanyAdmin = isCompanyAdmin;
          this.isCompanyUser = isCompanyUser;
        } else {
          this.isAdmin = false;
          this.isManager = false;
          this.isCompanyAdmin = false;
          this.isCompanyUser = false;
        }
        this.navigationItems = this.generateMenu();
      });

    this.companySub = this.companyService.company.pipe(filter(c => c != null), take(1)).subscribe(res => {
      this.company = res;
      this.companyLabel = res.name || 'Company';
      if (this.navigationItems[2]?.label === 'Company Dashboard') {
        this.navigationItems[2].label = `${this.companyLabel} Dashboard`;
      }
    });

    this.systemSettings.getTOSLink().then(res => this.tosLink = res);
    this.systemSettings.getPrivacyPolicyLink().then(res => this.ppLink = res);
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.loggedInSub?.unsubscribe();
  }

  async logIn(): Promise<void> {
    const ref = await this.alert.createModal({
      cssClass: ['ulm-modal', 'small'],
      component: LoadingDialogComponent,
      componentProps: { title: 'Logging you in' },
      backdropDismiss: false,
      id: this.modalId
    });
    await ref.present();

    return this.authService.signIn().catch(err => {
      console.error(err);
      this.toast.error('Failed to Connect/Login User.');
    }).finally(async () => await this.alert.dismissModalById(this.modalId));
  }

  signOut(): Promise<void> {
    return this.authService.signOut();
  }

  // Match these icons from the design guide: https://fonts.google.com/icons?selected=Material+Icons+Outlined
  generateMenu(): NavItem[] {
    return [
      {
        label: 'Home',
        icon: 'home-outline',
        activeIcon: 'home-outline',
        link: this.isLoggedIn ? '' : environment.defaultRoute,
        hidden: this.isNative,
        activeOptions: {
          queryParams: 'ignored',
          matrixParams: 'ignored',
          paths: 'exact',
          fragment: 'ignored'
        }
      },
      {
        label: 'Home',
        icon: 'scan-outline',
        activeIcon: 'scan-outline',
        link: '/mobile',
        hidden: !this.isLoggedIn || !this.isNative,
        activeOptions: {
          queryParams: 'ignored',
          matrixParams: 'ignored',
          paths: 'exact',
          fragment: 'ignored'
        }
      },
      {
        label: ((this.isManager || this.isAdmin) && this.company == null) ? 'View Inventory' : 'Manage Inventory',
        icon: 'layers-outline',
        activeIcon: 'layers-outline',
        link: '/inventory',
        hidden: (!this.isCompanyUser && !this.isCompanyAdmin && !this.isManager && !this.isAdmin) || this.isNative,
        navigationExtras: {
          queryParams: { type: this.isManager || this.isAdmin ? 'ulm' : undefined }
        }
      },
      {
        label: ((this.isManager || this.isAdmin) && this.company == null) ? 'View Inventory' : 'Manage Inventory',
        icon: 'layers-outline',
        activeIcon: 'layers-outline',
        link: '/mobile/inventory',
        hidden: (!this.isCompanyUser && !this.isCompanyAdmin && !this.isManager && !this.isAdmin) || !this.isNative,
        navigationExtras: {
          queryParams: { type: this.isManager || this.isAdmin ? 'ulm' : undefined }
        }
      },
      {
        label: 'Chain of Custody',
        link: '/chain-of-custody',
        icon: 'document-outline',
        hidden: (!this.isCompanyUser && !this.isCompanyAdmin),
      },
      // {
      //   label: 'Sales Orders',
      //   link: '/sales-orders',
      //   hidden: !this.isCompanyUser && !this.isCompanyAdmin
      // },
      // {
      //   label: 'Drying Companies',
      //   link: '/drying-company',
      //   hidden: !this.isCompanyAdmin
      // },
      {
        label: 'Admin',
        link: null,
        hidden: !this.isAdmin,
        items: [
          {
            label: 'Dashboard',
            link: '/admin-dashboard'
          },
          {
            label: 'System Settings',
            link: '/system-setting'
          },
          {
            label: 'Request Tickets',
            link: '/request-tickets',
            activeOptions: {
              queryParams: 'exact',
              matrixParams: 'ignored',
              paths: 'subset',
              fragment: 'ignored'
            },
            navigationExtras: {
              queryParams: { type: 'ulm' }
            },
          },
          {
            label: 'Cut Type',
            link: '/cut-type',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Cut Type Group',
            link: '/cut-type-group',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Drying Types',
            link: '/drying-type',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Processing Equipment Group',
            link: '/equipment-group',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Item Grades',
            link: '/item-grade',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Lumber Characteristic',
            link: '/lumber-characteristic',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Reason for Removal',
            link: '/reason-for-removal',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Removed By',
            link: '/removed-by',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Removal Authorities',
            link: '/removal-authority',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Removal Locations',
            link: '/removal-location',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Shipping Options',
            link: '/shipping-option'
          },
          {
            label: 'Subscriptions',
            link: '/subscriptions'
          },
          {
            label: 'Tree Species',
            link: '/tree-species',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'User Types',
            link: '/user-type',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Wood Processing Services',
            link: '/wood-processing-service',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
        ]
      },
      {
        label: 'Manager',
        hidden: !this.isManager || (this.isManager && this.isAdmin),
        items: [
          {
            label: 'Dashboard',
            link: 'manager-dashboard'
          },
          {
            label: 'System Settings',
            link: '/system-setting'
          },
          {
            label: 'Subscriptions',
            link: '/subscriptions'
          },
          {
            label: 'Request Tickets',
            link: '/request-tickets',
            activeOptions: {
              queryParams: 'exact',
              matrixParams: 'ignored',
              paths: 'subset',
              fragment: 'ignored'
            },
            navigationExtras: {
              queryParams: { type: 'ulm' }
            },
          },
          {
            label: 'Cut Type',
            link: '/cut-type',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Cut Type Group',
            link: '/cut-type-group',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Drying Types',
            link: '/drying-type',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Processing Equipment Group',
            link: '/equipment-group',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Item Grades',
            link: '/item-grade',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Lumber Characteristic',
            link: '/lumber-characteristic',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Reason for Removal',
            link: '/reason-for-removal',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Removed By',
            link: '/removed-by',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Removal Authorities',
            link: '/removal-authority',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Removal Locations',
            link: '/removal-location',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Shipping Options',
            link: '/shipping-option'
          },
          {
            label: 'Tree Species',
            link: '/tree-species',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'User Types',
            link: '/user-type',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
          {
            label: 'Wood Processing Services',
            link: '/wood-processing-service',
            navigationExtras: {
              queryParams: { type: 'ulm' }
            }
          },
        ]
      },
      {
        label: 'Register',
        link: '/register',
        icon: 'person-add-outline',
        activeIcon: 'person-add-outline',
        hidden: this.isLoggedIn
      },
      {
        label: 'Log In',
        icon: 'log-in-outline',
        activeIcon: 'log-in-outline',
        hidden: this.isLoggedIn,
        onSelected: (event) => {
          this.logIn();
        }
      },
      // {
      //   label: 'Log Out',
      //   hidden: !this.isLoggedIn,
      //   onSelected: (event) => {
      //     this.signOut();
      //   }
      // }
    ];
  }

}
