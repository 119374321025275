import { BaseEntity } from './baseEntity';

export class RemovalLocation extends BaseEntity {

  constructor() {
    super();
    this.isActive = true;
  }

  reference: string;
  description?: string;
  cocPoints: number;

  isActive: boolean;

}