<div class="table-responsive">
  <table mat-table multiTemplateDataRows [dataSource]="this.dataSource" class="mat-elevation-z1">
    <!-- Checkbox Column -->
    <ng-container *ngIf="this.allowSelect" matColumnDef="select">
      <th class="select-header" mat-header-cell *matHeaderCellDef>
        <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="selectRow($event, row)"
          [checked]="isSelected(selection, row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <!-- Reference Column -->
    <ng-container *ngIf="this.overrideReference === false" matColumnDef="reference">
      <th mat-header-cell *matHeaderCellDef> Reference </th>
      <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
    </ng-container>

    <ng-container *ngIf="this.overrideActive === false" matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
      <td mat-cell *matCellDef="let element">
        <span>
          <mat-icon *ngIf="element.isActive === true">done</mat-icon>
        </span>
      </td>
    </ng-container>

    <ng-content></ng-content>

    <div *ngIf="!overrideRow">
      <tr mat-header-row *matHeaderRowDef="this.columns; sticky: this.sticky;"></tr>
      <div *ngIf="this.allowSelect">
        <tr [class.inactive]="row.isActive === false" (click)="this.toggle(row, $event)" mat-row
          *matRowDef="let row; columns: this.columns;"></tr>
      </div>
      <div *ngIf="!this.allowSelect">
        <tr mat-row *matRowDef="let row; columns: this.columns;"></tr>
      </div>
    </div>
  </table>
</div>
<div *ngIf="this.isLoading === true">
  <div class="mt-6">
    <mat-spinner class="isLoading"></mat-spinner>
  </div>
</div>
<div *ngIf="this.showMissingData === true && this.isLoading !== true">
  <h2 class="mt-3 ml-3" *ngIf="this.dataSource?.data == null || this.dataSource.data.length === 0">
    No Data
  </h2>
</div>
