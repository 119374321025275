<ion-app>
  <app-header [isNative]="this.isNative" title="{{this.titleService.title | async}}" (menuToggled)="handleMenuToggle()">
  </app-header>
  <ion-split-pane #pane when="md" contentId="main" class="sidebar sidebar--{{ opened ? 'opened' : 'closed' }}">
    <ion-menu menuId="main-menu" (ionWillOpen)="this.menuWillOpen($event)" (ionWillClose)="this.menuWillClose($event)"
      #menu contentId="main" class="sideMenu">
      <app-nav [expanded]="opened" #sidebar></app-nav>
    </ion-menu>
    <ion-router-outlet id="main" (activate)="this.isLoading = false"></ion-router-outlet>
  </ion-split-pane>
  <router-outlet id="modal" name="modal"></router-outlet>
</ion-app>
