// According to https://developers.google.com/maps/documentation/geocoding/overview#component-filtering
// Google's Geocoder uses the two letter ISO 3166-1 Country Codes
// Codes available here: https://en.wikipedia.org/wiki/ISO_3166-1#Current_codes
export class CountryType {
    static USA = "US";
    static CANADA = "CA";
}

export const CountryTypeMap = new Map<string, string>([
    [CountryType.USA, 'United States'],
    [CountryType.CANADA, 'Canada']
])

export const CountryCodeToTypeMap = new Map<string, string>([
    ['United States', CountryType.USA],
    ['Canada', CountryType.CANADA]
])
