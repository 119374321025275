import { IonicModule } from '@ionic/angular';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { CoreModule } from './../../core/core.module';
import { NgModule } from '@angular/core';
import { FileUploadModule } from '@iplab/ngx-file-upload';

import { AttachmentModalComponent } from './attachment-modal/attachment-modal.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { ImageComponent } from './image/image.component';
import { AttachmentsUploadComponent } from './attachments-upload/attachments-upload.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    MatButtonModule,
    MatIconModule,
    IonicModule
  ],
  declarations: [
    AttachmentsUploadComponent,
    AttachmentComponent,
    AttachmentModalComponent,
    ImageComponent
  ],
  exports: [
    AttachmentsUploadComponent,
    AttachmentComponent,
    AttachmentModalComponent,
    ImageComponent
  ],
  providers: [],
})
export class AttachmentModule { }
