import { Component, OnInit } from '@angular/core';

import { LoadingDialogComponent } from 'src/app/components/loading-dialog/loading-dialog.component';
import { AlertService } from 'src/app/services/alert.service';

import { AuthService } from '../../services/auth.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss'],
})
export class WelcomePage implements OnInit {
  response;
  root = 'WelcomePage';
  isLoggedIn: boolean;
  loading: boolean = false;

  modalId: string = 'welcome-loader';

  constructor(
    private toaster: ToasterService,
    private authService: AuthService,
    private alert: AlertService
  ) {
  }

  ngOnInit() { }

  async logIn() {
    this.loading = true;

    const ref = await this.alert.createModal({
      cssClass: ['ulm-modal', 'small'],
      component: LoadingDialogComponent,
      componentProps: { title: 'Logging you in' },
      backdropDismiss: false,
      id: this.modalId
    });
    await ref.present();

    this.authService.signIn().catch(err => {
      this.toaster.error('Network Error, Failed to attempt login.');
    }).finally(async () => {
      this.loading = false;
      await this.alert.dismissModalById(this.modalId);
    });
  }

  // Disabled for now
  // googleLogin() {
  //   this.authService.signIn({ acr_values: 'idp:Google' });
  // }

  //https://github.com/wi3land/ionic-appauth/issues/16
  // Disabled for now
  // googleSignup(companyId?: string) {
  //   this.authService.signIn({ acr_values: 'idp:Google', action: 'signup', companyId: companyId });
  // }
}
