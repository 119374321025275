import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { PopoverController } from '@ionic/angular';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { AuthService as OIDCService } from 'ionic-appauth';
import { CompanyAccount } from 'src/app/models/company-account';
import { RlsUserNotification } from 'src/app/models/notifications/rls-user-notification';
import { User } from 'src/app/models/user';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { ManagerService } from 'src/app/services/manager.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RolesService } from 'src/app/services/roles.service';
import { UserService } from 'src/app/services/user.service';

import { CsvPopoverComponent } from '../csv-popover/csv-popover.component';
import { ProfilePopoverComponent } from '../profile-popover/profile-popover.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() title = 'AncesTREE';
  @Input() isNative: boolean = false;
  @Input() displayCompany = true;
  @Output() menuToggled = new EventEmitter();

  @ViewChild('tooltip') csvTooltip: MatTooltip;

  user: User;
  company: CompanyAccount;
  loggedIn: boolean = false;
  isCompanyUser: boolean = false;
  isCompanyAdmin: boolean = false;
  isManagerOrHigher: boolean = false;
  unsubscribe: Subject<boolean> = new Subject();
  companyCarbonValue: number = 0;
  siteCarbonValue: number = 0;
  tooltipString = '';
  loadingCarbon: boolean = false;
  unreadNotifications: RlsUserNotification[] = [];
  isUSRWCertified = false;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    public notificationService: NotificationService,
    public companyService: CompanyService,
    public managerService: ManagerService,
    private rolesService: RolesService,
    private popover: PopoverController,
    private oidc: OIDCService,
    public attachmentService: AttachmentService
  ) { }

  ngOnInit() {

    combineLatest([this.authService.loggedIn$, this.userService.user$, this.companyService.company$]).pipe(takeUntil(this.unsubscribe))
      .subscribe(([isLoggedIn, user, company]) => {
        this.loggedIn = isLoggedIn;

        const roles = this.rolesService.getUserRoles(user, company);
        this.isCompanyUser = isLoggedIn ? roles.isCompanyUser : false;
        this.isCompanyAdmin = isLoggedIn ? roles.isCompanyAdmin : false;
        this.isManagerOrHigher = isLoggedIn ? roles.isManagerOrHigher : false;
        if (isLoggedIn === true) {
          this.notificationService.getUsersNotifications();
        }
        if (this.isCompanyUser == true || this.isCompanyAdmin == true && this.company != null) {
          this.companyService.getCompanyCSV(this.company?.id);
        }
        if (this.isManagerOrHigher) {
          this.managerService.getULMCSV();
        }
      });

    this.userService.user
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(u => this.user = u);

    this.notificationService.userNotifications$
      .pipe(takeUntil(this.unsubscribe), map(res => res?.filter(o => o.isRead === false)))
      .subscribe(notifs => this.unreadNotifications = notifs);

    if (this.displayCompany) {
      this.companyService.company
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(c => {
          this.company = c;
          this.isUSRWCertified = c?.isUSRWCertified || false;
          if (this.isCompanyUser === true || this.isCompanyAdmin === true && this.company != null) {
            this.companyService.getCompanyCSV(this.company?.id).catch(err => {
              console.error(err);
            });
          }
        });

    } else {
      this.company = null;
    }

  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
  }

  handleMenuClick() {
    this.menuToggled.emit();
  }

  /**
   *
   */
  launchCarbonDialog(event: any) {
    this.csvTooltip?.hide();
    this.loadingCarbon = true;
    const fetcCsvPromises = [];
    if (this.isCompanyUser == true || this.isCompanyAdmin === true) {
      fetcCsvPromises.push(this.companyService.getCompanyCSV(this.company?.id));
    }
    if (this.isManagerOrHigher === true) {
      fetcCsvPromises.push(this.managerService.getULMCSV());
    }
    return Promise.all(fetcCsvPromises).finally(() => {
      this.loadingCarbon = false;
      return this.carbonPopover(event);
    })
  }

  async carbonPopover(event: any) {
    const ref = await this.popover.create({
      component: CsvPopoverComponent,
      cssClass: ['ulm-popover', 'csv-popover'],
      event: event,
      translucent: true,
      componentProps: {
        isCompanyUser: this.isCompanyUser || this.isCompanyAdmin,
        isManagerOrHigher: this.isManagerOrHigher,
        dismissPopover: () => ref.dismiss()
      }
    });
    return await ref.present();
  }

  async profilePopover(ev: any) {
    const ref = await this.popover.create({
      component: ProfilePopoverComponent,
      cssClass: 'profile-popover',
      event: ev,
      translucent: true,
      componentProps: {
        notifications: this.unreadNotifications,
        dismissPopover: () => {
          ref.dismiss();
        }
      }
    });
    return await ref.present();
  }

  logout() {
    return this.authService.signOut();
  }

}
