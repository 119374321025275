import { Type } from 'class-transformer';

import { BaseEntity } from './baseEntity';
import { CutTypeGroupSubGroup } from './cut-type-group-sub-group';
import { IOtherable } from './interfaces/IOtherable';

export class CutTypeGroup extends BaseEntity implements IOtherable {

  constructor() {
    super();
    this.isActive = true;
    this.isOther = false;
    this.isSub = false;
  }

  reference: string;
  description: string;
  isActive: boolean;
  isOther: boolean;
  isSub: boolean;

  @Type(() => CutTypeGroupSubGroup)
  groupSubGroups: CutTypeGroupSubGroup[];

  @Type(() => CutTypeGroupSubGroup)
  subGroupGroups: CutTypeGroupSubGroup[];

  @Type(() => CutTypeGroup)
  subGroups: CutTypeGroup[];

  @Type(() => CutTypeGroup)
  parentGroups: CutTypeGroup[];

}
