import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from '../components/loading/loading.component';
import { LoadingDialogComponent } from '../components/loading-dialog/loading-dialog.component';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    LoadingComponent,
    LoadingDialogComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    MatProgressSpinnerModule
  ],
  exports: [
    LoadingComponent,
    LoadingDialogComponent
  ],
  providers: [],
})
export class SharedLoadingModule { }