import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { LoadingDialogComponent } from '../components/loading-dialog/loading-dialog.component';
import { AlertService } from '../services/alert.service';

@Injectable({ providedIn: 'root' })
export abstract class BaseResolver<T> implements Resolve<T> {

  abstract modalId: string;

  constructor(protected alert: AlertService) { }

  async resolve(route: ActivatedRouteSnapshot): Promise<T> {
    if (route.data?.showLoading === true) {
      const ref = await this.alert.createModal({ cssClass: ['ulm-modal', 'stack-modal', 'small'], component: LoadingDialogComponent, backdropDismiss: false, id: this.modalId });
      await ref.present();
    }
    return this.onResolve(route).finally(async () => await this.alert.dismissModalById(this.modalId));
  }

  abstract onResolve(route: ActivatedRouteSnapshot): Promise<T>;

}
