import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isString } from 'lodash-es';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorResponse } from './http-error.interceptor';

@Injectable()
export class BlobErrorHttpInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return next.handle(req).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse && err.error instanceof Blob /*&& err.error.type === 'application/json'*/) {
          // https://github.com/angular/angular/issues/19888
          // When request of type Blob, the error is also in Blob instead of object of the json data
          return new Promise<any>((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = (e: Event) => {
              try {
                const errmsg = err.error.type === 'application/json' ? JSON.parse((<any>e.target).result) : (<any>e.target).result;
                const finalError: ErrorResponse = {
                  status: err.status,
                  display: isString(errmsg) ? errmsg : errmsg?.message,
                  message: isString(errmsg) ? errmsg : errmsg?.message,
                  error: new HttpErrorResponse({
                    error: errmsg,
                    headers: err.headers,
                    status: err.status,
                    statusText: err.statusText,
                    url: err.url
                  }),
                };
                reject(finalError);
              } catch (e) {
                console.log(e);
                reject(err);
              }
            };
            reader.onerror = (e) => {
              console.log(e);
              reject(err);
            };
            reader.readAsText(err.error);
          });
        }
        return throwError(err);
      })
    );
  }
}