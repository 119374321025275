import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-send-button',
  templateUrl: './send-button.component.html',
  styleUrls: ['./send-button.component.scss'],
})
export class SendButtonComponent implements OnInit {
  @Input() sending = false;
  @Input() text = '';
  @Input() buttonType = 'button';
  @Input() disabled = false;
  @Input() color = 'primary';
  @Input() expand: 'block' | 'full';

  @Output() click = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  // onClick() {
  //   this.click.emit();
  // }

}
