import { Transform, Type } from 'class-transformer';
import * as moment from 'moment';
import { Moment } from 'moment';

import { Address } from '../address';
import { BaseEntity } from '../baseEntity';
import { ChainOfCustody } from '../coc/chain-of-custody';
import { CompanyAccount } from '../company-account';
import { CutType } from '../cut-type';
import { DryingTypeType } from '../enums/drying-type-type';
import { LumberItemRemovalLocationType } from '../enums/lumber-item-removal-location-type';
import { LumberItemStatus } from '../enums/lumber-item-status';
import { IAttachable } from '../interfaces/IAttachable';
import { IAuditable } from '../interfaces/IAuditable';
import { ReasonForRemoval } from '../reason-for-removal';
import { LumberItemLumberCharacteristic } from '../relations/lumber-item-lumber-characteristic';
import { LumberItemWoodProcessingService } from '../relations/lumber-item-wood-processing-service';
import { RlsLumberItemImage } from '../relations/rls-lumber-item-image';
import { RemovalLocation } from '../removal-location';
import { RemovedBy } from '../removed-by';
import { ScalingMethod } from '../scaling-method';
import { ShippingOption } from '../shipping-option';
import { StorageLocation } from '../storage-location';
import { TreeSpecies } from '../tree-species';
import { User } from '../user';
import { WoodProcessingService } from '../wood-processing-service';
import { ItemGrade } from './item-grade';
import { LumberCharacteristic } from './lumber-characteristic';
import { WoodType } from './wood-type';

export class LumberItem extends BaseEntity implements IAuditable, IAttachable {

    constructor() {
        super();
        this.isActive = true;
        this.isGreen = true;
        this.isBundle = false;
        this.isTagged = false;
        this.isComplete = false;
        this.status = LumberItemStatus.INCOMPLETE;
        this.isCOC = null;
        this.isBucked = null;
        this.isStored = null;
        this.forSale = false;
        this.isSold = false;
        this.created = moment();

        this.width = null;
        this.width2 = null;
        this.width3 = null;
        this.width4 = null;
        this.width5 = null;
        this.isWidthRange = null;
        this.length = null;
        this.length = null;
        this.thickness = null;
        this.diameter2 = null;
        this.diameter = null;

        this.bundleLength = null;
        this.bundleWidth = null;
        this.bundleMinWidth = null;
        this.bundleMaxWidth = null;
        this.bundleQuantity = null;
        this.bundleHeight = null;

        this.calculatedSize = null;
        this.calculatedAreaSize = null;
        this.calculatedTotalSize = null;
        this.carbonSequesteredValue = null;
    }

    get attachableName(): string {
        return "LUMBERITEM";
    }

    get postedForSale(): boolean | null {
        return this.isActive === true && 
        (this.status === LumberItemStatus.COMPLETE || this.status === LumberItemStatus.INTERIM_COMPLETE) && 
        this.forSale === true;
    }

    auditId?: number;

    parentId: number;
    @Type(() => LumberItem)
    parent: LumberItem;

    @Type(() => LumberItem)
    children: LumberItem[];

    // rowVersion: string;
    isComplete: boolean;
    status: LumberItemStatus;
    name?: string;
    description?: string;
    qrcode?: string;

    forSale: boolean;
    isSold: boolean;

    @Transform((m: any): Moment => m == null ? null : moment(m), { toClassOnly: true })
    @Transform((m: Moment): any => m == null ? null : moment(m).format(), { toPlainOnly: true })
    created: Moment;

    @Transform((m: string): any => m == null ? null : moment(m), { toClassOnly: true })
    @Transform((m: Moment): any => m != null ? m.format() : null, { toPlainOnly: true })
    lastModified: Moment;

    isActive: boolean;
    isTagged: boolean;
    isBundle: boolean;

    thickness?: number;
    width?: number;
    width2?: number;
    width3?: number;
    width4?: number;
    width5?: number;
    length?: number;
    length2?: number;
    diameter?: number;
    diameter2?: number;

    isGreen: boolean;
    dryingMethod?: DryingTypeType;
    moistureContent?: number;

    @Transform((m: any): Moment => m == null ? null : moment(m), { toClassOnly: true })
    @Transform((m: Moment): any => m == null ? null : m.format(), { toPlainOnly: true })
    dryingDate: Moment;

    history: boolean;

    repeatQuantity: number;

    companyAccountId: number;
    @Type(() => CompanyAccount)
    companyAccount: CompanyAccount;

    createdById: string;
    @Type(() => User)
    createdBy: User;

    woodTypeId: number;
    @Type(() => WoodType)
    woodType: WoodType;

    // Bundled Section
    bundleWidth?: number;
    bundleHeight?: number;
    bundleLength?: number;
    isWidthRange?: boolean;
    bundleMinWidth?: number;
    bundleMaxWidth?: number;
    bundleQuantity?: number;

    // @Type(() => LumberItemLumberCharacteristic)
    lumberItemLumberCharacteristics: LumberItemLumberCharacteristic[];

    @Type(() => LumberCharacteristic)
    lumberCharacteristics: LumberCharacteristic[];

    itemGradeId: number;
    @Type(() => ItemGrade)
    itemGrade: ItemGrade;

    cutTypeId: number;
    @Type(() => CutType)
    cutType: CutType;

    // @Type(() => LumberItemWoodProcessingService)
    lumberItemWoodProcessingServices: LumberItemWoodProcessingService[];

    @Type(() => WoodProcessingService)
    woodProcessingServices: WoodProcessingService[];

    isNaturallyFallen: boolean;
    // Todo when this is implemented might need to be handled in the copy
    reasonForRemovalId: number;
    @Type(() => ReasonForRemoval)
    reasonForRemoval: ReasonForRemoval;

    removedById: number;
    @Type(() => RemovedBy)
    removedBy: RemovedBy;

    scalingMethodId: number;
    @Type(() => ScalingMethod)
    scalingMethod: ScalingMethod;

    isCOC: boolean;
    chainOfCustody: ChainOfCustody;

    currentStorageLocationId: number;
    @Type(() => StorageLocation)
    currentStorageLocation: StorageLocation;

    structureInfo: string;
    structureName: string;

    @Transform((m: any): Moment => m == null ? null : moment(m), { toClassOnly: true })
    @Transform((m: Moment): any => m == null ? null : m.format(), { toPlainOnly: true })
    structureBuildDate: Moment;

    @Transform((m: any): Moment => m == null ? null : moment(m), { toClassOnly: true })
    @Transform((m: Moment): any => m == null ? null : m.format(), { toPlainOnly: true })
    structureDemoDate: Moment;

    structureDemoReason: string;
    structureAdditionalInfo: string;

    @Type(() => RlsLumberItemImage)
    rlsLumberItemImages: RlsLumberItemImage[];

    treeSpeciesId: number;
    @Type(() => TreeSpecies)
    treeSpecies: TreeSpecies;

    hasOther: boolean;

    // @Transform((m: string): any => m == null ? null : moment(m), { toClassOnly: true })
    // @Transform((m: Moment): any => m != null ? m.format() : null, { toPlainOnly: true })
    // removalStartDate: Moment;

    removalLocationType?: LumberItemRemovalLocationType;

    removalLocationId: number;
    @Type(() => RemovalLocation)
    removalLocation: RemovalLocation;

    landfillDumpAddressId: number;
    @Type(() => Address)
    landfillDumpAddress: Address;

    treeRemovalLocationAddressId?: number;
    @Type(() => Address)
    treeRemovalLocationAddress: Address;

    detailedDescription?: string;
    privateNotes?: string;

    @Transform((m: any): Moment => m == null ? null : moment(m), { toClassOnly: true })
    @Transform((m: Moment): any => m == null ? null : m.format(), { toPlainOnly: true })
    felledDate?: Moment;

    // Cross Streets
    crossStreet1: string;
    crossStreet2: string;
    landmark: string;
    apn: number;
    treesRemoved: number;
    treeDBH: number;

    reclaimLocationId: number;
    @Type(() => Address)
    reclaimLocation: Address;

    xRemovalCoordinate: string;
    yRemovalCoordinate: string;

    isBucked?: boolean; // Log only property
    isStored?: boolean; // Log only property

    calculatedSize?: number;
    calculatedTotalSize?: number;
    calculatedAreaSize?: number; // Square
    carbonSequesteredValue?: number; // Always in pounds, converted to metric in display layer via pipe

    itemCost: number;
    sawingPlan: string;

    // Price Properties
    pricePerBoardPerUnit?: number;
    totalPrice?: number;
    priceModifier?: number;
    finalPrice?: number;

    // Shipping properties
    itemWeight?: number;
    shippingMaterialsWeight?: number;
    shippingMaterialsCost?: number;
    totalShippingWeight?: number;
    shippingCost?: number;
    totalShippingCost?: number;

    shippingOptions: ShippingOption[];

    shippingHeight?: number;
    shippingWidth?: number;
    shippingLength?: number;

    get isLumber(): boolean {
        return this.woodType?.isLumber;
    }

    get isRemoval(): boolean {
        return this.woodType?.isRemoval;
    }

    get isReclaimed(): boolean {
        return this.woodType?.isReclaim;
    }

    get isStoredBuckedLog(): boolean {
        return this.isBucked === true && this.isStored === true;
    }

    get isFinishedProduct(): boolean {
        return this.woodType?.isFinishedProduct;
    }

    get display(): string {
        return this.qrcode || this.name || "NEEDS QR CODE";
    }

}
