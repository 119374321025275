import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AlertController } from '@ionic/angular';
import { ReplaySubject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ItemQuickviewModalComponent } from 'src/app/components/item-quickview-modal/item-quickview-modal.component';
import { CompanyAccount } from 'src/app/models/company-account';
import { ItemListDto } from 'src/app/models/dto/ItemListDto';
import { LumberItemStatus } from 'src/app/models/enums/lumber-item-status';
import { LumberItemListFilter } from 'src/app/models/interfaces/ILumberItemListFilter';
import { IPaginationResult } from 'src/app/models/interfaces/IPaginationResult';
import { LumberItem } from 'src/app/models/lumber-items/lumber-item';
import { User } from 'src/app/models/user';
import { AlertService } from 'src/app/services/alert.service';
import { LumberItemsService } from 'src/app/services/lumber-items.service';
import { ManagerService } from 'src/app/services/manager.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RolesService } from 'src/app/services/roles.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SystemSettingService } from 'src/app/services/system-setting.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { environment } from 'src/environments/environment';

import { AuthService } from '../../services/auth.service';
import { CompanyService } from '../../services/company.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit, OnDestroy {
  user: User = null;
  company: CompanyAccount = null;

  userEmail = '';
  sending = false;

  isManagerOrHigher: boolean = false;
  isCompanyUser: boolean = false;
  isCompanyAdmin: boolean = false;
  isMobile = Capacitor.isNativePlatform();

  @ViewChild(MatPaginator) dashboardItemsPaginator;
  dashboardItems: IPaginationResult<ItemListDto> = new IPaginationResult<ItemListDto>();
  dashboardItemPageSize: number = 10;
  dashboardItemPageOptions = [5, 10, 25];
  dashboardItemPageNumber: number = 0;
  dashboardItemsLoading: boolean = false;
  dashboardItemColumns = ['qrcode', 'woodType', 'created', 'manage'];

  paramsSub: Subscription;
  supportEmail: string = environment.email;

  constructor(
    private companyService: CompanyService,
    private authService: AuthService,
    public roles: RolesService,
    public spinner: SpinnerService,
    public userService: UserService,
    private managerService: ManagerService,
    private alertController: AlertController,
    private itemService: LumberItemsService,
    private notification: NotificationService,
    private alert: AlertService,
    private route: ActivatedRoute,
    private toast: ToasterService,
    private systemSettingsService: SystemSettingService
  ) { }

  refresh(event) {
    this.queryDashboardItems().finally(() => event?.target?.complete());
  }

  async fetchRoles() {
    this.isManagerOrHigher = await this.roles.isManagerOrHigher$();
    this.isCompanyAdmin = await this.roles.isCompanyAdmin$();
    this.isCompanyUser = await this.roles.isCompanyUser$();
    if (this.isCompanyAdmin && this.dashboardItemColumns.includes('createdBy') != true) {
      this.dashboardItemColumns.push('createdBy');
    }
  }

  ngOnInit() {
  }

  ionViewWillEnter(): void {
    this.paramsSub = this.route.params.pipe().subscribe(async () => {
      this.company = this.route.snapshot.data?.companyAccount;
      this.user = this.route.snapshot.data?.user;
      await this.fetchRoles();
      this.notification.getUsersNotifications();
      if (this.company == null) {
        this.company = await this.companyService.getCompany$();
      }
      if (this.isCompanyUser || this.isCompanyAdmin) {
        this.companyService.getCompanyCSV(this.company?.id);
      }
      if (this.isManagerOrHigher) {
        this.managerService.getULMCSV();
      }
      this.queryDashboardItems();
      this.systemSettingsService.getSupportEmail(environment.email).then(res => this.supportEmail = res);
    });
  }

  dashboardItemsPageChange(event: PageEvent) {
    this.dashboardItemPageSize = event.pageSize;
    this.dashboardItemPageNumber = event.pageIndex;
    this.queryDashboardItems();
  }

  async openQuickView(item: LumberItem) {
    const ref = await this.alert.createModal({ cssClass: ['ulm-modal', 'quickview-modal'], component: ItemQuickviewModalComponent, componentProps: { item } });
    return await ref.present();
  }

  queryDashboardItems() {
    this.dashboardItemsLoading = true;
    const filter: LumberItemListFilter = {
      companyId: null,
      createdById: null,
      includeSubItem: true,
      includeInactive: false,
      isSold: false,
      onlyForSale: false,
      fetchRelationships: ['WoodType'],
      status: LumberItemStatus.INCOMPLETE,
      isHierarchy: false,
      orderBy: ['Created:desc', 'QrCode', 'Name']
    };

    if (this.isCompanyAdmin === true) {
      filter.companyId = this.company?.id;
      filter.createdById = null;
    } else if (this.isCompanyUser === true) {
      filter.companyId = this.company?.id;
      filter.createdById = this.user?.id;
    } else {
      this.dashboardItemsLoading = false;
      return;
    }

    return this.itemService.filterQuery(filter, this.dashboardItemPageSize, this.dashboardItemPageNumber)
      .then(res => {
        this.dashboardItems.data = res.data;
        this.dashboardItems.size = res.size;
      }).catch(err => {
        console.error(err);
        this.dashboardItems.data = [];
        this.dashboardItems.size = 0;
      }).finally(() => this.dashboardItemsLoading = false);
  }

  ionViewDidLeave() {
    this.paramsSub?.unsubscribe();
  }

  ngOnDestroy() {
    this.paramsSub?.unsubscribe();
  }

  approvalStatusLoading: boolean = false;
  async checkCompanyApprovalStatus() {
    try {
      this.approvalStatusLoading = true;
      const status = await this.companyService.checkApprovalStatus(this.company?.id);
      if (status === true) {
        await this.authService.silentRefresh(); // Refresh token to get companyId attached
        // Refetch User and Company via LoggedInUser
        await this.userService.getLoggedInUser(); // sets user and company
        this.user = await this.userService.getUser$();
        this.company = await this.companyService.getCompany$();
        await this.fetchRoles();
        this.toast.presentToast('Company Approved!');
        return;
      } else if (status === null) {
        this.toast.presentToast('Company still being reviewed, try again later');
      } else {
        this.company.approved = false;
        this.toast.presentToast(`Company has been denied, contact ${this.supportEmail} for details.`);
      }
    } catch (err) {
      console.error(err);
      this.toast.error(err.display);
    } finally {
      this.approvalStatusLoading = false;
    }
  }


  leaveCompany() {
    this.companyService.leaveCompany();
  }

  async alertLeave() {
    const alert = await this.alertController.create({
      header: `Leave ${this.company.name}`,
      message: `Are you sure you want to leave ${this.company.name}?`,
      buttons: [
        {
          text: 'Leave',
          cssClass: 'danger',
          handler: () => {
            this.leaveCompany();
          }
        },
        'Cancel'
      ]
    });

    await alert.present();
  }

}
