import { Type } from 'class-transformer';
import { Moment } from 'moment';

import { Address } from './address';
import { Attachment } from './attachment';
import { BaseEntity } from './baseEntity';
import { Currency } from './currency';
import { Email } from './email';
import { IAttachable } from './interfaces/IAttachable';
import { IAuditable } from './interfaces/IAuditable';
import { ItemGrade } from './lumber-items/item-grade';
import { MeasurementSystem } from './measurement-system';
import { Phone } from './phone';
import { RlsCompanyAddress } from './relations/rls-company-address';
import { RlsCompanyEmail } from './relations/rls-company-email';
import { RlsCompanyPhone } from './relations/rls-company-phone';
import { RlsCompanySpecies } from './relations/rls-company-species';
import { RlsUserCompany } from './relations/rls-user-company';
import { ScalingMethod } from './scaling-method';
import { TreeSpecies } from './tree-species';
import { User } from './user';
import { WoodProcessingService } from './wood-processing-service';

export class CompanyAccount extends BaseEntity implements IAttachable, IAuditable {

    constructor() {
        super();
        this.isActive = true;
        this.isUSRWCertified = false;
    }

    get attachableName(): string {
        return 'COMPANY';
    }

    name: string;
    logo?: Attachment;
    qrcodePrefix: string;
    email?: string;
    website?: string;
    approved: boolean;
    userLimit: number;
    isActive: boolean;
    speciesScientific: boolean;
    isUSRWCertified: boolean;

    currencyId: number;
    @Type(() => Currency)
    currency: Currency;

    measurementSystemId: number;
    @Type(() => MeasurementSystem)
    measurementSystem: MeasurementSystem;

    scalingMethodId: number;
    @Type(() => ScalingMethod)
    scalingMethod: ScalingMethod;

    get companyAddresses(): Address[] {
        return this.rlsCompanyAddresses?.map(r => r.address) || [];
    }
    get companyPhones(): Phone[] {
        return this.rlsCompanyPhones?.map(r => r.phone) || [];
    }

    get companyEmails(): Email[] {
        return this.rlsCompanyEmails?.map(r => r.email) || [];
    }

    @Type(() => TreeSpecies)
    treeSpecies: TreeSpecies[];

    rlsCompanySpecies: RlsCompanySpecies[];

    rlsUserCompanies: RlsUserCompany[];

    @Type(() => RlsCompanyAddress)
    rlsCompanyAddresses: RlsCompanyAddress[];

    @Type(() => RlsCompanyEmail)
    rlsCompanyEmails: RlsCompanyEmail[];

    @Type(() => RlsCompanyPhone)
    rlsCompanyPhones: RlsCompanyPhone[];

    companyAddressId: number;
    @Type(() => Address)
    companyAddress: Address;

    defaultAddressId: number;
    @Type(() => Address)
    defaultAddress: Address;

    companyEmailId: number;
    @Type(() => Email)
    companyEmail: Email;
    defaultEmailId: number;
    @Type(() => Email)
    defaultEmail: Email;

    companyPhoneId: number;
    @Type(() => Phone)
    companyPhone: Phone;
    defaultPhoneId: number;
    @Type(() => Phone)
    defaultPhone: Phone;

    itemGrades: ItemGrade[];
    woodProcessingServices: WoodProcessingService[];

    created: Date;
    lastModified: Date;

    createdById: string;
    createdBy: User;

}
