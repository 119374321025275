import { Params } from '@angular/router';

import { Exclude, Expose, Transform } from 'class-transformer';
import { Utilities } from 'src/app/shared/util';

import { BaseEntity } from '../baseEntity';
import { NotificationSendType } from '../enums/notification-send-type';
import { User } from '../user';
import { Notification } from './notification';

export class RlsUserNotification extends BaseEntity {
  constructor() {
    super();
    this.isDeleted = false;
    this.isRead = false;
  }
  notificationSendType: NotificationSendType;
  notification: Notification;
  receipientAccount: User;
  isDeleted: boolean;
  isRead: boolean;
  webPath: string;
  paramsPath: string;
  sentAt: Date;

  @Expose()
  @Transform((qp: any): Params => Utilities.getQueryMap(qp), { toClassOnly: true })
  queryParams: Params;
}

