import { Component, Input, OnInit } from '@angular/core';

import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss'],
})
export class LoadingDialogComponent implements OnInit {

  @Input() title: string = 'Loading';

  constructor() { }

  ngOnInit() { }

}
