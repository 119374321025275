import { BaseEntity } from "./baseEntity";
import { IOtherable } from "./interfaces/IOtherable";

export class UserType extends BaseEntity implements IOtherable {

  constructor() {
    super();
    this.isActive = true;
    this.isOther = false;
  }

  reference: string;
  description?: string;

  isActive: boolean;
  isOther: boolean;

}