import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { classToPlain, plainToClass } from 'class-transformer';

import { CompanyAccount } from '../models/company-account';
import { RlsUserCompany } from '../models/relations/rls-user-company';
import { User } from '../models/user';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  totalCSV: BehaviorSubject<number> = new BehaviorSubject(null);
  totalCSV$ = this.totalCSV;

  constructor(
    private api: ApiService
  ) { }

  getUsers() {
    return this.api.get('Account/AllUsers')
      .pipe(map(res => res?.map(o => plainToClass(User, o))))
      .toPromise();
  }

  getCompanyUsers(companyId: number): Observable<RlsUserCompany[]> {
    return this.api.get(`CompanyAccounts/Users/${companyId}`);
  }

  putOther(user: User): Observable<User> {
    return this.api.put(`Account/${user.id}`, classToPlain(user));
  }

  deactivateUser(user: User) {
    return this.api.delete(`UserAccount/DeactivateUser/${user.id}`)
      .toPromise();
  }

  getCompanies() {
    return this.api.get('CompanyAccounts/Admin')
      .pipe(map(res => res?.map(o => plainToClass(CompanyAccount, o, { enableImplicitConversion: true }))))
      .toPromise();
  }

  removeCompany(company: CompanyAccount) {
    return this.api.delete('CompanyAccounts/DeactivateCompany/' + company.id).toPromise();
  }

  approveCompany(company: CompanyAccount) {
    return this.api.put(`Account/ApproveCompany/${company.id}`, company).toPromise();
  }

  removeUser(rls: RlsUserCompany) {
    return this.api.delete(`CompanyAccounts/RemoveUser/${rls.id}`).toPromise();
  }

  getULMCSV() {
    return this.api.get(`CompanyAccounts/ULMCSV`)
      .pipe(tap(v => this.totalCSV.next(v))).toPromise()
      .catch(err => this.totalCSV.next(null));
  }
}
