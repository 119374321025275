import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { AuthService } from 'ionic-appauth';
import { environment } from 'src/environments/environment';

import { User } from '../models/user';
import { AlertService } from '../services/alert.service';
import { CompanyService } from '../services/company.service';
import { UserService } from '../services/user.service';
import { BaseResolver } from './base.resolver';

@Injectable({ providedIn: 'root' })
export class UserResolver extends BaseResolver<User> {

  modalId = 'user-loader';
  hasLoadedOnce: boolean = false;

  constructor(
    private oidc: AuthService,
    private router: Router,
    private userService: UserService,
    private companyService: CompanyService,
    protected alert: AlertService
  ) { super(alert); }

  async onResolve(route: ActivatedRouteSnapshot): Promise<User> {
    let user = await this.userService.getUser$();
    // if (this.hasLoadedOnce == false) {
    //   user = null;
    // }
    if (user != null) {
      return Promise.resolve(user);
    }
    return this.userService.getLoggedInUser().then(res => {
      this.hasLoadedOnce = true;
      return res;
    }).catch(err => {
      console.error(err);
      // Prevent UserResolver from becoming self-referential, as '/' has this resolver
      this.router.navigate([environment.defaultRoute]);
      return null;
    });
  }
}