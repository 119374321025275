import { Type } from 'class-transformer';
import { CompanyAccount } from '../company-account';
import { Email } from '../email';
import { BaseEntity } from '../baseEntity';

export class RlsCompanyEmail extends BaseEntity {

    constructor() {
        super();
        this.email = new Email();
    }

    companyAccount: CompanyAccount;

    @Type(() => Email)
    email: Email;
}
