import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppInjector } from './app/core/app.injector';
import { environment } from './environments/environment';

// import { defineCustomElements } from '@ionic/pwa-elements/loader';

// import * as Oidc from 'oidc-client';

// Oidc.Log.logger = console;


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(moduleRef => AppInjector.setInjector(moduleRef.injector))
  .catch(err => console.log(err));

// defineCustomElements(window);