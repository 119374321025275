import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  MatColumnDef,
  MatHeaderRowDef,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table';
import { Subscription } from 'rxjs';

import { BaseEntity } from 'src/app/models/baseEntity';

@Component({
  selector: 'app-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.scss'],
})
export class ListTableComponent<T> implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  @ViewChild(MatTable, { static: true }) table: MatTable<T>;

  @Input() isLoading: boolean;

  @Input() data: T[];
  @Input() dataSource: MatTableDataSource<T>;
  @Input() columns: string[];

  @Input() showMissingData: boolean = true;
  @Input() overrideReference: boolean = false;
  @Input() overrideRow: boolean = false;
  @Input() overrideActive: boolean = false;
  @Input() allowSelect: boolean = false;
  @Input() selectAllLocal: boolean = true;

  @Input() sticky: boolean = true;
  selection: SelectionModel<T> = new SelectionModel<T>(true, [], true);
  @Output() selectedRows: EventEmitter<T[]> = new EventEmitter<T[]>();
  @Output() allItemsSelected: EventEmitter<T[]> = new EventEmitter();
  @Output() allItemsRemoved: EventEmitter<any> = new EventEmitter();

  updateViaSource: boolean = false;
  updateViaData: boolean = false;

  selectionSub: Subscription;

  constructor() { }

  ngOnInit() {
    // Setup Update Type
    if (this.data === undefined && this.dataSource !== undefined) {
      this.updateViaSource = true;
    } else if (this.data !== undefined && this.dataSource === undefined) {
      this.updateViaData = true;
    } else {
      console.warn('app-list-table should be provided either a DataSource or Data to function properly.');
    }

    if (this.dataSource == null) {
      this.dataSource = new MatTableDataSource(this.data);
    } else if (this.data != null && this.data.length > 0) {
      this.dataSource.data = this.data;
    }
    this.selectionSub = this.selection.changed.subscribe(res => {
      this.selectedRows.emit(res.source.selected);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataSource == null || this.updateViaSource === true) {
      return;
    }
    if (this.updateViaData === true) {
      this.dataSource.data = changes.data?.currentValue || this.data || [];
    }
  }

  ngAfterContentInit(): void {
    this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
    this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));
  }

  ngOnDestroy(): void {
    this.selectionSub?.unsubscribe();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.paginator?.length || this.dataSource.data?.length || 0;
    return numSelected >= numRows;
  }

  isSelected(selection: SelectionModel<T>, row: any) {
    if (selection?.selected?.length > 0 && row instanceof BaseEntity) {
      const arr: any[] = selection.selected;
      return arr.findIndex(o => o.uuid === row.uuid) > -1;
    }
    return selection.isSelected(row);
  }

  toggle(row: T, event?: Event) {
    if (event?.target) {
      const e = event.target as HTMLElement;
      if (e.tagName === 'A') {
        return;
      }
    }
    this.selection.toggle(row);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected() === true) {
      this.clearSelection();
    } else {
      this.selectAll();
    }
  }

  public setSelection(data: any[]) {
    this.selection.clear();
    this.selection.select(...data);
  }

  selectRow(event: any, row: any) {
    this.selection.toggle(row);
  }

  selectAll() {
    if (this.selectAllLocal === true) {
      this.selection.select(...this.dataSource.data);
    }
    this.allItemsSelected.emit();
  }

  clearSelection() {
    this.selection.clear();
    this.allItemsRemoved.emit();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: T, index?: number): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${index + 1}`;
  }

}
