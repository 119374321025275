import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isArray, isString } from 'lodash-es';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, mergeMap, retryWhen, take } from 'rxjs/operators';

export interface ErrorResponse {
  error: HttpErrorResponse;
  status: number;
  message: any;
  display: any;
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retryWhen((errors) => {
          return errors.pipe(mergeMap(error => error.status === 429 ? of(error) : throwError(error)), delay(1000), take(2));
        }), catchError((error: HttpErrorResponse) => {
          let errorMessage = null;
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else if (error instanceof HttpErrorResponse && !(error.error instanceof Blob)) {
            if (error.status === 0) {
              // TODO prevent refresh failed error on status == 0
              errorMessage = 'Connection Failed, try again later.';
            } else if (error.status == 401) {
              errorMessage = 'Unauthorized';
            } else if (error.status == 403) {
              errorMessage = 'Unauthorized';
            } else if (error.status == 404) {
              errorMessage = 'Failed to load, Not Found';
            } else if (error?.error != null && isString(error?.error)) {
              if (error.error.length > 250) {
                errorMessage = 'Unknown Error';
              } else {
                errorMessage = error.error;
              }
            } else if (error?.error != null && isArray(error.error)) {
              errorMessage = error.error[0];
            } else if (error?.error?.errors != null && isArray(error?.error?.errors)) {
              errorMessage = error.error?.errors[0];
            } else {
              errorMessage = isString(error) ? error :
                (isString(error.error?.InnerException?.Message || error.error?.message || error.error?.Message || error.error) ? (error.error?.InnerException?.Message || error.error?.message || error.error?.Message || error.message) : null);
            }
            errorMessage = errorMessage || 'Unknown Error';
            const errorResult = { error, status: error.status, message: errorMessage, display: errorMessage };
            return throwError(errorResult);
          }
          return throwError(error);
        })
      );
  }

}
