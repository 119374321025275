export enum LumberItemStatus {
  INCOMPLETE = 0,
  COMPLETE = 1,
  INTERIM_INCOMPLETE = 2,
  INTERIM_COMPLETE = 3,
  COC_INCOMPLETE,
  COC_COMPLETE,
  COC_HISTORICAL_ONLY
}


export const LumberItemStatusMap = new Map([
  [LumberItemStatus.COMPLETE, 'Complete'],
  [LumberItemStatus.INCOMPLETE, 'Incomplete'],
  [LumberItemStatus.INTERIM_INCOMPLETE, 'Interim CoC Incomplete'],
  [LumberItemStatus.INTERIM_COMPLETE, 'Interim CoC Certified'],
  [LumberItemStatus.COC_INCOMPLETE, 'CoC Incomplete'],
  [LumberItemStatus.COC_COMPLETE, 'CoC Certified'],
  [LumberItemStatus.COC_HISTORICAL_ONLY, 'CoC Historical Data Only'],
]);

export const LumberItemStatusOptions = [
  { id: LumberItemStatus.COMPLETE, reference: 'Complete' },
  { id: LumberItemStatus.INCOMPLETE, reference: 'Incomplete' },
  { id: LumberItemStatus.INTERIM_INCOMPLETE, reference: 'Interim CoC Incomplete' },
  { id: LumberItemStatus.INTERIM_COMPLETE, reference: 'Interim CoC Certified' },
  { id: LumberItemStatus.COC_INCOMPLETE, reference: 'CoC Incomplete' },
  { id: LumberItemStatus.COC_COMPLETE, reference: 'CoC Certified' },
  { id: LumberItemStatus.COC_HISTORICAL_ONLY, reference: 'CoC Historical Data Only' }
];
