import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiUrl: string = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  parseParams(params) {
    let paramString = '?';
    const paramsArr = Object.keys(params);
    // if the params object is empty then return a blank string
    if (paramsArr.length === 0) {
      return '';
    }

    paramsArr.forEach((param, index) => {
      paramString += param + '=' + params[param];
      if (index !== paramsArr.length - 1) {
        paramString += '&';
      }
    });

    return paramString;
  }

  createFormData(files): FormData {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files[]', file.rawFile, file.name);
    });

    return formData;
  }

  get(endpoint: string, params?): Observable<any> {
    if (params) {
      endpoint += this.parseParams(params);
    }

    return this.http.get(this.apiUrl + endpoint).pipe(take(1));
  }

  post(endpoint, body, params?): Observable<any> {
    if (params) {
      endpoint += this.parseParams(params);
    }

    return this.http.post(this.apiUrl + endpoint, body).pipe(take(1));
  }

  put(endpoint, body, params?): Observable<any> {
    if (params) {
      endpoint += this.parseParams(params);
    }

    return this.http.put(this.apiUrl + endpoint, body).pipe(take(1));
  }

  delete(endpoint, params?): Observable<any> {
    if (params) {
      endpoint += this.parseParams(params);
    }

    return this.http.delete(this.apiUrl + endpoint).pipe(take(1));
  }
}
