import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { AlertOptions, ComponentRef, ModalOptions } from '@ionic/core';
import { assign } from 'lodash-es';

import { LoadingDialogComponent } from '../components/loading-dialog/loading-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  get defaultOptions(): AlertOptions {
    return {
      cssClass: 'ulm-alert',
      backdropDismiss: true
    };
  }

  get defaultModalOptions(): ModalOptions<ComponentRef> {
    return {
      component: null,
      cssClass: 'ulm-modal',
      backdropDismiss: true
    };
  }

  constructor(
    private alertController: AlertController,
    private modalController: ModalController
  ) { }


  createAlert(options: AlertOptions) {
    const opts = this.defaultOptions;
    assign(opts, options);
    return this.alertController.create(opts);
  }

  /**
   *
   * @param cancelHandler
   * @param confirmHandler
   * @returns
   */
  createConfirmCancelAlert(header, message, cancelText, confirmText, cancelHandler: { (): boolean; (): boolean; }, confirmHandler: { (): boolean; (): boolean; }, display?: string) {
    return this.createAlert({
      header: `${header}`,
      message: `<strong> ${message} </strong>`,
      backdropDismiss: false,
      buttons: [{
        text: `${cancelText}`,
        role: 'cancel',
        cssClass: 'secondary',
        handler: cancelHandler
      }, {
        text: `${confirmText}`,
        role: 'confirm',
        cssClass: 'danger',
        handler: confirmHandler
      }]
    });
  }

  // Same as confirmCancel alert, except without the 'danger' cssClass on confirm
  createChoiceAlert(header, message, cancelText, confirmText, cancelHandler: { (): boolean; (): boolean; }, confirmHandler: { (): boolean; (): boolean; }) {
    return this.createAlert({
      header: `${header}`,
      message: message != null ? `<strong> ${message} </strong>` : null,
      backdropDismiss: false,
      buttons: [{
        text: `${cancelText}`,
        role: 'cancel',
        cssClass: 'secondary',
        handler: cancelHandler
      }, {
        text: `${confirmText}`,
        cssClass: 'primary',
        handler: confirmHandler
      }]
    });
  }

  createNoticeAlert(header, message, confirmText, backdropDismiss = false) {
    return this.createAlert({
      header: `${header}`,
      message: `<strong> ${message}`,
      backdropDismiss: backdropDismiss,
      buttons: [{
        text: `${confirmText}`,
        cssClass: 'primary'
      }]
    });
  }

  async getCopyAmountAlert(confirmHandler?: any): Promise<number> {
    const ref = await this.createAlert({
      header: 'How many copies?',
      backdropDismiss: false,
      cssClass: ['ulm-alert', 'no-message'],
      inputs: [{
        cssClass: ['ion-text-right', 'alert-input-large'],
        name: 'amount',
        type: 'number',
        placeholder: 'Number of copies',
        value: 1,
        min: 1,
      }],
      buttons: [{
        role: 'cancel',
        text: 'Cancel',
        cssClass: 'danger'
      }, {
        text: 'continue',
        cssClass: 'primary',
        role: 'enter',
        handler: confirmHandler
      }]
    });
    await ref.present();
    const { data, role } = await ref.onDidDismiss();
    if (data == null || data?.status === false || role == 'cancel') {
      return Promise.reject('User cancelled');
    }
    const amount: number = data?.values?.amount as number;
    if (amount < 1) {
      return Promise.reject('Invalid copy amount');
    }
    return Promise.resolve(amount);
  }

  // TODO Need to refactor scanner though as well
  // as I am currently relying on the button and the template
  // inside the button to trigger, need to split into a modal
  // so I can handle from wherever
  async scanOrGenerateAlert(form: UntypedFormGroup) {
    const alert = await this.createAlert({
      header: 'Scan or Generate',
      backdropDismiss: true,
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'danger',
          role: 'cancel'
        },
        {
          text: 'Scan',
          cssClass: 'primary',
          role: 'scan'
        },
        {
          text: 'Generate',
          cssClass: 'secondary',
          role: 'generate'
        }
      ]
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'scan') {

    }
  }

  createModal(options: ModalOptions<ComponentRef>): Promise<HTMLIonModalElement> {
    const opts = this.defaultModalOptions;
    assign(opts, options);
    if (opts.component === null) {
      console.error('Failed to pass component ref to crateModal');
    }

    return this.modalController.create(opts);
  }

  async createLoadingModal(modalId: string): Promise<HTMLIonModalElement> {
    const ref = await this.createModal({ cssClass: ['ulm-modal', 'small'], component: LoadingDialogComponent, backdropDismiss: false, id: modalId });
    return ref;
  }

  async dismissModal() {
    try {
      const existing = await this.modalController.getTop();
      if (existing != null) {
        this.modalController.dismiss()
      }
    } catch (err) {
      console.error(err);
    }
  }

  async dismissModalById(id?: string) {
    try {
      return id == null ? this.dismissModal() : this.modalController.dismiss(undefined, undefined, id);
    } catch (err) {
      console.error(err);
    }
  }

  /**
   *
   * @returns
   */
  async dismissAllAlerts(status: boolean = false): Promise<boolean> {
    const current = await this.alertController.getTop();
    if (current == null) { return Promise.resolve(true); }
    return this.alertController.dismiss({ status: status }).then(() => this.dismissAllAlerts());
  }

  /**
  *
  * @returns
  */
  async dismissAllDialogs(status: boolean = false): Promise<boolean> {
    const current = await this.modalController.getTop();
    if (current == null) { return Promise.resolve(true); }
    return this.modalController.dismiss({ status: status }).then(() => this.dismissAllDialogs());
  }
}
