import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  DecimalPipe
} from '@angular/common';
import { NgModule } from '@angular/core';

import { DisableControlDirective } from '../directives/disable-control.directive';
import { MatInputAutofocusDirective } from '../directives/mat-input-autofocus.directive';
import { NgControlChangeDebouncedDirective } from '../directives/ng-control-change-debounced.directive';
import { NgModelChangeDebouncedDirective } from '../directives/ng-model-change-debounced.directive';
import { CastPipe } from '../pipes/cast.pipe';
import { DisplayFunctionPipe } from '../pipes/display-function.pipe';
import { FindByPipe } from '../pipes/find-by.pipe';
import { MomentPipe } from '../pipes/moment-pipe.pipe';
import { NotationNamePipe } from '../pipes/notation-name.pipe';
import { UomConversionPipe } from '../pipes/uom-conversion.pipe';
import { UomWeightPipe } from '../pipes/uom-weight.pipe';

@NgModule({
  declarations: [
    CastPipe,
    DisplayFunctionPipe,
    NotationNamePipe,
    UomConversionPipe,
    UomWeightPipe,
    FindByPipe,
    DisableControlDirective,
    NgModelChangeDebouncedDirective,
    NgControlChangeDebouncedDirective,
    MatInputAutofocusDirective,
    MomentPipe
  ],
  imports: [
    CommonModule,
  ],
  providers: [
    NotationNamePipe,
    CurrencyPipe,
    DecimalPipe,
    DatePipe
  ],
  exports: [
    CastPipe,
    DisplayFunctionPipe,
    NotationNamePipe,
    UomConversionPipe,
    UomWeightPipe,
    FindByPipe,
    DisableControlDirective,
    NgModelChangeDebouncedDirective,
    NgControlChangeDebouncedDirective,
    MatInputAutofocusDirective,
    MomentPipe
  ]
})
export class CoreModule { }
