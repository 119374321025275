import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NonMobileNavService {

  hasReturned = new BehaviorSubject(false);
  hasReturned$ = this.hasReturned;

  constructor() { }

}
