import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import { CompanyAccount } from 'src/app/models/company-account';
import { CompanyService } from 'src/app/services/company.service';
import { ManagerService } from 'src/app/services/manager.service';

@Component({
  selector: 'app-csv-popover',
  templateUrl: './csv-popover.component.html',
  styleUrls: ['./csv-popover.component.scss'],
})
export class CsvPopoverComponent implements OnInit {

  @Input() company: CompanyAccount;
  @Input() isManagerOrHigher: boolean = false;
  @Input() isCompanyUser: boolean = false;
  @Input() dismissPopover = () => { };


  constructor(
    public managerService: ManagerService,
    public companyService: CompanyService
  ) { }

  ngOnInit() {
    this.companyService.company.pipe(take(1)).subscribe(res => this.company = res);
  }

}
