import { Component, OnInit } from '@angular/core';

import { CompanyAccount } from 'src/app/models/company-account';
import { RlsCompanyUserSubscriptionDto } from 'src/app/models/dto/subscription-package-dto';
import { PaymentCustomerUser } from 'src/app/models/payment-customer-user';
import { User } from 'src/app/models/user';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { RolesService } from 'src/app/services/roles.service';
import { SubscriptionPackageService } from 'src/app/services/subscription-package.service';
import { SystemSettingService } from 'src/app/services/system-setting.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit {

  user: User;
  company: CompanyAccount;
  isCompanyOwner: boolean;
  subscription: RlsCompanyUserSubscriptionDto;
  customer: PaymentCustomerUser;
  isLoading: boolean;
  isDeleting: boolean;

  supportEmail: string;

  constructor(private userService: UserService,
    private alert: AlertService,
    private auth: AuthService,
    private companyService: CompanyService,
    private subService: SubscriptionPackageService,
    private toaster: ToasterService,
    private systemSettingService: SystemSettingService) { }

  ngOnInit() { }


  ionViewWillEnter() {
    this.systemSettingService.getSupportEmail(environment.email).then(res => this.supportEmail = res);
  }

  async fetch() {
    this.isLoading = true;
    try {
      const [user, company] = await Promise.all([
        this.userService.getUser$(),
        this.companyService.getCompany$(),
      ]);
      this.isCompanyOwner = user.id === company.createdById;
      this.customer = await this.subService.getExternalCustomer();
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  async deleteAccount(): Promise<void> {
    try {
      const ref = await this.alert.createConfirmCancelAlert('Delete Account',
        'Confirm you want to delete your account and all related information.', 'Cancel', 'Delete',
        () => false, () => true);
      await ref.present();
      const { role } = await ref.onDidDismiss();
      if (role === 'cancel') { return; }
      this.isDeleting = true;
      const user = await this.userService.getUser$();
      await this.userService.delete(user);
      await this.toaster.presentToast('Account Deleted, signing out!');
      try {
        await this.auth.signOut();
      } catch (err) {
        console.error(err);
        void this.toaster.error('Failed to signout after deletion.');
      }

    } catch (err) {
      console.error(err);
      void this.toaster.error(err.display);
    } finally {
      this.isDeleting = false;
    }
  }

}
