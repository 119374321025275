import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  private sideBarExpanded = new BehaviorSubject(false);
  public sideBarExpanded$: Observable<boolean> = this.sideBarExpanded;

  constructor() { }

  setSidebarExpanded(isExpanded: boolean) {
    this.sideBarExpanded.next(isExpanded);
  }
}
