import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';

import { RolesService } from '../services/roles.service';

@Injectable({
  providedIn: 'root'
})
export class ManagerOrAdminGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private roleService: RolesService
  ) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return Promise.all([this.roleService.isULMManager$(), this.roleService.isAdmin$()])
      .then(([isULMManager, isAdmin]) => {
        if (isULMManager == true || isAdmin == true) {
          return true;
        }
        return this.router.parseUrl('/');
      }).catch(err => this.router.parseUrl('/'));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return Promise.all([this.roleService.isULMManager$(), this.roleService.isAdmin$()])
      .then(([isULMManager, isAdmin]) => {
        if (isULMManager == true || isAdmin == true) {
          return true;
        }
        return this.router.parseUrl('/');
      }).catch(err => this.router.parseUrl('/'));
  }
}
