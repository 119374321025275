import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Capacitor } from '@capacitor/core';
import { ModalController } from '@ionic/angular';
import { map } from 'lodash-es';
import { take } from 'rxjs/operators';

import { CompanyAccount } from 'src/app/models/company-account';
import {
  LumberItemStatus,
  LumberItemStatusMap
} from 'src/app/models/enums/lumber-item-status';
import { CompanyService } from 'src/app/services/company.service';
import { LumberItemsService } from 'src/app/services/lumber-items.service';

// import Swiper core and required modules
import { Attachment } from '../../models/attachment';
import { LumberItem } from '../../models/lumber-items/lumber-item';
import { AttachmentService } from '../../services/attachment.service';

@Component({
  selector: 'app-item-quickview-modal',
  templateUrl: './item-quickview-modal.component.html',
  styleUrls: ['./item-quickview-modal.component.scss'],
})
export class ItemQuickviewModalComponent implements OnInit {

  @Input() item: LumberItem;
  @Input() showCompany: boolean = false;

  images: Attachment[];
  itemLoading: boolean = false;
  panelsOpen: number = 0;
  itemStatus = LumberItemStatus;
  baseLink = '/';
  itemStatusMap = LumberItemStatusMap;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  company: CompanyAccount;
  isNative: boolean = false;

  constructor(
    public imageService: AttachmentService,
    private companyService: CompanyService,
    private modalController: ModalController,
    private itemService: LumberItemsService
  ) { }

  ngOnInit() {
    this.isNative = Capacitor.isNativePlatform();
    this.companyService.company.pipe(take(1)).subscribe((c) => (this.company = c));
    this.itemLoading = true;
    this.images = map(this.item.rlsLumberItemImages, 'image');
    this.itemService
      .getByUUID(this.item.uuid)
      .then((res) => {
        this.item = res;
        if (this.company == null && this.showCompany) {
          this.companyService.getById(res.companyAccountId).then(c => this.company = c)
            .catch(err1 => this.company = null);
        }

        this.images = map(this.item.rlsLumberItemImages, 'image');
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => (this.itemLoading = false));
  }

  navigate() {
    this.modalController.dismiss();
  }

  handleAccordionButton() {
    if (this.panelsOpen > 0) {
      this.accordion.closeAll();
    } else {
      this.accordion.openAll();
    }
  }

  panelClosed() {
    this.panelsOpen -= 1;
  }

  panelOpened() {
    this.panelsOpen += 1;
  }
}
