import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CompanyAccount } from '../models/company-account';
import { CreateResponse } from '../models/create-response';
import { CreateUserDto } from '../models/dto/CreateUserDto';
import { RegisterUserDto } from '../models/dto/register-user-dto';
import { User } from '../models/user';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(
    private api: ApiService
  ) { }

  userRegister(model: CreateUserDto): Observable<CreateResponse<User>> {
    if (model.user.isNew == true) {
      delete model.user.id;
    }
    return this.api.post('Account/Register', model);
  }

  registerUser(model: RegisterUserDto): Promise<CreateResponse<User>> {
    return this.api.post('Account/NewRegister', model).toPromise();
  }


  companyRegister(company: CompanyAccount): Observable<CreateResponse<CompanyAccount>> {
    return this.api.post('CompanyAccounts/CompanyRegister', company);
  }

  postUserImage(user: User) {
  }

  postCompanyImage(company: CompanyAccount) {
  }
}
