import { BaseEntity } from './baseEntity';

export class MeasurementSystem extends BaseEntity {

    constructor() {
        super();
        this.isActive = true;
    }

    reference: string;
    isActive: boolean;
    unitFamily: UnitFamily;

    get isMetric(): boolean {
        return this.unitFamily === UnitFamily.Metric;
    }

    get isImperial(): boolean {
        return this.unitFamily === UnitFamily.USCustomary;
    }
}

export enum UnitFamily {
    USCustomary, Metric
}