import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, from, Observable, of, throwError } from 'rxjs';
import {
    catchError,
    filter,
    map,
    mergeMap,
    switchMap,
    take
} from 'rxjs/operators';

import { AuthService as OIDCService } from 'ionic-appauth';
import { environment } from 'src/environments/environment';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private oidc: OIDCService,
        private router: Router) {

    }

    /**
     *
     * @param req
     * @param next
     * @returns
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.addAuth(req).pipe(mergeMap(reqWithAuth => {
            return next.handle(reqWithAuth)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        if (error?.status === 401) {
                            // this.authService.signoutWithoutRedirect();
                            // This is occuring due to the token in memory in
                            // other tabs not updating once refreshed...
                            this.router.navigate([environment.defaultRoute]);
                        }
                        return throwError(error);
                    })
                );
        }));
    }

    // Issue when uploading Attachments and attachment header
    // content-type would be multipart/form but needs a boundary
    getContentTypeHeader = (req: HttpRequest<any>) => `${req?.headers?.get('Content-Type') || 'application/json'};v=${req.headers['x-api-version'] || environment.apiVersion}`;

    /**
     *
     * @param req HttpRequest<any>
     * @returns Observable<HttpRequest<any>>
     */
    addAuth(req: HttpRequest<any>): Observable<HttpRequest<any>> {
        // Ignore requests to auth server as they are for getting authenticated
        // though this could be done better potentially with bypass token
        if (req.url.indexOf(environment.oidcConfig.server_host) > -1) {
            return of(req).pipe(map(req => req.clone({
                setHeaders: { 'x-api-version': environment.apiVersion }
            })));
        }

        return combineLatest([this.authService.isRefreshing$.pipe(filter(isRefreshing => isRefreshing != true), take(1)), this.oidc.token$.pipe(take(1))]).pipe(take(1), switchMap(([isRefreshing, token]) => {
            let doRefresh = token != null ? !token?.isValid() : false;
            this.authService.isRefreshing.next(doRefresh);
            const authPromise = this.authService.getAuthorizationHeaderValue().then(authToken => {
                const authReq = req.clone({
                    setHeaders: {
                        Authorization: authToken,
                        'x-api-version': environment.apiVersion
                    }
                });
                return authReq;
            }).catch(err => {
                console.warn('Failed to add Auth Header');
                return req.clone({
                    setHeaders: { 'x-api-version': environment.apiVersion }
                });
            }).finally(() => this.authService.isRefreshing.next(false));
            return from(authPromise)
        }));
    }

}
