import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';

import {
  LumberItemStatus,
  LumberItemStatusMap
} from 'src/app/models/enums/lumber-item-status';

@Component({
  selector: 'app-item-status-icon',
  templateUrl: './item-status-icon.component.html',
  styleUrls: ['./item-status-icon.component.scss'],
})
export class ItemStatusIconComponent implements OnInit, OnChanges {

  @Input() status: LumberItemStatus;
  @Input() size = null;

  statusMap = LumberItemStatusMap;
  iconStatusMap: Map<LumberItemStatus, { icon?: string; src?: string; color: string }> = new Map([
    [LumberItemStatus.INTERIM_INCOMPLETE, { icon: 'star-outline', color: 'danger' }],
    [LumberItemStatus.INTERIM_COMPLETE, { icon: 'star-outline', color: 'success' }],
    [LumberItemStatus.COC_INCOMPLETE, { icon: 'star', color: 'danger' }],
    [LumberItemStatus.COC_COMPLETE, { icon: 'star', color: 'success' }],
    [LumberItemStatus.COC_HISTORICAL_ONLY, { src: 'assets/icon/historical-only.png', color: 'information' }],
  ]);
  display: { icon?: string; src?: string; color: string };

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status.currentValue === null) {
      this.display = null;
    }
    this.display = this.iconStatusMap.get(changes.status.currentValue);
  }

}
