import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { round } from 'lodash-es';

import { MeasurementSystem } from '../models/measurement-system';
import { UnitOfMeasurementService } from '../services/unit-of-measurement.service';

const trueRound = (value: number, precision: number = 3) => {
  if (value == null) { return null; }
  return round(value, precision);
};
@Pipe({
  name: 'uomWeight'
})
export class UomWeightPipe implements PipeTransform {

  constructor(private numberPipe: DecimalPipe) { }

  transform(value: any, ms?: MeasurementSystem, convert = true, unitsOnly = false): string {
    if (ms?.isMetric) {
      if (unitsOnly) {
        return "KG";
      }
      value = value == null ? 'TBD' : this.numberPipe.transform(trueRound(UnitOfMeasurementService.convertPoundsToKG(value)), '1.0-0');
      return `${value} kg`;
    }
    if (unitsOnly) {
      return "LB";
    }
    value = value == null ? 'TBD' : this.numberPipe.transform(trueRound(value), '1.0-0');
    return `${value} lb`
  }

}
