/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {
  ValidatorDeclaration,
  Validators as ReactiveValidators
} from 'angular-reactive-validation';
import { filter, indexOf, isFunction, startCase } from 'lodash-es';

import { LumberItem } from '../models/lumber-items/lumber-item';
import {
  conditionalValidator,
  conditionalValidatorParental
} from '../validators/conditional.validator';
import { ItemValidationService } from '../validators/item-validation.service';
import { DimensionPaths } from './lumber-item-form.service';

export type Initializer<T> = (T | (() => T));
export const evaluateInitializer = <T>(arg: Initializer<T>): T => isFunction(arg) ? arg() : arg;

export interface DimensionValidator {
  width: ValidatorFn[];
  width2: ValidatorFn[];
  width3: ValidatorFn[];
  width4: ValidatorFn[];
  width5: ValidatorFn[];
  length: ValidatorFn[];
  length2: ValidatorFn[];
  thickness: ValidatorFn[];
  diameter: ValidatorFn[];
  diameter2: ValidatorFn[];
  bundleLength: ValidatorFn[];
  bundleWidth: ValidatorFn[];
  bundleHeight: ValidatorFn[];
  bundleMinWidth: ValidatorFn[];
  bundleMaxWidth: ValidatorFn[];
  isWidthRange: ValidatorFn[];
  scalingMethod: ValidatorFn[];
  sawingPlan: ValidatorFn[];
};

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  static gtValidator = ValidatorDeclaration.wrapSingleArgumentValidator((min: number) =>
    (control: AbstractControl): ValidationErrors => (control.value == null || control.value > min) ? null : { 'gt': true }, 'gt');

  constructor() { }

  static requiredValidator(message: string = 'Required'): ValidatorFn {
    return ReactiveValidators.required(message);
  }

  static integerValidator(): ValidatorFn {
    return ReactiveValidators.pattern("^[0-9]*$", 'Cannot be a decimal');
  }

  static minLengthValidator(length: number): ValidatorFn {
    return ReactiveValidators.minLength(length, l => `Min Length: ${l}`);
  }

  static maxLengthValidator(length: number): ValidatorFn {
    return ReactiveValidators.maxLength(length, l => `Exceeds Max Length: ${l}`);
  }

  static minDimensionValue(gtValue: number = 0): ValidatorFn {
    return ValidationService.gtValidator(gtValue, (value) => `Must be greater than ${value}`);
  }

  requiredValidator(message: string = 'Required'): ValidatorFn {
    return ReactiveValidators.required(message);
  }

  requiredTrueValidator(message: string = 'Must be checked'): ValidatorFn {
    return ReactiveValidators.requiredTrue(message);
  }

  maxLength(length: number): ValidatorFn {
    return ReactiveValidators.maxLength(length, l => `Exceeds Max Length: ${l}`);
  }

  minValue(value: number): ValidatorFn {
    return ReactiveValidators.min(value, `Min Value: ${value}`);
  }

  static filteredMinArrayLength(key: string) {
    // TODO the unwrap after wrapping two arguments causes the function to become undefined
    return ValidatorDeclaration.wrapTwoArgumentValidator((min: number, filterProp: string) => {
      return this.filteredMinLengthValidator(min, (v => v && v[filterProp] === true), key);
    }, `minLengthArray${key}`);
  }

  static filteredMinLengthValidator(min: number, filterFunc: any, key: string) {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value != null && filter(c.value, filterFunc)?.length >= min) {
        const resp = {};
        resp[`minLengthArray${key}`] = { valid: true };
        return resp;
      }
      return null;
    };
  }

  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      required: 'Required',
      invalidEmailAddress: 'Invalid email.',
      invalidPassword: 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      minlength: `Minimum length ${validatorValue.requiredLength}`,
      maxlength: `Exceeds maximumn length ${validatorValue.requiredLength}`
    };

    return config[validatorName];
  }

  static itemValidator(control: AbstractControl) {
    if (control.value == null) { return null; }
    if (control.value.woodType == null) {
      return { invalidItem: true };
    } else {
      return null;
    }
  }

  // Item Validators
  // TODO Refactor this to be self sufficient per group
  // would need to return the subscription so it could be cleaned
  // up inside the calling component
  static getDimensionDefaultValidatorsPerItem() {
    return {
      width: [
        ValidationService.minDimensionValue(),
        ValidationService.conditionalReclaimTypeNonRanged(),
        ValidationService.conditionalIsFinishedProduct()
      ],
      width2: [ValidationService.minDimensionValue()],
      width3: [ValidationService.minDimensionValue()],
      width4: [ValidationService.minDimensionValue()],
      width5: [ValidationService.minDimensionValue()],
      length: [
        ValidationService.minDimensionValue(),
        ValidationService.conditionalReclaimNonBundleType(),
        ValidationService.conditionalLogNonBundleType(),
        ValidationService.conditionalIsFinishedProduct()
      ],
      length2: [ValidationService.minDimensionValue()],
      thickness: [
        ValidationService.minDimensionValue(),
        ValidationService.conditionalIsBundleNonLog(),
        ValidationService.conditionalReclaimType(),
        ValidationService.conditionalIsFinishedProduct()
      ],
      diameter: [
        ValidationService.minDimensionValue(),
        ValidationService.conditionalLogType()
      ],
      diameter2: [ValidationService.minDimensionValue()],
      bundleLength: [
        ValidationService.minDimensionValue(),
        ValidationService.conditionalIsBundleNonLog()
      ],
      bundleWidth: [
        ValidationService.minDimensionValue(),
        ValidationService.conditionalIsBundleNonLog()
      ],
      bundleHeight: [
        ValidationService.minDimensionValue(),
        ValidationService.conditionalIsBundleNonLog()
      ],
      bundleMinWidth: [
        ValidationService.minDimensionValue(),
        ValidationService.conditionalIsBundleAndRanged()
      ],
      bundleMaxWidth: [
        ValidationService.minDimensionValue(),
        ValidationService.conditionalIsBundleAndRanged(),
      ],
      isWidthRange: [ValidationService.conditionalIsBundleNonLog()],
      scalingMethod: [ValidationService.conditionalLogNonBundleType()]
    };
  }

  /**
   *
   * Special cases:
   * width, Diameter1, Diameter2,
   *  isBundle causes the cutType Validator to ignore, since in most cases it should,
   * to get around this I have created another Validator initializerIsBundleNonLogCutTypeRound for them, similarly with width
   *
   * @param self
   * @returns
   */
  static getDimensionDefaultValidators(self: any): DimensionValidator {
    return {
      width: [
        ValidationService.minDimensionValue(),
        ValidationService.initializerIsBundleNonLogNonRangedNonCutTypeRound(() => self.isBundle, () => self.isWidthRange, () => self.woodType?.isLog, () => self.cutTypeDimensionPaths?.path2),
        ValidationService.initializerReclaimTypeNonRanged(() => self.isWidthRange, () => self.woodType?.isReclaim),
        ValidationService.initializerIsNonBundleFinishedProduct(() => self.isBundle, () => self.woodType?.isFinishedProduct),
        ValidationService.itemParentDistanceValidator('width', () => self.parentItem),
        ValidationService.itemCutTypeDistanceValidator('width', () => { return { isBundle: self.isBundle, dimensionPaths: self.cutTypeDimensionPaths }; })
      ],
      width2: [
        ValidationService.minDimensionValue(),
        ValidationService.itemParentDistanceValidator('width2', () => self.parentItem),
        ValidationService.itemCutTypeDistanceValidator('width2', () => { return { isBundle: self.isBundle, dimensionPaths: self.cutTypeDimensionPaths }; })
      ],
      width3: [
        ValidationService.minDimensionValue(),
        ValidationService.itemCutTypeDistanceValidator('width3', () => { return { isBundle: self.isBundle, dimensionPaths: self.cutTypeDimensionPaths }; }),
        ValidationService.itemParentDistanceValidator('width3', () => self.parentItem)
      ],
      width4: [
        ValidationService.minDimensionValue(),
        ValidationService.itemParentDistanceValidator('width4', () => self.parentItem)
      ],
      width5: [
        ValidationService.minDimensionValue(),
        ValidationService.itemParentDistanceValidator('width5', () => self.parentItem)
      ],

      length: [
        ValidationService.minDimensionValue(),
        ValidationService.initializerReclaimNonBundleType(() => self.woodType?.isReclaim, () => self.isBundle),
        ValidationService.initializerLogNonBundleType(() => self.woodType?.isLog, () => self.isBundle),
        ValidationService.initializerIsNonBundleFinishedProduct(() => self.isBundle, () => self.woodType?.isFinishedProduct),
        ValidationService.itemParentDistanceValidator('length', () => self.parentItem),
        ValidationService.itemCutTypeDistanceValidator('length', () => { return { isBundle: self.isBundle, dimensionPaths: self.cutTypeDimensionPaths }; })
      ],
      length2: [
        ValidationService.minDimensionValue(),
        ValidationService.itemCutTypeDistanceValidator('length2', () => { return { isBundle: self.isBundle, dimensionPaths: self.cutTypeDimensionPaths }; }),
        ValidationService.itemParentDistanceValidator('length2', () => self.parentItem)
      ],
      thickness: [
        ValidationService.minDimensionValue(),
        ValidationService.initializerIsBundleNonLog(() => self.isBundle, () => self.woodType?.isLog),
        ValidationService.initializerReclaimType(() => self.woodType?.isReclaim),
        ValidationService.initializerIsNonBundleFinishedProduct(() => self.isBundle, () => self.woodType?.isFinishedProduct),
        ValidationService.itemParentDistanceValidator('thickness', () => self.parentItem),
        ValidationService.itemCutTypeDistanceValidator('thickness', () => { return { isBundle: self.isBundle, dimensionPaths: self.cutTypeDimensionPaths }; })
      ],
      diameter: [
        ValidationService.minDimensionValue(),
        ValidationService.initializerLogNonBundleType(() => self.woodType?.isLog, () => self.isBundle),
        ValidationService.itemParentDistanceValidator('diameter', () => self.parentItem),
        ValidationService.itemCutTypeDistanceValidator('diameter', () => { return { isBundle: self.isBundle, dimensionPaths: self.cutTypeDimensionPaths }; }),
        ValidationService.initializerIsBundleNonLogCutTypeRound(() => self.isBundle, () => self.woodType?.isLog, () => self.cutTypeDimensionPaths?.path2),
      ],
      diameter2: [
        ValidationService.minDimensionValue(),
        ValidationService.itemParentDistanceValidator('diameter2', () => self.parentItem),
        ValidationService.itemCutTypeDistanceValidator('diameter2', () => { return { isBundle: self.isBundle, dimensionPaths: self.cutTypeDimensionPaths }; }),
        ValidationService.initializerIsBundleNonLogCutTypeRound(() => self.isBundle, () => self.woodType?.isLog, () => self.cutTypeDimensionPaths?.path2),
      ],

      bundleLength: [
        ValidationService.minDimensionValue(),
        ValidationService.initializerIsBundleNonLog(() => self.isBundle, () => self.woodType?.isLog)
      ],
      bundleWidth: [
        ValidationService.minDimensionValue(),
        ValidationService.initializerIsBundleNonLog(() => self.isBundle, () => self.woodType?.isLog)
      ],
      bundleHeight: [
        ValidationService.minDimensionValue(),
        ValidationService.initializerIsBundleNonLog(() => self.isBundle, () => self.woodType?.isLog)
      ],
      bundleMinWidth: [
        ValidationService.minDimensionValue(),
        ValidationService.initializerIsBundleAndRanged(() => self.isBundle, () => self.isWidthRange, () => self.woodType?.isLog)
      ],
      bundleMaxWidth: [
        ValidationService.minDimensionValue(),
        ValidationService.initializerIsBundleAndRanged(() => self.isBundle, () => self.isWidthRange, () => self.woodType?.isLog)
      ],
      isWidthRange: [ValidationService.initializerIsBundleNonLog(() => self.isBundle, () => self.woodType?.isLog)],
      scalingMethod: [ValidationService.initializerLogNonBundleType(() => self.woodType?.isLog, () => self.isBundle)],
      sawingPlan: [ValidationService.maxLengthValidator(2000)]
    };
  }


  // Validators

  /**
   * Validation on requirement
   * - Based on Dimension Paths certain properties are required
   *
   * Path 1:
   * Path 1A:
   * Path1L:
   * Path2:
   * Path3:
   */
  static itemCutTypeDistanceValidator = ValidatorDeclaration.wrapTwoArgumentValidator((path: string, pathValidationMessage: any) => {
    return (control: AbstractControl): ValidationErrors => {
      if (control?.parent == null) {
        return null;
      }
      const isBundle: boolean = control.parent.getRawValue()?.isBundle !== undefined ? control.parent.getRawValue()?.isBundle : pathValidationMessage.isBundle;
      const dimensionPaths = pathValidationMessage.dimensionPaths;
      if (dimensionPaths == null || isBundle === true) {
        return;
      }
      const errorState = () => control?.value == null ? { 'required': { 'message': 'Required' } } : null;
      // TODO see if the below line is necessary should just be path1 as path1 is just the same logic
      const path1Container = (dimensionPaths?.path1 || dimensionPaths.path1A || dimensionPaths?.path1B);
      if (path === 'width' && (path1Container || dimensionPaths?.path3)) {
        return errorState();
      } else if (path === 'length' && (path1Container || dimensionPaths?.path3)) {
        return errorState();
      } else if (path === 'thickness' && ((path1Container && (dimensionPaths?.path1A || dimensionPaths?.path1B)) || dimensionPaths?.path2 || dimensionPaths?.path3)) {
        return errorState();
      } else if (path === 'length2' && dimensionPaths?.path1L === true) {
        return errorState();
      } else if ((path === 'width2' || path === 'width3') && path1Container && dimensionPaths?.isLive) {
        return errorState();
      } else if ((path === 'diameter' || path === 'diameter2') && dimensionPaths?.path2) {
        return errorState();
      }

      return null;
    }
  }, 'required');

  /**
   * Passes in parent or gets it from the form.parent.parent
   *
   * Validator for the parent distance attribute to act as the max value
   * Things this needs to handle:
   * 1) Normal - Parent Property to Property
   *
   * Parent isBundle, isWidthRanged
   * *** As of now this validator is not meant for bundle{Properties} as a bundle
   * could be shaped in ways that may break this
   *
   */
  static itemParentDistanceValidator =
    ValidatorDeclaration.wrapTwoArgumentValidator((prop: string, parentItem?: LumberItem) => {
      return (control: AbstractControl): ValidationErrors => {
        if (control?.parent == null) {
          return null;
        }

        const errorState = (value: number, comp: number, name: string) => (comp != null && value > comp) ? { 'maxParent': { 'message': 'Exceeds Parent ' + startCase(name) } } : null;

        const parent = control.parent.get('parent')?.value || parentItem;
        if (prop.includes('width') && parent?.isWidthRange === true) {
          let bundleProp = null;
          if (parent['bundleMaxWidth'] != null) {
            bundleProp = 'bundleMaxWidth';
          } else if (parent['bundleMinWidth']) {
            bundleProp = 'bundleMinWidth';
          } else {
            bundleProp = 'bundleWidth';
          }
          const comp = parent[bundleProp];
          return errorState(control?.value, comp, bundleProp)
        } else if (parent?.isBundle === true) {
          let bundleProp = null;
          // Piece Width && Piece Thickness exist for Lumber Bundles, but not Length
          // Dimensions don't exist for Grouped Logs so no need to handle
          if (prop.includes('length')) {
            bundleProp = 'bundleLength';
          }
          if (bundleProp != null) {
            return errorState(control?.value, parent[bundleProp], bundleProp);
          }
        }
        const comp = parent != null ? parent[prop] : null;
        return errorState(control.value, comp, prop);
      }
    }, 'maxParent');

  // Dimension Validators

  static initializerTruthyRequired(isTrue: Initializer<boolean>) {
    return conditionalValidator((control) => evaluateInitializer(isTrue) === true, ReactiveValidators.required('Required'));
  }

  // Reclaim Type
  static conditionalReclaimType() {
    return conditionalValidatorParental((control) => control.parent.getRawValue()?.woodType?.isReclaim === true,
      ReactiveValidators.required('Required'));
  }

  static initializerReclaimType(isReclaim: Initializer<boolean>) {
    return conditionalValidator((control) => evaluateInitializer(isReclaim) === true, ReactiveValidators.required('Required'));
  }

  // Reclaim Non-Ranged
  static conditionalReclaimTypeNonRanged() {
    return conditionalValidatorParental((control) => control.parent.getRawValue()?.isWidthRange !== true && control.parent.getRawValue()?.woodType?.isReclaim === true, ReactiveValidators.required('Required'));
  }

  static initializerReclaimTypeNonRanged(isRanged: Initializer<boolean>, isReclaim: Initializer<boolean>) {
    return conditionalValidator((control) => evaluateInitializer(isRanged) !== true && evaluateInitializer(isReclaim), ReactiveValidators.required('Required'));
  }

  // Reclaim Non-Bundle
  static conditionalReclaimNonBundleType() {
    return conditionalValidatorParental((control) => control.parent.getRawValue()?.woodType?.isReclaim === true && control.parent.getRawValue()?.isBundle === false, ReactiveValidators.required('Required'));
  }

  static initializerReclaimNonBundleType(isReclaim: Initializer<boolean>, isBundle: Initializer<boolean>) {
    return conditionalValidator((control) => evaluateInitializer(isReclaim) === true && evaluateInitializer(isBundle) === false, ReactiveValidators.required('Required'));
  }

  // Lumber
  static conditionalLumberType() {
    return conditionalValidatorParental((control) => control.parent.getRawValue()?.woodType?.isLumber === true, ReactiveValidators.required('Required'));
  }

  static initializerLumberType(isLumber: Initializer<boolean>) {
    return conditionalValidator((control) => evaluateInitializer(isLumber), ReactiveValidators.required('Required'));
  }

  // Log
  static conditionalLogType() {
    return conditionalValidatorParental((control) => control.parent.getRawValue()?.woodType?.isRemoval === true, ReactiveValidators.required('Required'));
  }

  static initializerLogType(isLog: Initializer<boolean>) {
    return conditionalValidator((control) => evaluateInitializer(isLog) === true, ReactiveValidators.required('Required'));
  }

  // Log Non-Bundle
  static conditionalLogNonBundleType() {
    return conditionalValidatorParental((control) => control.parent.getRawValue()?.woodType?.isRemoval === true && control.parent.getRawValue()?.isBundle === false, ReactiveValidators.required('Required'));
  }

  static initializerLogNonBundleType(isLog: Initializer<boolean>, isBundle: Initializer<boolean>) {
    return conditionalValidator((control) => evaluateInitializer(isLog) === true && evaluateInitializer(isBundle) === false, ReactiveValidators.required('Required'));
  }

  // Log Non-Bucked
  static initializerLogNonBuckedType(isLog: Initializer<boolean>, isBucked: Initializer<boolean>) {
    return conditionalValidator((control) => evaluateInitializer(isLog) === true && evaluateInitializer(isBucked) !== true, ReactiveValidators.required('Required'));
  }

  static initializerLogNonMunicipalType(isLog: Initializer<boolean>, isMunicipal: Initializer<boolean>) {
    return conditionalValidator((control) => evaluateInitializer(isLog) === true && evaluateInitializer(isMunicipal) !== true, ReactiveValidators.required('Required'));
  }

  static initializerMunicipalLogType(isLog: Initializer<boolean>, isMunicipal: Initializer<boolean>) {
    return conditionalValidator((control) => evaluateInitializer(isLog) === true && evaluateInitializer(isMunicipal) === true, ReactiveValidators.required('Required'));
  }

  // Log or Lumber
  static conditionalLumberOrLogType() {
    return conditionalValidatorParental((control) => (control.parent.getRawValue()?.woodType?.isLumber === true || control.parent.getRawValue()?.woodType?.isRemoval === true), ReactiveValidators.required('Required'))
  }

  static initializerLumberOrLogType(isLumber: Initializer<boolean>, isLog: Initializer<boolean>) {
    return conditionalValidatorParental((control) => (evaluateInitializer(isLumber) === true || evaluateInitializer(isLog) === true), ReactiveValidators.required('Required'))
  }

  // Bundle
  static conditionalIsBundle(isBundle: Initializer<boolean>) {
    return conditionalValidatorParental((control) => evaluateInitializer(isBundle) === true, ReactiveValidators.required('Required'));
  }

  static initializerIsBundle(isBundle: Initializer<boolean>) {
    return conditionalValidator(() => evaluateInitializer(isBundle) === true, ReactiveValidators.required('Required'));
  }

  static conditionalIsFinishedProduct() {
    return conditionalValidatorParental((control) => control.parent.getRawValue()?.woodType?.isFinishedProduct === true, ReactiveValidators.required('Required'));
  }

  static conditionalIsNonBundleFinishedProduct() {
    return conditionalValidatorParental((control) => {
      const value = control.parent.getRawValue();
      return value?.isBundle === false && value?.woodType?.isFinishedProduct === true;
    }, ReactiveValidators.required('Required'));
  }

  // Bundle - Non-Log
  static conditionalIsBundleNonLog() {
    return conditionalValidatorParental((control) => control.parent.getRawValue()?.isBundle === true && control.parent.getRawValue()?.woodType?.isRemoval === false, ReactiveValidators.required('Required'));
  }

  static initializerIsBundleNonLog(isBundle: Initializer<boolean>, isLog: Initializer<boolean>) {
    return conditionalValidator(() => evaluateInitializer(isBundle) === true && evaluateInitializer(isLog) === false, ReactiveValidators.required('Required'));
  }

  static initializerIsFinishedProduct(isFinishedProduct: Initializer<boolean>) {
    return conditionalValidator(() => evaluateInitializer(isFinishedProduct) === true, ReactiveValidators.required('Required'));
  }

  static initializerIsNonBundleFinishedProduct(isBundle: Initializer<boolean>, isFinishedProduct: Initializer<boolean>) {
    return conditionalValidator(() => evaluateInitializer(isBundle) === false && evaluateInitializer(isFinishedProduct) === true, ReactiveValidators.required('Required'));
  }

  // Bundle - Non-Log, Non-Ranged
  // TODO fix this for cuttype path2
  static conditionalIsBundleNonLogNonRanged() {
    return conditionalValidatorParental((control: AbstractControl) => {
      if (control == null || control.parent == null) { return false; }
      const itemValue = control.parent.getRawValue();
      return itemValue.isBundle === true && itemValue.isWidthRange === false && itemValue.woodType?.isRemoval === false
    }, ReactiveValidators.required('Required'));
  }

  static conditionalIsBundleNonLogNonRangedNonCutTypeRound(dimensionPaths: DimensionPaths) {
    return conditionalValidatorParental((control: AbstractControl) => {
      if (control == null || control.parent == null) { return false; }
      const itemValue = control.parent.getRawValue();
      return itemValue.isBundle === true && itemValue.isWidthRange === false && itemValue.woodType?.isRemoval === false && dimensionPaths?.path2 !== true
    }, ReactiveValidators.required('Required'));
  }

  static initializerIsBundleNonLogNonRangedNonCutTypeRound(isBundle: Initializer<boolean>, isRanged: Initializer<boolean>, isLog: Initializer<boolean>, path2: Initializer<boolean>) {
    return conditionalValidator(() => evaluateInitializer(isBundle) === true && evaluateInitializer(isRanged) === false && evaluateInitializer(isLog) === false && evaluateInitializer(path2) !== true, ReactiveValidators.required('Required'));
  }

  static conditionalIsBundleNonLogCutTypeRound(dimensionPaths: DimensionPaths) {
    return conditionalValidatorParental((control: AbstractControl) => {
      if (control == null || control.parent == null) { return false; }
      const itemValue = control.parent.getRawValue();
      return itemValue.isBundle === true && itemValue.woodType?.isRemoval === false && dimensionPaths?.path2 === true
    }, ReactiveValidators.required('Required'));
  }

  static initializerIsBundleNonLogCutTypeRound(isBundle: Initializer<boolean>, isLog: Initializer<boolean>, path2: Initializer<boolean>) {
    return conditionalValidator(() => evaluateInitializer(isBundle) === true && evaluateInitializer(isLog) === false && evaluateInitializer(path2) === true, ReactiveValidators.required('Required'));
  }

  // Bundle - Bundle, Ranged
  static conditionalIsBundleAndRanged() {
    return conditionalValidatorParental((control: AbstractControl) => control.parent.getRawValue()?.isBundle === true && control.parent.getRawValue()?.woodType?.isRemoval === false && control.parent.getRawValue()?.isWidthRange === true, ReactiveValidators.required('Required'));
  }

  static initializerIsBundleAndRanged(isBundle: Initializer<boolean>, isRanged: Initializer<boolean>, isLog: Initializer<boolean> = () => false) {
    return conditionalValidator(() => evaluateInitializer(isBundle) === true && evaluateInitializer(isRanged) === true && evaluateInitializer(isLog) === false, ReactiveValidators.required('Required'));
  }

  static bundleRangeValidator(minControlName: string = 'bundleMinWidth', maxControlName: string = 'bundleMaxWidth') {
    return (formGroup: UntypedFormGroup) => {
      const startControl = formGroup.get(minControlName);
      const endControl = formGroup.get(maxControlName);
      if (startControl == null || endControl == null) {
        console.warn('Range validator could not find one or both controls required');
      }

      if (endControl.errors && !endControl.errors.range) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (formGroup.get('isWidthRange').value === true && startControl.value !== null && endControl.value !== null && startControl.value > endControl.value) {
        endControl.setErrors({ range: { message: 'Must be greater than or equal to min width' } });
      } else {
        endControl.setErrors(null);
      }
    }
  }

}

