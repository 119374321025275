import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';

import { BaseModalComponent } from '../core/base-modal-component';
import { OutletCloserService } from '../services/outlet-closer.service';

@Injectable({
  providedIn: 'root'
})
export class ModalRedirectGuard implements CanActivate, CanDeactivate<BaseModalComponent> {

  constructor(protected router: Router, private outletCloser: OutletCloserService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { redirect, outlet, value } = route.data;
    const outlets = {};
    outlets[`${outlet}`] = value;
    return this.router.navigate([`${redirect}`], { queryParams: route.queryParams }).then(() => {
      return this.router.navigate([{ outlets }], { replaceUrl: true, queryParams: route.queryParams, skipLocationChange: true })
    });
  }

  canDeactivate(
    component: BaseModalComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    component.close();
    return true;
  }

}
