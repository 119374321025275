<ion-modal [isOpen]="open" class="ulm-modal register-modal" [ngClass]="{ invite: this.model.companyId != null }" (didDismiss)="this.didDismiss()" #regModal>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          <span *ngIf="this.model?.companyId != null">Join Company</span>
          <span *ngIf="this.model?.companyId == null">Sign Up</span>
        </ion-title>
        <ion-buttons slot="primary">
          <ion-button tabindex="-1" type="button" (click)="close({ status: false })">
            <ion-icon name="close" color="primary"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <form (ngSubmit)="this.submit()" (keyup.enter)="this.submit()" [formGroup]="this.form">
        <ion-grid class="ion-padding">
          <ion-row>
            <ion-col>
              <div [hidden]="this.model.companyId != null">
                <div [ngClass]="{ invalid: this.form.get('permission').invalid && this.form.get('permission').touched }">
                  <ion-item>
                    <ion-checkbox color="secondary" slot="start" formControlName="permission"></ion-checkbox>
                    <ion-label class="ion-text-wrap small">I have permission to register this company and to act as company administrator on this account.</ion-label>
                    <ion-note slot="error"><arv-validation-messages for="permission"></arv-validation-messages></ion-note>
                  </ion-item>
                </div>
              </div>
              <div class="main-trial-form">
                <!-- <ion-item>
                  <ion-label>{{this.selectedPackage || "Select"}}</ion-label>
                  <ion-button>Change Package</ion-button>
                </ion-item> -->
                <ion-item>
                  <ion-label position="floating">First Name</ion-label>
                  <ion-input placeholder="First Name" type="text" formControlName="firstName"></ion-input>
                  <ion-note slot="error"><arv-validation-messages for="firstName"></arv-validation-messages></ion-note>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Last Name</ion-label>
                  <ion-input placeholder="Last Name" type="text" formControlName="lastName"> </ion-input>
                  <ion-note slot="error"><arv-validation-messages for="lastName"></arv-validation-messages></ion-note>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Company Name</ion-label>
                  <ion-input placeholder="Company Name" type="text" formControlName="company"></ion-input>
                  <ion-note slot="error"><arv-validation-messages for="company"></arv-validation-messages></ion-note>
                </ion-item>
                <ion-item>
                  <ion-label position="floating">Work Email</ion-label>
                  <ion-input placeholder="Work Email" type="email" formControlName="email"></ion-input>
                  <ion-note slot="error"><arv-validation-messages for="email"></arv-validation-messages></ion-note>
                </ion-item>
                <app-password-input>
                  <ion-label position="floating">Password</ion-label>
                  <ion-input type="password" placeholder="Password" formControlName="password"></ion-input>
                  <ion-note slot="error"><arv-validation-messages for="password"></arv-validation-messages></ion-note>
                </app-password-input>
                <!-- <ngx-recaptcha2 #captchaElem [siteKey]="reCaptchaSiteKey$ | async" [useGlobalDomain]="false" formControlName="recaptcha"></ngx-recaptcha2> -->
              </div>
              <div [ngClass]="{ invalid: this.form.get('policyConfirmed').invalid && this.form.get('policyConfirmed').touched }">
                <ion-item>
                  <ion-checkbox color="secondary" slot="start" formControlName="policyConfirmed"></ion-checkbox>
                  <ion-label class="ion-text-wrap small">
                    I have read and accept the <a target="_blank" [href]="this.systemSettings.tosLink$ | async">terms of service</a> and
                    <a target="_blank" [href]="this.systemSettings.ppLink$ | async">privacy policy</a>.
                  </ion-label>
                  <ion-note slot="error"><arv-validation-messages for="policyConfirmed"></arv-validation-messages></ion-note>
                </ion-item>
              </div>
              <div class="trial-button-container">
                <app-send-button type="submit" [sending]="this.isRegistering" (click)="this.submit()" color="primary" [disabled]="this.isRegistering" expand="block">Sign Up</app-send-button>
                <!-- <ion-button (click)="this.googleSignup(this.model.companyId);" fill="clear" expand="block" class="mb-2">
                  <ion-img src="assets/google/logo.png" class="google-logo"></ion-img>
                  <span>Or sign up with Google</span>
                </ion-button> -->
                <ion-note slot="helper" color="dark">Already have an account?</ion-note>
                <ion-button (click)="this.login()" expand="block" color="secondary">Login</ion-button>
                <!-- <ion-button (click)="this.googleLogin(this.model.companyId);" fill="outline" expand="block">
                  <ion-img src="assets/google/logo.png" class="google-logo"></ion-img>
                  <span>Sign in with Google</span>
                </ion-button> -->
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
  </ng-template>
</ion-modal>
