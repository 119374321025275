import { BaseEntity } from './baseEntity';
import { CompanyAccount } from './company-account';
import { PhoneType } from './enums/phone-type';
import { RlsCompanyPhone } from './relations/rls-company-phone';
import { RlsUserPhone } from './relations/rls-user-phone';
import { User } from './user';

export class Phone extends BaseEntity {

    // Properties
    reference: string | null;
    phoneType: PhoneType;
    number: string;
    isActive: boolean;

    // Relationships
    defaultForUserAccount: User;
    defaultForCompanyAccount: CompanyAccount;

    rlsCompanyPhone: RlsCompanyPhone[];
    rlsUserPhone: RlsUserPhone[];

    constructor() {
        super();
        this.isActive = true;
        this.number = '';
    }

}
