import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonModal, ModalController } from '@ionic/angular';
import { Validators as ReactiveValidators } from 'angular-reactive-validation';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

import { BaseModalComponent } from 'src/app/core/base-modal-component';
import { RegisterUserDto } from 'src/app/models/dto/register-user-dto';
import { RegisterForm } from 'src/app/models/register-form';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { IapSubscriptionPackageService } from 'src/app/services/iap-subscription-package.service';
import { OutletCloserService } from 'src/app/services/outlet-closer.service';
import { RegisterService } from 'src/app/services/register.service';
import { SubscriptionPackageService } from 'src/app/services/subscription-package.service';
import { SystemSettingService } from 'src/app/services/system-setting.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';
import { UserValidationService } from 'src/app/services/user.validation.service';
import { Utilities } from 'src/app/shared/util';

@Component({
  selector: 'app-registration-modal',
  templateUrl: './registration-modal.component.html',
  styleUrls: ['./registration-modal.component.scss']
})
export class RegistrationModalComponent extends BaseModalComponent implements OnInit {
  @Input() model: RegisterUserDto;
  @Input() autoLaunch: boolean = true;
  @Input() companyId: string;
  @ViewChild('regModal', { static: false }) regModal: IonModal;

  open: boolean;
  isRegistering: boolean = false;
  userEnabledTracking: boolean = false;

  //reCaptchaSiteKey$ = new BehaviorSubject<string | null>(null);

  form: FormGroup<RegisterForm>;

  ppLink: string;
  tosLink: string;

  constructor(
    private modalController: ModalController,
    private authService: AuthService,
    private registerService: RegisterService,
    private userService: UserService,
    private toast: ToasterService,
    private router: Router,
    protected route: ActivatedRoute,
    private companyService: CompanyService,
    private modal: ModalController,
    private outletCloser: OutletCloserService,
    public systemSettings: SystemSettingService,
    private iapService: IapSubscriptionPackageService,
    private subService: SubscriptionPackageService
  ) {
    super();
  }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.route.queryParamMap.pipe(take(1)).subscribe(async (queryParamMap) => {
      this.model ??= new RegisterUserDto();
      this.model.policyConfirmed = false;
      const selectedPacakgeId = queryParamMap.get('packageId');
      if (selectedPacakgeId != null) {
        // Setting for company registration
        void Utilities.storageSetItem('Register:SelectedPackageId', selectedPacakgeId);
      }
      this.systemSettings
        .getTOSLink()
        .then((res) => (this.tosLink = res))
        .catch(console.error);
      this.systemSettings
        .getPrivacyPolicyLink()
        .then((res) => (this.ppLink = res))
        .catch(console.error);

      this.model.companyId = queryParamMap.get('companyId');
      this.model.email = queryParamMap.get('email');
      this.form = this.initForm(this.model);
      if (this.autoLaunch === true) {
        void this.launch();
      }
      if (this.model.companyId != null) {
        try {
          const publicCompany = await this.companyService.getPublicInfo(this.model.companyId);
          this.form.patchValue({ company: publicCompany.name });
        } catch (err) {
          console.error(err);
        }
      }
    });

    //this.systemSettingsService.getReCaptchaSiteKey(environment.captchaSiteKey).then(res => this.reCaptchaSiteKey$.next(res)).catch(err => console.error(err));
  }

  ionViewWillEnter(): void {
    void this.launch();
  }

  ionViewWillLeave(): void {
    void this.regModal.dismiss({ status: false });
  }

  didDismiss(): void {
    void this.outletCloser.closeOutlet('modal');
  }

  async launch(model?: RegisterUserDto): Promise<IonModal> {
    if (model != null) {
      this.initForm(model);
    }
    void this.userService
      .getTrackingPermission()
      .then((res) => {
        this.userEnabledTracking = res;
      })
      .catch((err) => {
        console.error(err);
        this.userEnabledTracking = false;
      });
    if (this.regModal == null) {
      this.open = true;
      return;
    }
    await this.regModal.present();

    return this.regModal;
  }

  initForm(model?: RegisterUserDto): FormGroup<RegisterForm> {
    return new FormGroup<RegisterForm>({
      permission: new FormControl(model.companyId != null, {
        nonNullable: true,
        validators: [ReactiveValidators.requiredTrue('Required')]
      }),
      firstName: new FormControl(model?.firstName, {
        nonNullable: true,
        validators: [UserValidationService.nameValidator]
      }),
      lastName: new FormControl(model?.lastName, {
        nonNullable: true,
        validators: [UserValidationService.nameValidator]
      }),
      company: new FormControl(model.company),
      companyId: new FormControl(model.companyId),
      email: new FormControl(model.email, {
        nonNullable: true,
        validators: [
          UserValidationService.emailValidator(),
          ReactiveValidators.required('Required')
          // ReactiveValidators.maxLength(100, (max) => `Max length ${max}.`)
        ]
      }),
      password: new FormControl(model.password, {
        nonNullable: true,
        validators: [
          ReactiveValidators.required('Required'),
          UserValidationService.passwordValidator()
        ]
      }),
      policyConfirmed: new FormControl(model.policyConfirmed, {
        nonNullable: true,
        validators: [ReactiveValidators.requiredTrue('You must read & accept terms of service and privacy policy.')]
      })
      // recaptcha: ['', ReactiveValidators.required('Required')]
    });
  }

  close(data: { status: boolean }): void {
    void (this.regModal ?? this.modalController).dismiss(data);
  }

  async submit(): Promise<boolean> {
    this.isRegistering = true;
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      void this.toast.error('Form is invalid, check fields.');
      this.isRegistering = false;
      return;
    }

    const registerFormValue: RegisterUserDto = this.form.getRawValue();
    await Utilities.storageSetItem('Register:CompanyName', registerFormValue.company);
    try {
      const res = await this.registerService.registerUser(registerFormValue);
      await this.userService.setUser(res.result);
      await this.userService.updateTrackingPreference(this.userEnabledTracking, res.result?.id).catch((err) => {
        console.error(err);
      });
      // Verify Extisting Receipt with new User Account, handles purchase before registering
      if (this.iapService.isSupported) {
        this.iapService.store.applicationUsername = res.result.id;
        const currentTransactionId = await lastValueFrom(this.iapService.transactionId$.pipe(take(1)));
        if (currentTransactionId != null) {
          await this.subService.updateSubscriptionPostRegister(currentTransactionId, res.result.id).catch((err) => {
            console.error(err);
          });
          // The below method should work but am having trouble with it being consistent in testing
          // so I attempt to call it still as it should be the single source of truth but the call above
          // is a sort of preceding failsafe
          // class SKTransaction
          this.iapService.store.getApplicationUsername();
          await new Promise((resolve) => setTimeout(resolve, 1000));
          const currentTransaction = this.iapService.store.localTransactions.find((o) => (o as any).originalTransactionId == currentTransactionId);
          if (currentTransaction != null) {
            await currentTransaction.verify().catch((err) => console.error(err));
          } else {
            console.error('Transaction not found in local store');
          }
        }
      }
      void this.toast.presentToast(res.message);
      return this.router.navigateByUrl('/registration-success');
    } catch (err) {
      console.error(err);
      void this.toast.error(err.message || 'There was an error registering this user.');
    } finally {
      this.isRegistering = false;
    }
  }

  async login(): Promise<void> {
    setTimeout(() => {
      this.close({ status: false });
    }, 0);
    return await this.authService.login().catch((err) => this.toast.error(err));
  }

  // googleLogin(companyId?: string) {
  //   this.authService.signIn({ acr_values: 'idp:Google', companyId: companyId });
  // }

  // //https://github.com/wi3land/ionic-appauth/issues/16
  // googleSignup(companyId?: string) {
  //   this.authService.signIn({ acr_values: 'idp:Google', companyId: companyId });
  // }
}
