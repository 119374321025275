import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  template: ''
})
export abstract class BaseListComponent<T> implements OnInit, OnChanges, AfterViewInit {

  @Input() data: T[];
  @Input() isLoading: boolean;
  abstract displayColumns: string[];

  hasData: boolean = false;

  dataSource: MatTableDataSource<T>;

  @ViewChild(MatSort) matSort?: MatSort;
  @ViewChild(MatPaginator) matPaginator?: MatPaginator;

  constructor() { }

  ngOnInit(): void {
    this.hasData = this.data != null && this.data.length > 0;
    this.dataSource = new MatTableDataSource(this.data || []);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataSource == null) {
      return;
    }
    const arr = changes.data?.currentValue || this.data;
    this.dataSource.data = arr;
    this.hasData = arr != null && arr.length > 0;
  }

  ngAfterViewInit(): void {
    if (this.dataSource != null && this.matSort != null) {
      this.dataSource.sort = this.matSort;
    }
    if (this.matPaginator != null && this.dataSource != null) {
      this.dataSource.paginator = this.matPaginator;
    }
  }
}
