import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { QuicklinkModule } from 'ngx-quicklink';

import { AddressAutocompleteComponent } from '../components/address-autocomplete/address-autocomplete.component';
import { AutocompleteComponent } from '../components/autocomplete/autocomplete.component';
import { CoreModule } from '../core/core.module';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    ScrollingModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatAutocompleteModule,
    GooglePlaceModule,

    QuicklinkModule
  ],
  declarations: [
    AddressAutocompleteComponent,
    AutocompleteComponent,
  ],
  exports: [
    MatAutocompleteModule,
    AutocompleteComponent,
    AddressAutocompleteComponent,

    QuicklinkModule
  ],
  providers: [],
})
export class SharedAutocompleteModule { }
