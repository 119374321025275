import { Injectable } from '@angular/core';
import { Router, UrlSegmentGroup } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OutletCloserService {

  constructor(private readonly router: Router) { }

  closeOutlet(outletName: string): Promise<boolean> {
    const currentUrlTree = this.router.parseUrl(
      this.router.routerState.snapshot.url
    );
    const newRootSegment = this.recursivelyClearOutlet(
      currentUrlTree.root,
      outletName
    );
    currentUrlTree.root = newRootSegment;
    return this.router.navigateByUrl(currentUrlTree);
  }

  recursivelyClearOutlet(
    root: UrlSegmentGroup,
    outletName: string
  ): UrlSegmentGroup {
    const newChildren = {};
    for (const [childOutlet, child] of Object.entries(root.children)) {
      if (childOutlet !== outletName) {
        const newChild = this.recursivelyClearOutlet(child, outletName);
        newChildren[childOutlet] = newChild;
      }
    }
    return new UrlSegmentGroup(root.segments, newChildren);
  }
}
