import { Injectable } from '@angular/core';
import { AdvertisingId } from '@capacitor-community/advertising-id';
import { Capacitor } from '@capacitor/core';
import { isString, isUndefined, omitBy } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { classToPlain, plainToClass } from 'class-transformer';
import { Address } from 'src/app/models/address';
import { Phone } from 'src/app/models/phone';

import { UserAdvertisingUpdateDto } from '../models/dto/UserAdvertisingUpdateDto';
import { User } from '../models/user';
import { Utilities } from '../shared/util';
import { ApiService } from './api.service';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: BehaviorSubject<User> = new BehaviorSubject(null);
  user$: Observable<User> = this.user;
  constructor(
    private api: ApiService,
    private companyService: CompanyService) {
    this.initUser().then(user => {
      this.user.next(user);
    });
  }

  /**
   * @deprecated
   * @returns
   */
  getUser(): User {
    return plainToClass(User, this.user.getValue(), { enableImplicitConversion: true });
  }

  getUser$(): Promise<User> {
    return this.user$.pipe(take(1)).toPromise().then(res => plainToClass(User, res, { enableImplicitConversion: true }));
  }

  async initUser(): Promise<User> {
    const u = await Utilities.storageGetItem('user');
    if (u == null) {
      return null;
    }
    return plainToClass(User, u, { enableImplicitConversion: true });
  }

  setUser(user: User): Promise<void> {
    this.user.next(user);
    return Utilities.storageSetItem('user', user);
  }

  // TODO Return error from this endpoint, not being handled properly on 401
  getUserForForm(): Promise<User> {
    return this.api.get('Account/GetUser')
      .pipe(map(i => plainToClass(User, i, { enableImplicitConversion: true })))
      .toPromise()
      .then((user: User) => {
        if (user == null) return null;
        const userAddresses: Address[] = [];
        user.userAddresses.forEach((address) => {
          userAddresses.push(plainToClass(Address, address));
        });

        const userPhones: Phone[] = [];
        user.userPhones.forEach((phone) => {
          userPhones.push(plainToClass(Phone, phone));
        });

        user.userAddresses = userAddresses;
        user.userPhones = userPhones;
        return user;
      }).catch(err => {
        console.error(err);
        return Promise.reject(err);
      });
  }

  getLoggedInUser(): Promise<User> {
    return this.getUserForForm().then(async user => {
      await this.setUser(user);
      const companyAccountId = (user?.companyAccount && user.companyAccount.id) || user?.companyAccountId;
      if (companyAccountId != null) {
        return this.companyService.getForForm(companyAccountId).then(async companyAccount => {
          await this.companyService.setCompany(companyAccount);
          return user;
        }).catch(async err => {
          await this.companyService.setCompany(null);
          return user;
        });
      } else {
        await this.companyService.setCompany(null);
      }
      return user;
    });
  }

  /**
   *
   * @param token
   * @param fallback
   * @returns
   */
  getRolesOnJWT(token: string, fallback: string[]) {
    try {
      const tokenValue = JSON.parse(atob(token.split('.')[1]));
      if (tokenValue?.role == null) {
        throw new Error('No roles');
      } else if (isString(tokenValue?.role)) {
        return [tokenValue.role]
      }
      return tokenValue.role;
    } catch (err) {
      console.error(err);
      return fallback || [];
    }
  }

  getCompanyIdOnJWT(token: string, fallback = null) {
    const tokenValue = JSON.parse(atob(token.split('.')[1]));
    return Number.parseInt(tokenValue.company_id) || null;
  }

  get(email: string): Promise<User> {
    return this.api.get(`Account/SearchUser/${email}`)
      .pipe(map(res => plainToClass(User, res)))
      .toPromise();
  }

  getById(id: number): Promise<User> {
    return this.api.get(`UserAccount/${id}`)
      .pipe(map(res => plainToClass(User, res)))
      .toPromise();
  }

  getByUUID(uuid: string): Promise<User> {
    return this.api.get(`Account/ByUUID/${uuid}`)
      .pipe(map(res => plainToClass(User, res)))
      .toPromise();
  }

  getByFilter(): Promise<User[]> {
    throw new Error('Method not implemented.');
  }

  autocomplete(filter: string, companyId: number | null): Promise<User[]> {
    const queryParams = { filter, companyId }
    return this.api.get(`UserAccount/Autocomplete`, omitBy(queryParams, isUndefined))
      .pipe(map(res => res.map(r => plainToClass(User, r))))
      .toPromise();
  }

  put(user: User): Observable<User> {
    return this.api.put(`Account/${user.id}`, classToPlain(user))
      .pipe(map(res => plainToClass(User, res)));
  }

  delete(user: User): Promise<void | any> {
    return this.api.delete(`Account/DeleteAccount/${user.id}`).toPromise();
  }

  resendEmailVerification(email: string): Promise<string> {
    return this.api.post(`Account/Resend/ConfirmEmail`, { email }).toPromise().then(res => res?.message);
  }

  async getTrackingPermission(): Promise<boolean> {
    if (Capacitor.getPlatform() === 'web') { return true; }
    let { status } = await AdvertisingId.getAdvertisingStatus();
    if (status === 'Not Determined') {
      const res = await AdvertisingId.requestTracking();
      status = res.value;
    }
    if (status === 'Authorized') {
      return true;
    }
    // Not sure how I feel about the below as its causes a recursive cycle...
    // else if (status === 'Not Determined') {
    //   return this.getTrackingPermission();
    // }
    return false;
  }

  async updateTrackingPreference(status: boolean, userId: string = null, platform: string = Capacitor.getPlatform()): Promise<any> {
    const req: UserAdvertisingUpdateDto = { canTrack: status, userId, platformId: platform };
    return this.api.put('Account/UpdateTrackingPreference', req).toPromise();
  }
}
