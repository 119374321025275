import { BaseEntity } from './baseEntity';
import { TreeSpeciesType } from './enums/tree-species-type';
import { IOtherable } from './interfaces/IOtherable';

export class TreeSpecies extends BaseEntity implements IOtherable {
    constructor() {
        super();
        this.isActive = true;
        this.isOther = false;
    }
    speciesType: TreeSpeciesType;
    commonName: string;
    scientificName: string;
    carbonSequesteredValue?: number;
    isActive: boolean;
    isOther: boolean;
}
