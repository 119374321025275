import { Injectable } from '@angular/core';
import { IonicSafeString, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastController: ToastController) { }

  async presentToast(message: string | IonicSafeString, duration = 2000): Promise<void> {
    const toast = await this.toastController.create({
      message,
      duration,
      cssClass: 'toast-message'
    });
    return toast.present();
  }

  async error(message: string | IonicSafeString, duration = 3500): Promise<void> {
    // TODO this needs to be large as obtrusive may be even only user dismissable
    // unless specified

    const toast = await this.toastController.create({
      message,
      duration,
      cssClass: 'toast-error'
    });
    return toast.present();
  }
}
