import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';
import { MAT_SELECT_SCROLL_STRATEGY_PROVIDER } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ReactiveValidationModule } from 'angular-reactive-validation';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule
} from 'ngx-cookieconsent';
import { QuicklinkModule } from 'ngx-quicklink';
import 'reflect-metadata';

import 'moment';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { NavModule } from './components/nav/nav.module';
import { BlobErrorHttpInterceptor } from './interceptors/blob-error.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { DashboardPageModule } from './pages/dashboard/dashboard.module';
import { GettingStartedPageModule } from './pages/getting-started/getting-started.module';
import { WelcomePageModule } from './pages/welcome/welcome.module';
import { FormatPipe } from './pipes/format.pipe';
import { AuthService } from './services/auth.service';

const cookieConfig: NgcCookieConsentConfig = {
  autoOpen: false,
  cookie: {
    domain: environment.cookieDomain
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#277247',
      text: '#ffffff'
    },
    button: {
      background: '#ffffff',
      text: '#277247'
    }
  },
  type: 'info',
  content: {
    message: 'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'Got it!',
    deny: 'Refuse Cookies',
    link: 'Learn more',
    href: 'https://cookiesandyou.com',
    policy: 'Cookie Policy'
  }
};

// Components

@NgModule({
  declarations: [AppComponent, FormatPipe],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    ReactiveValidationModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NavModule,
    AuthModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    QuicklinkModule,

    GettingStartedPageModule,
    WelcomePageModule,
    DashboardPageModule,
  ],
  providers: [
    AuthService,
    NativeGeocoder,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    // For Modal's with FormComponents to work, TODO figure out a way to remove from here
    FormGroupDirective,
    MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
    MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
