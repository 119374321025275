<ion-content [forceOverscroll]="false">
  <ng-material-multilevel-menu #menu [configuration]="this.config" [items]="this.navigationItems">
    <ng-template #listTemplate let-item="item" let-configuration="configuration">
      <div class="item-container" *ngIf="this.expanded || (!this.expanded && item.icon)">
        <ion-menu-toggle *ngIf="item.hasChilden === false" [autoHide]="true" menu="main-menu" class="menu-item" [dir]="configuration.rtlLayout ? 'rtl' : 'ltr'">
          <ng-container *ngTemplateOutlet="this.linkTemplate; context: { item: item, configuration: configuration }"> </ng-container>
        </ion-menu-toggle>
        <div *ngIf="item.hasChilden === true" class="menu-item" [dir]="configuration.rtlLayout ? 'rtl' : 'ltr'">
          <ng-container *ngTemplateOutlet="this.linkTemplate; context: { item: item, configuration: configuration }"> </ng-container>
        </div>
      </div>
    </ng-template>
  </ng-material-multilevel-menu>
</ion-content>
<ion-footer [ngClass]="{ expanded: this.expanded }">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <div class="mt-2 flex ion-justify-content-center">
          <a class="flex mr-2 legal-link" [href]="this.systemSettings.tosLink$ | async" target="_blank">Terms of Use</a>
          | <a class="flex ml-2 legal-link" [href]="this.systemSettings.ppLink$ | async" target="_blank">Privacy Policy</a>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>

<ng-template let-item="item" let-configuration="configuration" #linkTemplate>
  <!-- event stopPropogation to prevent calling a transition to a route twice... -->
  <div class="title">
    <div class="link-container">
      <a
        *ngIf="item.link != null"
        class="w-100"
        [routerLink]="item.link"
        (click)="$event.stopPropagation()"
        routerLinkActive="selected-item"
        [routerLinkActiveOptions]="item.activeOptions || this.routerMatchOpts"
        [queryParams]="item.navigationExtras?.queryParams"
      >
        <div class="icon-container" [class.expanded]="this.expanded">
          <ion-icon class="menu-icon" [name]="item.isSelected ? item.activeIcon : item.icon"></ion-icon>
        </div>
        <div class="label-container">
          <span class="menu-label" [class.hidden]="!this.expanded">{{ item.label }}</span>
        </div>
      </a>
      <div class="menu-link" *ngIf="item.link == null">
        <div class="icon-container" [class.expanded]="this.expanded">
          <ion-icon class="menu-icon" [name]="item.isSelected ? item.activeIcon : item.icon"></ion-icon>
        </div>
        <div class="label-arrow-container">
          <div class="label-container">
            <span class="menu-label" [class.hidden]="!this.expanded" [class.expanded]="item.expanded">
              {{ item.label }}
            </span>
          </div>
          <div [ngClass]="item.expanded ? 'icon-arrow-container-expanded' : 'icon-arrow-container'" *ngIf="item.hasChilden && this.expanded">
            <mat-icon *ngIf="!configuration.rtlLayout" [@ExpandedLTR]="item.expanded ? 'yes' : 'no'"> keyboard_arrow_down </mat-icon>
            <mat-icon *ngIf="configuration.rtlLayout" [@ExpandedRTL]="item.expanded ? 'yes' : 'no'"> keyboard_arrow_down </mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
