import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { plainToClass } from 'class-transformer';

import { Notification } from '../models/notifications/notification';
import { RlsUserNotification } from '../models/notifications/rls-user-notification';
import { ApiService } from './api.service';
import { BaseEntityService } from './base-entity.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  endpoint = 'Notification';

  constructor(private api: ApiService) { }

  userNotifications = new BehaviorSubject<RlsUserNotification[]>([]);
  userNotifications$: Observable<RlsUserNotification[]> = this.userNotifications;;


  getUsersNotifications(): Promise<RlsUserNotification[]> {
    return this.api.get(`${this.endpoint}/User`)
      .pipe(catchError(err => {
        console.error(err);
        return of([]);
      }),
        map(o => o?.map(v => plainToClass(RlsUserNotification, v))), tap(v => this.userNotifications?.next(v))).toPromise();
  }

  getUsersUnreadNotifications() {
    return this.api.get(`${this.endpoint}/User/Unread`)
      .pipe(tap(v => this.userNotifications?.next(v))).toPromise();
  }

  async markRead(notification: RlsUserNotification, status: boolean = true) {
    const arr = await this.userNotifications$.pipe(take(1)).toPromise();
    const value = arr.find(v => v.id == notification.id)
    value.isRead = status;
    this.userNotifications.next(arr);
    return this.api.put(`${this.endpoint}/MarkAsRead`, null, { id: notification.id, status }).toPromise().catch(err => {
      value.isRead = !status;
      this.userNotifications.next(arr);
    });
  }

  get(): Observable<(Notification | RlsUserNotification)[]> | Promise<(Notification | RlsUserNotification)[]> {
    throw new Error('Method not implemented.');
  }
  getById(id: number): Observable<Notification | RlsUserNotification> | Promise<Notification | RlsUserNotification> {
    throw new Error('Method not implemented.');
  }
  getByUUID(uuid: string): Observable<Notification | RlsUserNotification> | Promise<Notification | RlsUserNotification> {
    throw new Error('Method not implemented.');
  }
  post(model: Notification | RlsUserNotification): Observable<Notification | RlsUserNotification> | Promise<Notification | RlsUserNotification> {
    throw new Error('Method not implemented.');
  }
  put(model: Notification | RlsUserNotification): Observable<Notification | RlsUserNotification> | Promise<Notification | RlsUserNotification> {
    throw new Error('Method not implemented.');
  }

  async delete(model: RlsUserNotification): Promise<any> {
    const arr = await this.userNotifications$.pipe(take(1)).toPromise();
    const index = arr.findIndex(v => v.id == model.id)
    arr.splice(index, 1);
    this.userNotifications.next(arr);
    return this.api.delete(`${this.endpoint}`, { notificationId: model?.id }).toPromise().catch(err => {
      arr.splice(index, 0, model);
      this.userNotifications.next(arr);
      throw err;
    });
  }


}
