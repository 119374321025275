import { Injectable } from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup
} from '@angular/forms';
import { Params } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { isEmpty, sum } from 'lodash-es';

import { BaseEntity } from '../models/baseEntity';

@Injectable({
    providedIn: 'root'
})
export class Utilities {

    // Ionic Select
    comparisonFunc(value: any, option: any): boolean {
        if (value === null && !option) { // HACK null comes in as '' for some reason with ion-select
            return true;
        }
        return value === option;
    }

    objectComparisonFunction(value: any, option: any): boolean {
        return (option && option.id) === (value && value.id);
    }

    baseEntityComparisonFunction(value: BaseEntity, option: BaseEntity): boolean {
        return (option && option.uuid) === (value && value.uuid);
    }

    optionEntityObjectComparison(value: BaseEntity, option: any): boolean {
        if (option === (value && value.id) || option === (value && value.uuid)) {
            return true;
        }
        return (option && option.id) === (value && value.id) || (option && option.uuid) === (value && value.uuid);
    }

    trackByBaseEntity(be: BaseEntity) {
        return be?.uuid;
    }

    focusInputSelect(target: Partial<HTMLInputElement>) {
        target?.select();
    }

    focusIonInputSelect(target: Partial<HTMLInputElement>) {
        ((target.children[0]) as HTMLInputElement)?.select();
    }

    FA(control: AbstractControl): UntypedFormArray {
        return control as UntypedFormArray;
    }

    FC(control: AbstractControl): UntypedFormControl {
        return control as UntypedFormControl;
    }

    FG(control: AbstractControl): UntypedFormGroup {
        return control as UntypedFormGroup;
    }

    updateValueAndValidity(controls: any, obj: AbstractControl) {
        Object.keys(controls)?.forEach(k => {
            obj?.get(k)?.updateValueAndValidity();
        });
    }

    patchByBaseEntity(formArray: UntypedFormArray, value: BaseEntity[], emitEvent: boolean = false) {
        formArray.controls.forEach(c => {
            const found = value?.find(rls => rls.uuid == c.value?.uuid);
            if (found != null) {
                c.patchValue(found, { emitEvent });
            }
        });
    }

    static storageGetGroup(): string {
        return 'CapacitorStorage'; // Default Group Key, can change with configure call
    }

    static async storageGetItem(key: string): Promise<any> {
        return Preferences.get({ key }).then(res => safeJSONParse(res?.value));
    }

    static async storageSetItem(key: string, value: any): Promise<any> {
        if (value != null) {
            value = safeJSONStringify(value);
        }
        return Preferences.set({ key, value });
    }

    static async storageRemoveItem(key: string): Promise<any> {
        return Preferences.remove({ key });
    }

    static partition(array, predicate) {
        return array.reduce((acc, item) => predicate(item)
            ? (acc[0].push(item), acc)
            : (acc[1].push(item), acc), [[], []]);
    }

    static canSubmitFromEnter(target: any) {
        if (target == null || target?.nodeName === 'TEXTAREA' || (target?.nodeName === 'BUTTON' && target?.type != 'submit')) {
            return false;
        }
        return true;
    }

    static average(arr: number[]) {
        return sum(arr) / arr.length;
    }

    /**
 * Get a query map based on a query string.
 *
 * The function will populate a map variable with key value pairs of the parameters.
 *
 * If there is more than one of the same key, the function will populate an array in the map with the multiple values within it
 *
 * @param  {string} query The query string - the question mark is optional
 * @return {object}       key value pairs of the parameter / value of the parameter
 */
    static getQueryMap(query: string): Params {

        if (query == null || isEmpty(query)) {
            return {};
        }
        if (typeof query !== 'string') {
            return null;
        }

        var toType = function (a) {
            return ({}).toString.call(a).match(/\s([a-zA-Z]+)/)[1].toLowerCase();
        }, map = {};

        // map the hit query into a proper object
        query.replace(/([^&|\?=]+)=?([^&]*)(?:&+|$)/g, (match, key, value) => {
            if (key in map) {
                // the key already exists, so we need to check if it is an array, if not, make it an array and add the new value
                if (toType(map[key]) !== 'array') {
                    // it's not an array - make it an array
                    map[key] = [map[key]];
                }
                // push the new value into the array
                map[key].push(value);
            } else {
                // put the value into the map
                map[key] = value;
            }
            return "";
        });
        return map;
    }
}

export function safeJSONParse(value) {
    try {
        return JSON.parse(value);
    } catch (err) {
        console.error(err);
        return null;
    }
}

export function safeJSONStringify(value) {
    try {
        return JSON.stringify(value);
    } catch (err) {
        console.error(err);
        return null;
    }
}


