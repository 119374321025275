<ion-header>
  <ion-toolbar>
    <ion-title>
      <a class="title-link" [routerLink]="this.isNative == true ? '/mobile' : '/'">
        <div class="logo">
          <ion-img *ngIf="company == null || this.loggedIn != true" src="assets/images/app-logo-long-light.png"> </ion-img>
          <div [ngClass]="{ 'ion-align-self-center': this.company?.logo === null }" *ngIf="company != null && this.loggedIn === true">
            <app-image *ngIf="this.company?.logo != null" [allowPreview]="false" [image]="this.company?.logo"> </app-image>
            <ion-text class="ion-align-self-center ion-text-nowrap" *ngIf="this.company?.logo == null">{{ company.name }}</ion-text>
          </div>
        </div>
      </a>
    </ion-title>

    <ion-buttons slot="start" class="startButtons">
      <ion-menu-button autoHide="false" (click)="this.handleMenuClick()" class="menuButton"></ion-menu-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button
        *ngIf="isCompanyUser || this.isCompanyAdmin || this.isManagerOrHigher"
        class="carbon"
        (click)="launchCarbonDialog($event)"
        #tooltip="matTooltip"
        matTooltipPosition="below"
        matTooltipClass="csv-tooltip"
        [matTooltip]="
          (this.company?.measurementSystem?.isMetric === true ? 'Kilograms' : 'Pounds') +
          ' of Carbon Stored: ' +
          ((this.isCompanyUser === true || this.isCompanyAdmin === true ? this.companyService.csv$ : this.managerService.totalCSV$) | async | uomWeight: this.company?.measurementSystem)
        "
      >
        <ion-spinner *ngIf="this.loadingCarbon == true" color="secondary"></ion-spinner>
        <ion-icon *ngIf="this.loadingCarbon == false" class="carbonIcon" src="assets/icon/CO2-Icon-Outline-Brand-Dark-Brown.svg"></ion-icon>
      </ion-button>
      <ion-item [hidden]="this.isUSRWCertified === false" detail="false" lines="none" class="usrw-item"
       #usrwTooltip="matTooltip"
        matTooltipPosition="below"
        matTooltipClass="usrw-tooltip"
        matTooltip="USRW Certified">
          <ion-img class="usrw-image" src="assets/images/usrw-icon-logo.png" alt="USRW Cert"></ion-img>
      </ion-item>
      <ion-item
        class="profile-item"
        button
        detail="false"
        lines="none"
        (click)="profilePopover($event)"
        *ngIf="user != null && this.loggedIn === true"
        [ngClass]="{ 'badge-button': this.unreadNotifications?.length > 0 }"
      >
        <ion-avatar *ngIf="this.user?.profilePicture != null">
          <img class="profile-image" *ngIf="this.user?.profilePicture != null" alt="" [src]="this.attachmentService.baseUrl + this.user?.profilePicture.path" />
        </ion-avatar>
        <span *ngIf="this.user?.profilePicture == null" class="icon-avatar">
          <ion-icon slot="icon-only" name="person-circle-outline"></ion-icon>
        </span>
        <ion-badge slot="end" class="unread-badge" [ngClass]="{ 'icon-badge': this.user?.profilePicture == null }" *ngIf="this.unreadNotifications.length > 0">
          {{ this.unreadNotifications?.length > 9 ? '9+' : this.unreadNotifications?.length }}
        </ion-badge>
      </ion-item>
      <ion-button *ngIf="this.user == null && this.loggedIn === true" (click)="this.logout()">Logout</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
