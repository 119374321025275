<ion-item>
   <ng-content></ng-content>
   <ion-buttons class="show-container" slot="end">
      <ion-button type="button" (click)="toggleShow()">
         <ion-icon *ngIf="showPassword === false" slot="icon-only" class="show-option" color="secondary"
            name="eye-off-outline"></ion-icon>
         <ion-icon *ngIf="showPassword === true" slot="icon-only" class="hide-option" color="secondary"
            name="eye-outline"></ion-icon>
      </ion-button>
   </ion-buttons>
</ion-item>
