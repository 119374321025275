import { BaseEntity } from './baseEntity';

export class ReasonForRemoval extends BaseEntity {

    constructor() {
        super();
        this.isActive = true;
        this.isOther = false;
    }

    reference: string;
    description?: string;

    isActive: boolean;
    isOther: boolean;

    displayOrder?: number;
    cocPoints?: number;
}
