import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { AddRemoveTableComponent } from '../components/add-remove-table/add-remove-table.component';
import { ListTableComponent } from '../components/list-table/list-table.component';
import { CoreModule } from '../core/core.module';
import { SharedLoadingModule } from './loading.module';


@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    RouterModule,
    SharedLoadingModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    AddRemoveTableComponent,
    ListTableComponent
  ],
  exports: [
    AddRemoveTableComponent,
    ListTableComponent,

    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatButtonModule,
    RouterModule,
  ],
  providers: [],
})
export class SharedTableModule { }
