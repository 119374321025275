import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Capacitor } from '@capacitor/core';

import 'cordova-plugin-purchase/www/store.d';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { IapSubscriptionPackageService } from 'src/app/services/iap-subscription-package.service';
import { SystemSettingService } from 'src/app/services/system-setting.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.page.html',
  styleUrls: ['./getting-started.page.scss'],
})
export class GettingStartedPage implements OnInit {

  email = "mailto: " + environment.email;
  tosLink: string;
  ppLink: string;
  isMobile = Capacitor.isNativePlatform();

  queryParams: Params;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private alert: AlertService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private authService: AuthService,
    private iapService: IapSubscriptionPackageService,
    public systemSettings: SystemSettingService,
    private toast: ToasterService
  ) { }

  ngOnInit() { }

  ionViewWillEnter() {
    this.systemSettings.getTOSLink().then(res => this.tosLink = res);
    this.systemSettings.getPrivacyPolicyLink().then(res => this.ppLink = res);
    this.systemSettings.getSupportEmail(environment.email).then(res => this.email = `mailto:${res}`);
    this.queryParams = this.route.snapshot.queryParams;
    this.renderer.addClass(this.document.querySelector('html'), 'no-app-header');
    this.renderer.addClass(this.document.querySelector('html'), 'no-app-sidemenu');
  }

  ionViewWillLeave() {
    this.renderer.removeClass(this.document.querySelector('html'), 'no-app-header');
    this.renderer.removeClass(this.document.querySelector('html'), 'no-app-sidemenu');
  }

  async login() {
    return this.authService.login().catch(err => this.toast.error(err));
  }

  async manage() {
    return this.iapService.manageSubscription();
  }

}
