<ion-content>
  <div class="p-3">
    <h3 class="flex">
      <span *ngIf="this.company?.measurementSystem?.isImperial">Pounds of Carbon Stored</span>
      <span *ngIf="this.company?.measurementSystem?.isMetric">Kilograms of Carbon Stored</span>
      <span *ngIf="this.company === null">Pounds of Carbon Stored</span>

      <span class="spacer"></span>
      <button mat-icon-button (click)="this.dismissPopover();" class="ml-3 close-icon">
        <mat-icon>close</mat-icon>
      </button>
    </h3>

    <p *ngIf="isCompanyUser">{{company?.name || "Company"}}:
      {{this.companyService.csv$ | async | uomWeight:company?.measurementSystem}}
    </p>
    <p *ngIf="isManagerOrHigher">AncesTREE:
      {{this.managerService.totalCSV$ | async | uomWeight:company?.measurementSystem}}
    </p>

  </div>
</ion-content>
