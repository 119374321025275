<ion-content [fullscreen]="true">
  <div class="container">
    <ion-img src="assets/images/app-logo-light.png" class="logo"></ion-img>
    <p class="mt-3" *ngIf="!isLoggedIn">Get started now!</p>
    <div class="mt-6">
      <app-send-button class="mr-3" [sending]="this.loading" (click)="logIn()">Log In</app-send-button>
      <ion-button color="secondary" fill="outline" [routerLink]="['/', { outlets: { 'modal': 'signup'}}]">Register</ion-button>
    </div>
    <!-- <div>
      <ion-button class="ml-3" (click)="this.googleLogin();">
        <ion-icon name="logo-google"></ion-icon>
        <span class="pl-2">Sign in with Google</span>
      </ion-button>
    </div> -->
  </div>
</ion-content>
