import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipModule
} from '@angular/material/tooltip';
import { IonicModule } from '@ionic/angular';
import { NgArrayPipesModule, NgDatePipesModule } from 'ngx-pipes';
import { QuicklinkModule } from 'ngx-quicklink';

import { ItemListComponent } from '../components/item-list/item-list.component';
import { RequestTicketsListComponent } from '../components/request-tickets-list/request-tickets-list.component';
import { CoreModule } from '../core/core.module';
import { SharedLoadingModule } from './loading.module';
import { myCustomTooltipDefaults } from './shared-form.module';
import { SharedTableModule } from './shared-table.module';
import { SharedModule } from './shared.module';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    MatTooltipModule,
    SharedTableModule,
    SharedLoadingModule,
    SharedModule,
    IonicModule,
    NgArrayPipesModule,
    NgDatePipesModule,
    QuicklinkModule
  ],
  declarations: [
    ItemListComponent,
    RequestTicketsListComponent,
  ],
  exports: [
    ItemListComponent,
    RequestTicketsListComponent,

    MatTooltipModule,
    SharedTableModule,
    SharedLoadingModule,
    NgArrayPipesModule,
    NgDatePipesModule,

    QuicklinkModule
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
  ],
})
export class SharedListModule { }
