import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { plainToClass } from 'class-transformer';

import { Address } from '../models/address';
import { CompanyAccount } from '../models/company-account';
import { RlsCompanyUserSubscriptionDto } from '../models/dto/subscription-package-dto';
import { RlsCompanyAddress } from '../models/relations/rls-company-address';
import { RlsUserCompany } from '../models/relations/rls-user-company';
import { Utilities } from '../shared/util';
import { AlertService } from './alert.service';
import { ApiService } from './api.service';
import { TitleService } from './title.service';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  endpoint: string = 'CompanyAccounts';

  csv: BehaviorSubject<number> = new BehaviorSubject(null);
  csv$ = this.csv;

  company: BehaviorSubject<CompanyAccount> = new BehaviorSubject(null);
  company$: Observable<CompanyAccount> = this.company;

  currentSub: BehaviorSubject<RlsCompanyUserSubscriptionDto> = new BehaviorSubject(null);
  currentSub$ = this.currentSub;

  constructor(
    private api: ApiService,
    private toaster: ToasterService,
    private router: Router,
    private alert: AlertService,
    private alertController: AlertController,
    private titleService: TitleService,
  ) {
    this.initCompany().then(company => {
      this.company.next(company);
    });
  }

  getById(id: number): Promise<CompanyAccount> {
    return this.api.get(`${this.endpoint}/${id}`).toPromise()
      .then(res => plainToClass(CompanyAccount, res, { enableImplicitConversion: true }));
  }

  getByUuid(uuid: string): Promise<CompanyAccount> {
    return this.api.get(`${this.endpoint}/ByUUID/${uuid}`).toPromise();
  }

  getPublicInfo(uuid: string): Promise<CompanyAccount> {
    return this.api.get(`${this.endpoint}/Public`, { uuid }).toPromise();
  }

  getForForm(id: number): Promise<CompanyAccount> {
    return this.api.get(`${this.endpoint}/FormFetch/${id}`).toPromise()
      .then(res => plainToClass(CompanyAccount, res));
  }

  setCompany(company: CompanyAccount) {
    if (company != null) {
      this.titleService.displayCompany.next(true);
    } else {
      this.titleService.displayCompany.next(false);
    }
    this.company.next(company);
    return Utilities.storageSetItem('company', company);
  }

  setSubscription(rls: RlsCompanyUserSubscriptionDto) {
    this.currentSub.next(rls);
  }


  getCompany$(): Promise<CompanyAccount> {
    return this.company$.pipe(take(1)).toPromise()
      .then(res => plainToClass(CompanyAccount, res, { enableImplicitConversion: true }));
  }

  checkApprovalStatus(id: number): Promise<boolean> {
    return this.api.get(`${this.endpoint}/GetApprovalStatus/${id}`)
      .toPromise();
  }

  async initCompany(): Promise<CompanyAccount> {
    const company = await Utilities.storageGetItem('company');
    if (company == null) {
      return null;
    }
    return plainToClass(CompanyAccount, company, { enableImplicitConversion: true });
  }

  put(company: CompanyAccount) {
    return this.api.put(`${this.endpoint}/${company.id}`, company)
      .pipe(map(res => {
        return { message: res.message, result: plainToClass(CompanyAccount, res.result) };
      }));
  }

  removeUser(rls: RlsUserCompany) {
    return this.api.delete(`${this.endpoint}/RemoveUser/${rls.id}`).toPromise();
  }

  // TODO Refactor this so present toast is only inside the controller
  addUser(userEmail): Promise<RlsUserCompany> {
    if (!userEmail) {
      return Promise.reject(new Error('Please enter an Email'));
    }
    if (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(userEmail) === false) {
      return Promise.reject(new Error('Please enter a valid Email'));
    }
    return this.api.post(`${this.endpoint}/AddUser/${userEmail}`, {}).toPromise();
  }

  // https://localhost:44373/api/Address/CompanyAddress/1004
  addAddress(companyId: number, address: Address): Promise<RlsCompanyAddress> {
    return this.api.post(`Address/CompanyAddress/${companyId}`, address)
      .pipe(map(res => plainToClass(RlsCompanyAddress, res)))
      .toPromise();
  }

  async inviteUser(userEmail: string, company: CompanyAccount) {
    return this.api.post(`${this.endpoint}/InviteUser/${company.id}/${userEmail}`, {}).toPromise();
  }

  async alertInvite(email) {
    const company = await this.company$.pipe(take(1)).toPromise();
    const alert = await this.alert.createAlert({
      header: 'Invite User',
      // tslint:disable-next-line: max-line-length
      message: `${email} does not have an account. Would you like to invite them to ${company.name} on the AncesTREE? <br/><br/> See your subscription plan for pricing details, in Manage Subscription.`,
      buttons: [
        {
          text: 'Invite',
          cssClass: 'primary',
          handler: (blah) => {
            this.inviteUser(email, company).then(res => {
              this.toaster.presentToast(res.message);
            }).catch(error => {
              console.log(error);
            });
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'danger'
        }
      ]
    });

    await alert.present();
  }

  async leaveCompany() {
    const company = await this.company$.pipe(take(1)).toPromise();
    this.api
      .delete(`${this.endpoint}/LeaveCompany/${company.id}`)
      .pipe(take(1))
      .subscribe(res => {
        this.toaster.presentToast(res.message);
        this.company.next(null);
      }, error => {
        this.toaster.presentToast(error.error);
      });
  }

  autocomplete(filter: string): Promise<CompanyAccount[]> {
    return this.api.get(`${this.endpoint}/Autocomplete`, { filter })
      .pipe(map(res => res.map(r => plainToClass(CompanyAccount, r))))
      .toPromise();
  }

  getCompanyCSV(companyId: number): Promise<number> {
    return this.api.get(`${this.endpoint}/CompanyCSV`, { id: companyId })
      .pipe(catchError(err => {
        console.error(err);
        return of(null);
      }), tap(v => this.csv?.next(v))).toPromise();
  }

  updateUserLimit(companyId: number, limit: number) {
    return this.api.put(`${this.endpoint}/UserLimit`, { companyId, limit }).toPromise();
  }
  updateCert(companyId: number, value: boolean) {
    return this.api.put(`${this.endpoint}/USRWCertified`, { companyId, isCertified: value }).toPromise();
  }
}
