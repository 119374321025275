import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { classToPlain, plainToClass } from 'class-transformer';
import { environment } from 'src/environments/environment';

import { SystemSetting } from '../models/system-setting';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingService {

  endpoint: string = 'SystemSettings';

  ppLink = new BehaviorSubject<string | null>(environment.privacyPolicyUrl);
  ppLink$ = this.ppLink;
  fetchedPP: boolean = false;

  tosLink = new BehaviorSubject<string | null>(environment.termsOfServiceUrl);
  tosLink$ = this.tosLink;
  fetchedTos = false;

  subTermsLink = new BehaviorSubject<string | null>(environment.subscriptionTermsUrl);
  subTermsLink$ = this.subTermsLink;
  fetchedSubTerms = false;

  googleMapsApiKey = new BehaviorSubject<string | null>(null);
  googleMapsApiKey$ = this.googleMapsApiKey;

  supportEmail = new BehaviorSubject<string | null>(null);
  supportEmail$ = this.supportEmail;

  supportPhone = new BehaviorSubject<string | null>(null);
  supportPhone$ = this.supportPhone;

  reCaptchaSiteKey = new BehaviorSubject<string | null>(null);
  reCaptchaSiteKey$ = this.reCaptchaSiteKey;

  constructor(private api: ApiService) { }

  get(): Promise<SystemSetting[]> {
    return this.api.get(`${this.endpoint}`)
      .pipe(map(res => res?.map(o => plainToClass(SystemSetting, o)))).toPromise();
  }

  getByKey(key: string): Promise<SystemSetting> {
    return this.api.get(`${this.endpoint}/ByReference`, { key })
      .pipe(map(res => plainToClass(SystemSetting, res))).toPromise();
  }

  async getGoogleMapsApiKey(defaultApiKey: string): Promise<string> {
    const curr = await this.googleMapsApiKey$.pipe(take(1)).toPromise();
    if (curr != null) {
      return curr;
    }
    let value = await this.getByKey('ANCESTREE.GOOGLE_MAPS_API_KEY')
      .then(res => res?.value)
      .catch(err => { console.error(err); return defaultApiKey; });
    this.googleMapsApiKey.next(value);
    return value;
  }

  async getSupportEmail(defaultSupportEmail: string): Promise<string> {
    const curr = await this.supportEmail$.pipe(take(1)).toPromise();
    if (curr != null) {
      return curr;
    }
    let value = await this.getByKey('ANCESTREE.SUPPORT_EMAIL')
      .then(res => res?.value)
      .catch(err => { console.error(err); return defaultSupportEmail; });
    this.supportEmail.next(value);
    return value;
  }

  async getSupportPhone(): Promise<string> {
    const curr = await this.supportPhone$.pipe(take(1)).toPromise();
    if (curr != null) {
      return curr;
    }
    try {
      const { value } = await this.getByKey('ANCESTREE.SUPPORT_EMAIL');
      this.supportPhone.next(value);
      return value;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async getReCaptchaSiteKey(defaultSiteKey: string): Promise<string> {
    const curr = await this.reCaptchaSiteKey$.pipe(take(1)).toPromise();
    if (curr != null) {
      return curr;
    }
    let value = await this.getByKey('ANCESTREE.RECAPTCHA_SITE_KEY')
      .then(res => res?.value)
      .catch(err => { console.error(err); return defaultSiteKey; });
    this.reCaptchaSiteKey.next(value);
    return value;
  }

  getById(id: number): Observable<SystemSetting> | Promise<SystemSetting> {
    throw new Error('Method not implemented.');
  }

  getByUUID(uuid: string): Observable<SystemSetting> | Promise<SystemSetting> {
    throw new Error('Method not implemented.');
  }

  post(model: SystemSetting): Observable<SystemSetting> | Promise<SystemSetting> {
    throw new Error('Method not implemented.');
  }

  put(model: SystemSetting): Promise<SystemSetting> {
    return this.api.put(`${this.endpoint}/${model.id}`, classToPlain(model))
      .pipe(map(res => plainToClass(SystemSetting, res))).toPromise();
  }

  delete(model: SystemSetting): Promise<any> | Observable<any> {
    throw new Error('Method not implemented.');
  }

  async getPrivacyPolicyLink(): Promise<string> {
    const curr = await this.ppLink$.pipe(take(1)).toPromise();
    if (curr != null && this.fetchedPP === true) {
      return curr;
    }

    const value: string | null = await this.getByKey('ANCESTREE.PRIVACY_POLICY_LINK')
      .then(res => res?.value)
      .catch(err => { console.error(err); return environment.privacyPolicyUrl; });
    this.ppLink.next(value);
    this.fetchedPP = true;
    return value;
  }

  async getTOSLink(): Promise<string> {
    const curr = await this.tosLink$.pipe(take(1)).toPromise();
    if (curr != null && this.fetchedTos === true) {
      return curr;
    }

    const value: string | null = await this.getByKey('ANCESTREE.TERMS_OF_SERVICE_LINK')
      .then(res => res?.value)
      .catch(err => { console.error(err); return environment.termsOfServiceUrl; });
    this.tosLink.next(value);
    this.fetchedTos = true;
    return value;
  }

  async getSubTermsLink(): Promise<string> {
    const curr = await this.subTermsLink$.pipe(take(1)).toPromise();
    if (curr != null && this.fetchedSubTerms === true) {
      return curr;
    }

    const value: string | null = await this.getByKey('ANCESTREE.SUBSCRIPTION_TERMS_LINK')
      .then(res => res?.value)
      .catch(err => { console.error(err); return environment.subscriptionTermsUrl; });
    this.subTermsLink.next(value);
    this.fetchedSubTerms = true;
    return value;
  }
}
