import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'lodash-es';

import { RlsCompanySpecies } from '../models/relations/rls-company-species';
import { SpCompanySpecies } from '../models/spModels/sp-company-species';
import { TreeSpecies } from '../models/tree-species';
import { CompanyService } from '../services/company.service';
import { UserService } from '../services/user.service';

@Pipe({
  name: 'notationName'
})
export class NotationNamePipe implements PipeTransform {

  constructor(
    private userService: UserService,
    private companyService: CompanyService) {
  }

  transform(value: (string | SpCompanySpecies | TreeSpecies | any), ...args: any[]): any {
    if (isString(value)) { return value; }

    const company = this.companyService.company.getValue();
    if (value && value.speciesName != null) {
      return value.speciesName;
    }
    if (company?.speciesScientific == true) {
      return value?.scientificName;
    }
    return value?.commonName;
  }

}
