import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { plainToClass } from 'class-transformer';

import { CheckboxGroupOption } from '../components/checkbox-group/checkbox-group.component';
import { CompanyAccount } from '../models/company-account';
import { RoleType } from '../models/enums/role-type';
import { Role } from '../models/role';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { CompanyService } from './company.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  endpoint: string = 'Roles'

  constructor(private api: ApiService, private userService: UserService,
    private companyService: CompanyService, private authService: AuthService) { }

  // I'd like to prevent these being async functions if possible
  // I thought the userService initializing itself in the constructor would work, but it failed
  // for the companyService inside the dashboard, meaning any rolesService could have the same issue potentially
  // User Role Checks
  /**
   *
   * @returns
   */
  async isEndUser$(): Promise<boolean> {
    try {
      const user = await this.userService.getUser$();
      return user?.apiRoles?.map(o => o.reference).includes(RoleType.END_USER);
    } catch {
      return false;
    }
  }
  async isCompanyUser$(): Promise<boolean> {
    try {
      const company = await this.companyService.getCompany$();
      if (company.approved !== true) return false;

      const user = await this.userService.getUser$();
      return user?.apiRoles?.map(o => o.reference).includes(RoleType.COMPANY_USER);
    } catch {
      return false;
    }
  }
  async isCompanyAdmin$(bypassApproval: boolean = false): Promise<boolean> {
    try {
      const company = await this.companyService.getCompany$();
      if (bypassApproval === false && company.approved !== true) return false;

      const user = await this.userService.getUser$();
      return user?.apiRoles?.map(o => o.reference).includes(RoleType.COMPANY_ADMIN);
    } catch {
      return false;
    }
  }
  async isULMManager$(): Promise<boolean> {
    try {
      const user = await this.userService.getUser$();
      return user?.apiRoles?.map(o => o.reference).includes(RoleType.MANAGER);
    } catch {
      return false;
    }
  }
  /**
   *
   * @returns
   */
  async isAdmin$(): Promise<boolean> {
    try {
      const user = await this.userService.getUser$();
      return user?.apiRoles?.map(o => o.reference).includes(RoleType.ADMIN);
    } catch {
      return false;
    }
  }
  /**
   *
   * @returns
   */
  async isCompanyUserOrHigher$(): Promise<boolean> {
    return await this.isCompanyUser$() || await this.isCompanyAdminOrHigher$();
  }
  /**
  *
  * @returns
  */
  async isCompanyAdminOrHigher$(): Promise<boolean> {
    return await this.isCompanyAdmin$() || await this.isULMManager$() || await this.isAdmin$();
  }
  /**
   *
   * @returns
   */
  async isManagerOrHigher$(): Promise<boolean> {
    return await this.isULMManager$() || await this.isAdmin$();
  }

  getUserRoles(user: User, company: CompanyAccount) {

    const roles = user?.apiRoles?.map(o => o.reference);
    let value = {
      isEndUser: roles?.includes(RoleType.END_USER) || false,
      isCompanyUser: company?.approved != true ? false : roles?.includes(RoleType.COMPANY_USER) || false,
      isCompanyAdmin: company?.approved != true ? false : roles?.includes(RoleType.COMPANY_ADMIN) || false,
      isManager: roles?.includes(RoleType.MANAGER) || false,
      isAdmin: roles?.includes(RoleType.ADMIN) || false,
      isCompanyUserOrHigher: false,
      isCompanyAdminOrHigher: false,
      isManagerOrHigher: false,
    };
    value.isManagerOrHigher = value.isManager || value.isAdmin;
    value.isCompanyAdminOrHigher = value.isCompanyAdmin || value.isManagerOrHigher
    value.isCompanyUserOrHigher = value.isCompanyUser || value.isCompanyAdminOrHigher;
    return value;
  }

  // Api Requests

  get(): Promise<Role[]> {
    return this.api.get(`${this.endpoint}`)
      .pipe(map(res => res?.map(o => plainToClass(Role, o))))
      .toPromise();
  }

  getOptions(): Promise<CheckboxGroupOption<Role>[]> {
    return this.api.get(`${this.endpoint}/Options`)
      .pipe(map(v => v?.reverse()))
      .toPromise()
      .then(res => res?.map(o => {
        o.value = plainToClass(Role, o?.value);
        return o;
      }));
  }

  getByClaims(): Promise<string[]> {
    return this.api.get(`${this.endpoint}/Claims`)
      .toPromise();
  }

  getById(id: number): Observable<Role> | Promise<Role> {
    throw new Error('Method not implemented.');
  }
  getByUUID(uuid: string): Observable<Role> | Promise<Role> {
    throw new Error('Method not implemented.');
  }
  post(model: Role): Observable<Role> | Promise<Role> {
    throw new Error('Method not implemented.');
  }
  put(model: Role): Observable<Role> | Promise<Role> {
    throw new Error('Method not implemented.');
  }
  delete(model: Role): Observable<any> | Promise<any> {
    throw new Error('Method not implemented.');
  }
}
