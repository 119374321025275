import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { Capacitor } from '@capacitor/core';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { isString } from 'lodash-es';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { plainToClass } from 'class-transformer';
import { environment } from 'src/environments/environment';

import { Attachment } from '../models/attachment';
import { BaseEntity } from '../models/baseEntity';
import { ApiService } from './api.service';
import { BaseEntityService } from './base-entity.service';
import { ToasterService } from './toaster.service';

export interface FileUploadParams {
  modelName: string;
  modelUuid: string;
  uuid: string;
}

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  baseUrl = environment.attachmentUrl;
  apiUrl = environment.apiBaseUrl;

  constructor(
    private toaster: ToasterService,
    private http: HttpClient,
    private api: ApiService) { }

  get(): Observable<Attachment[]> | Promise<Attachment[]> {
    throw new Error('Method not implemented.');
  }

  getById(id: number): Observable<Attachment> {
    return this.api.get('Attachments', { id })
      .pipe(map(res => plainToClass(Attachment, res)));
  }

  getByUUID(uuid: string): Observable<Attachment> {
    return this.api.get('Attachments/ByUuid', { uuid })
      .pipe(map(res => plainToClass(Attachment, res)));
  }

  post(file: File, params: FileUploadParams): Observable<Attachment> {
    const formData = new FormData();
    formData.append('file', file);
    return this.api.post('Attachments', formData, params)
      .pipe(map(res => plainToClass(Attachment, res)));
  }

  put(attachment: Attachment): Observable<Attachment> {
    return this.api.put('Attachments', attachment)
      .pipe(map(res => plainToClass(Attachment, res)));
  }

  copyImage(copyFrom: Attachment, copyTo: Attachment, params: FileUploadParams) {
    return this.api.put(`Attachments/CopyAttachment`, { copyFrom, copyTo, modelName: params.modelName, modelUuid: params.modelUuid })
      .pipe(map(res => plainToClass(Attachment, res)));
  }

  delete(attachment: Attachment) {
    return this.api.delete(`Attachments/${attachment.id}`).toPromise();
  }

  getUnattachedRelatedAttachments(modelName: string, identifier: string) {
    return this.api.get('Attachments/Unattached', { modelName, identifier }).toPromise();
  }

  handleAttachmentReplaceChange(previousValue: Attachment, newValue: Attachment) {
    if (previousValue != null && !previousValue.isNew) {
      this.delete(previousValue).catch(err => {
        console.error(err);
      });
    } else if (previousValue?.isNew) {
      console.error('Attempted to delete a new Attachment');
    }
  }

  /**
   * https://stackoverflow.com/questions/51789871/download-file-from-http-post-request-angular-6
   * https://github.com/ionic-team/capacitor/issues/974
   * https://github.com/digaus/CapacitorSaveBlob/blob/master/src/app/services/file.service.ts
   * https://georghoeller.dev/how-to-save-and-or-open-file/
   * @param attachment
   * @param openAfterDownload @default true
   * @returns
   */
  downloadAttachment(attachment: Attachment, openAfterDownload = true): Subscription {
    return this.http.post(`${this.apiUrl}Attachments/Download/${attachment.id}`, {},
      { responseType: 'blob', observe: 'response' })
      .pipe(take(1), catchError(err => {
        console.error(err);
        this.toaster.error(isString(err?.message) ? err?.message : 'Failed to download attachment', 2000);
        return throwError(err);
      })).subscribe(resp => {
        const fileNameHeader = resp.headers.get('content-disposition');
        const mimeType = resp.headers.get('content-type');
        var filename = fileNameHeader.split(';')[1].split('filename')[1].split('=')[1].trim();
        filename = filename?.replace(/['"]+/g, '');
        return AttachmentService.convertToBase64(resp.body).then(async fileValue => {
          if (Capacitor.isNativePlatform() === true) {
            const res = await Filesystem.writeFile({
              path: `AncesTREE/Attachments/${filename}`,
              data: fileValue,
              directory: Directory.Documents,
              recursive: true
            });

            if (openAfterDownload == true) {
              let fileOpener: FileOpener = new FileOpener();
              fileOpener.open(res.uri, mimeType)
                .then(() => console.log('File is opened'))
                .catch(e => console.log('Error opening file', e));
            }
          } else {
            let link = document.createElement('a');
            link.href = fileValue;
            link.download = filename;
            link.click();
            link = null;
            return Promise.resolve({ uri: filename });
          }
        });
      });
  }

  public static convertToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve: (base64: string) => void, reject: () => void): void => {
      let reader: FileReader = new FileReader();
      const realFileReader: FileReader = reader['_realReader'];
      if (realFileReader) {
        reader = realFileReader;
      }
      reader.onerror = (err: any): void => {
        console.log(err);
        reject();
      };
      reader.onload = (): void => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  }

  async convertToBlob(dataUrl: string): Promise<Blob> {
    const res = await fetch(dataUrl);
    return res.blob();
  }
}



